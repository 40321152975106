import * as React from 'react';
import { useAppStateStore, } from "../../store/layerStore";
import { Box, IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import CloseIcon from '@mui/icons-material/Close';
import { IVotingPlace, VotingPlace } from '../../model/VotingPlace';
// import { EDInfo } from '../../data/ed';
import { roundedToFixed, AppStates, VPTypeCodes, UserStates, isVPBeforeOpenHoursThisDay } from '../../util';
import { useEventInfoStore, useVotingPlaceStore } from '../../store/layerStore';
import { IEventInfo } from '../../model/Event';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body1,
    padding: theme.spacing(-2),
    textAlign: 'center',
    color: theme.palette.text.primary,
    fontFamily: 'BC Sans',
}));

const isAdvance = (state: string) => {
    switch (state) {
        case AppStates.ADVANCE_1:
        case AppStates.ADVANCE_2:
        case AppStates.ADVANCE_3:
        case AppStates.ADVANCE_4:
        case AppStates.ADVANCE_5:
        case AppStates.ADVANCE_6:
        case AppStates.ADVANCE_7:
        case AppStates.ADVANCE_8:
        case AppStates.ADVANCE_9:
            return true;
        default:
            return false;
    }
}

export const VotingPlaceSummary = ({containerHeight, margin, vp, isOpen}: {containerHeight: number, margin: number, vp: IVotingPlace, isOpen: boolean}) => {
    const { setActiveVotingPlace, setHighlightVotingPlace } = useVotingPlaceStore();
    const { userState, voterElectoralDistrictInfo } = useAppStateStore();
    const { eventInfo } = useEventInfoStore();

    const renderWaitTime = (vp: IVotingPlace) => {
        var wt: string = '';
        if (eventInfo && !vp.isClosure && isAdvance(eventInfo.state) && vp.waitTime !== null && vp.uploadDateTime && (vp.votingPlaceTypeCode === VPTypeCodes.ADVANCE || vp.votingPlaceTypeCode === VPTypeCodes.ADVANCE_FINAL)) {
            if (vp.waitTime < 0 || Date.now() - vp.uploadDateTime.getTime() > (45 * 60000)) {
                wt = ('Estimated wait time unavailable')
            } else {
                if (vp.waitTime <= 5) {
                    wt = ('Estimated wait time 0 to 5 minutes');
                } else if (vp.waitTime > 5 && vp.waitTime <= 15) {
                    wt = ('Estimated wait time 5 to 15 minutes');
                } else if (vp.waitTime > 15 && vp.waitTime <= 30) {
                    wt = ('Estimated wait time 15 to 30 minutes');
                } else if (vp.waitTime > 30) {
                    wt = ('Estimated wait time 60 minutes');
                }
            }
            return <Typography display='block' component='span' variant='body1' marginLeft='0px' marginRight='auto' sx={{color:'#db282e', fontFamily:'BC Sans'}}>{wt}</Typography>
        } else {
            return(<></>);
        }
    } 

    const renderVpIcon = (vp: IVotingPlace) => {
        if (vp.isClosure) {
            return <WhereToVoteIcon sx={{color: '#979797', stroke:'black', strokeWidth: 0.4}} fontSize='large' />
        }

        if (userState === UserStates.ED && voterElectoralDistrictInfo?.assignedVotingPlace !== null && voterElectoralDistrictInfo?.assignedVotingPlace.id === vp.addressStandardId) {
            return <WhereToVoteIcon sx={{color: vp.icon3, stroke:'black', strokeWidth: 0.4}} fontSize='large' />
        } else {
            return <WhereToVoteIcon sx={{color: (eventInfo?.state === AppStates.POST_ADVANCE || eventInfo?.state === AppStates.GENERAL ? vp.icon2 : vp.icon1), stroke:'black', strokeWidth: 0.4}} fontSize='large' />
        }
    }

    const renderPrimaryText = (vp: VotingPlace) => {
        // if (!vp.isClosure) {
            return (
                <React.Fragment>
                    { userState === UserStates.ED && voterElectoralDistrictInfo?.assignedVotingPlace !== null && voterElectoralDistrictInfo?.assignedVotingPlace.id === vp.addressStandardId && 
                        // <Typography variant='h6' sx={{fontFamily:'Bebas Neue'}}>Your assigned voting place</Typography>
                        <Typography variant='body1' sx={{fontFamily: 'BC Sans', fontWeight:'500'}}>Your assigned voting place</Typography>
                    }
                    <Typography variant='body1' sx={{fontFamily:'BC Sans'}}>{`${vp.votingPlaceType}`}</Typography>
                </React.Fragment>
            )
        // } else {
        //     return (
        //         <React.Fragment>
        //             <Typography variant='body1' sx={{fontFamily:'BC Sans'}}>{`${vp.votingPlaceType}`}</Typography>
        //             {/* <Typography variant='body1' sx={{color:'#db282e', fontWeight:'bold'}}>{`${vp.closureNote}`}</Typography> */}
        //         </React.Fragment>
        //     )

        // }
    }

    const renderClosed = (isOpen: boolean, vp: VotingPlace, eventInfo: IEventInfo) => {
        if (vp.isClosure) {
            return <Typography variant='body1' component='span' display='block' sx={{color:'#db282e'}}>{`${vp.closureNote}`}</Typography>
        }
        if (isOpen && isVPBeforeOpenHoursThisDay(vp, eventInfo)) {
            return (
                <Stack direction='row' spacing='2'>
                    <Typography variant='body1' sx={{color:'#db282e', fontFamily: 'BC Sans'}}>Closed.</Typography>&nbsp;
                    <Typography variant='body1'> Opens at {isVPBeforeOpenHoursThisDay(vp, eventInfo)} a.m.</Typography>
                </Stack>
            );
        } else {
            return <></>;
        }        
    }

    const handleClick = (vp: VotingPlace) => {
        setActiveVotingPlace(vp);
    };

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway' || reason === 'timeout') {
            return;
        }
        // setOpen(false);
        // setEdInfo(null);
    };

    const handleHover = (vp?: VotingPlace) => {
        setTimeout(() => {
            setHighlightVotingPlace(vp);
          }, 200);
    }

  const action = (
    <React.Fragment >
      {/* <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button> */}
        <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
            edge="end">
            <CloseIcon fontSize="small" />
        </IconButton>
    </React.Fragment>
  );

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'center',
  }));



  return (
    // <Box> sx={{ margin: `${margin}px` }}
    // <Paper elevation={0}>
    <div onClick={() => {
        setHighlightVotingPlace(vp);
        setActiveVotingPlace(vp);
    }}>
        <Box sx={{backgroundColor:'#e9e8dc', height:'35px'}}>
            <Stack direction="row" justifyContent="flex-start" alignItems='center' >
                <Typography noWrap fontFamily='BC Sans' fontSize='1.0rem' fontWeight='bold'
                    marginLeft='10px' marginRight='auto' sx={{color:'#db282e'}}>{vp.buildingName}</Typography>
                <Typography noWrap fontFamily='BC Sans' fontSize='0.92rem' fontWeight='bold' 
                    lineHeight='2.0rem' marginRight='10px' marginLeft='1px' sx={{color:'#db282e'}}>{vp.distance >= 7 ? roundedToFixed(vp.distance,0) : roundedToFixed(vp.distance,1)} KM</Typography>
            </Stack>
        </Box> 
        <ListItem sx={{padding: '0px'}} >          
            <ListItemButton key={vp.addressStandardId} sx={{padding: '0px'}} >
                <ListItemIcon sx={{minWidth:'0px', padding: '8px'}}>
                    { renderVpIcon(vp) }
                </ListItemIcon>
                <ListItemText 
                    primary={ renderPrimaryText(vp) }
                    secondary={
                        <React.Fragment>
                            <Typography
                                component="span"
                                variant="body1"
                                color="text.secondary"
                                sx={{fontFamily:'BC Sans'}}
                            >
                                {vp.streetAddress}, {vp.locality}
                            </Typography>
                            {   
                                isOpen && (vp.votingPlaceTypeCode === VPTypeCodes.ADVANCE || vp.votingPlaceTypeCode === VPTypeCodes.ADVANCE_FINAL) && 
                                    renderWaitTime(vp)
                            }
                            {
                                eventInfo && 
                                    renderClosed(isOpen,vp,eventInfo)
                            }
                        </React.Fragment>
                    }
                />
            </ListItemButton>
        </ListItem>  </div>      
    // </Paper>
  );
}