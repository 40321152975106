import {useMap} from '@vis.gl/react-google-maps';
import {useEffect} from 'react';

import type {LayersList} from '@deck.gl/core/typed';
import { useAppStateStore, useLayerVisibilityStore, useVotingPlaceStore } from "../../store/layerStore";
import { bs11EdBounds } from "./utils/utils";

export type DeckglOverlayProps = {layers?: LayersList};

export const SearchListener = () => {
    const { edInfo } = useLayerVisibilityStore();
    const map = useMap();
    useEffect(() => {
        if (edInfo && map) {
            const bounds = bs11EdBounds(edInfo?.edAbbr);
            map?.fitBounds(bounds);
        }
    }, [edInfo,map]);

    return null;
}

// export const RegionListener = () => {
//     const { region } = useLayerVisibilityStore();
//     const map = useMap();
//     useEffect(() => {
//         if (region && map) {
//             const bnd = bs11RegionBounds(region);
//             map?.fitBounds(bnd);
//         }
//     }, [region]);

//     return null;
// }

export const ActiveVotingPlaceListener = () => {
    const { activeVotingPlace } = useVotingPlaceStore();
    const map = useMap();
    useEffect(() => {
        if (activeVotingPlace && map) {
            map.panTo(activeVotingPlace.latLng);
            // map.setCenter(activeVotingPlace.latLng);
            // map.setZoom(15);
        }
    }, [activeVotingPlace,map]);

    return null;
}

export const UserLocationListener = () => {
    const { userLocation, setUserLocation } = useAppStateStore();
    const map = useMap();
    useEffect(() => {
        if (userLocation && map) {
            console.log("** UserLocation updated",userLocation);
            //map?.panTo(activeVotingPlace.latLng);
            map.setCenter(userLocation);
            map.setZoom(15);
            setUserLocation(userLocation);
        }
    }, [userLocation,map]);

    return null;
}

