import { VotingPlace } from "../model";
import { IEventInfo } from "../model/Event";
import { AppStates, DeoHours, VpHours, VPTypeCodes } from '../util';

export function isAdvanceDay(advance: Date[]) {
    const dt = new Date();
    return (advance.filter(a => a.getDate() === dt.getDate() && a.getMonth() === dt.getMonth() && a.getFullYear() === dt.getFullYear()).length > 0);
}

/**
* Determines whether a Voting Place is open at the current moment time.
* @param  {Object} vp Google Fusion table query result for VP
* @param  {Object} applicationState Current app states
* @param  {Date[]} holidays Array of holiday dates
* @return {Boolean} True if the VP is open at the moment, and false if it is not
*/
export function isVPInOpenHours(vp: VotingPlace, eventInfo: IEventInfo) {
    const hour = new Date().getHours();
    const day = new Date().getDay();
    const dt: Date = new Date();
    // Open until 8PM Pacific time
    if (eventInfo.state === AppStates.GENERAL && (vp.votingPlaceTypeCode === VPTypeCodes.ADVANCE_FINAL || vp.votingPlaceTypeCode === VPTypeCodes.FINAL || vp.votingPlaceTypeCode === VPTypeCodes.DEO)) {
        // if (dt.getHours() < eventInfo.finalVotingDateLocal.getHours()) {
        //     return true;
        // } else 
        if (dt.getTime() < eventInfo.finalVotingDateLocal.getTime()) {
            return true;
        }
        // if (hourUtc < 3) {
        //     return true;
        // }
    }
  
    // If advance VP or general VP or advance + general VP
    if (vp.votingPlaceTypeCode === VPTypeCodes.ADVANCE || vp.votingPlaceTypeCode === VPTypeCodes.FINAL || vp.votingPlaceTypeCode === VPTypeCodes.ADVANCE_FINAL) {
        // This is not an advance voting day.
        if (!isAdvanceDay(eventInfo.advanceVotingDates)) return false;

        // if (eventInfo.advanceVotingDates.filter(a => a.getDate() === dt.getDate() && a.getMonth() === dt.getMonth() && a.getFullYear() === dt.getFullYear()).length === 0) {
        //     return false;
        // }

        // If time is between 8am and 8pm
        if (hour >= VpHours.advance.open && hour < VpHours.advance.closed) return true;
        // if (hour >= 8 && hour < 20) {
        //     return true;
        // }
    } else if (vp.votingPlaceTypeCode === VPTypeCodes.DEO) {// If DEO
      //TODO JB, DEC 2017 - Need to add check to test for stat holidays and also reduce code duplication between votingPlaceResults and cardInfoContent. Ideally we would determine what is open/closed in the reducer as soon as we have VP results
      // Use the nice ES6 array.includes to check it is not one of the other app states (loading, closed and error will stop us ever getting this far so leave them out)
      //const isAdvanceOrGeneral = ![appStates.PRE_ADVANCE, appStates.POST_ADVANCE].includes(this.props.eventInfo.appState); // Doesn't work in IE11
        const isAdvanceOrGeneral = (eventInfo.state && eventInfo.state !== AppStates.PRE_ADVANCE && eventInfo.state !== AppStates.POST_ADVANCE);
        // This is not an advance voting day.
        // const isAdvanceToday = (eventInfo.advanceVotingDates.filter(a => a.getDate() === dt.getDate() && a.getMonth() === dt.getMonth() && a.getFullYear() === dt.getFullYear()).length === 0);
        const isAdvanceToday = isAdvanceDay(eventInfo.advanceVotingDates);

        if (IsStatHoliday(new Date(), eventInfo.holidays)) {
            return false;
        // } else if (isAdvanceOrGeneral && !isAdvanceToday && (hour >= 8 && hour < 20)) { // If voting day is advance or general and time is between 8am and 8pm
        } else if (isAdvanceOrGeneral && isAdvanceToday && (hour >= DeoHours.advance.open && hour < DeoHours.advance.closed)) {
            return true;
        // } else if (day === 6 && !isAdvanceOrGeneral && (hour >= 10 && hour < 16)) {
        } else if (day === 6 && !isAdvanceToday && (hour >= DeoHours.other[6].open && hour < DeoHours.other[6].closed)) {
            // If Saturday (but not advance or general voting days), and time is between 10am to 4pm
            return true;
        } else if (day === 0 && !isAdvanceOrGeneral) {
            // If Sunday (but not advance or general voting), closed
            return false;
        // } else if (day < 6 && !isAdvanceOrGeneral && (hour >= 9 && hour < 17)) {
        } else if (day < 6 && !isAdvanceToday && (hour >= DeoHours.other[day].open && hour < DeoHours.other[day].closed)) {
            // Regular hours Monday to Friday
            return true;
        }
    }
    // Return false in all other situations (shouldn't be any)
    return false;
}

/**
* Determines whether a Voting Place is open at the current moment time.
* @param  {Object} vp Google Fusion table query result for VP
* @param  {Object} applicationState Current app states
* @param  {Date[]} holidays Array of holiday dates
* @return {integer} If VP is open later today, the hour as integer else 0
*                   Previously: True if the VP is open at the moment, and false if it is not
*/
export function isVPBeforeOpenHoursThisDay(vp: VotingPlace, event: IEventInfo) {
    const dt = new Date();
    const hour = dt.getHours();
    const day = dt.getDay();
 
    // If advance VP or general VP or advance + general VP
    if (vp.votingPlaceTypeCode === VPTypeCodes.ADVANCE || vp.votingPlaceTypeCode === VPTypeCodes.FINAL || vp.votingPlaceTypeCode === VPTypeCodes.ADVANCE_FINAL) {
        // If time is between 8am and 8pm
        // if (hour < 8) {
        if (hour < VpHours.advance.open) {
            return VpHours.advance.open; //8;//true;
        }
    } else if (vp.votingPlaceTypeCode === VPTypeCodes.DEO) {// If DEO
        //TODO JB, DEC 2017 - Need to add check to test for stat holidays and also reduce code duplication between votingPlaceResults and cardInfoContent. Ideally we would determine what is open/closed in the reducer as soon as we have VP results
        // Use the nice ES6 array.includes to check it is not one of the other app states (loading, closed and error will stop us ever getting this far so leave them out)
        //const isAdvanceOrGeneral = ![appStates.PRE_ADVANCE, appStates.POST_ADVANCE].includes(this.props.eventInfo.appState); // Doesn't work in IE11
        const isAdvanceOrGeneral = (event.state && event.state !== AppStates.PRE_ADVANCE && event.state !== AppStates.POST_ADVANCE && isAdvanceDay(event.advanceVotingDates));
        if (IsStatHoliday(new Date(), event.holidays)) {
            return 0; //false;
        } else if (isAdvanceOrGeneral && (hour < DeoHours.advance.open)) { //8 If voting day is advance or general and time is between 8am and 8pm
            return DeoHours.advance.open; //8; //true;
        } else if (day === 6 && !isAdvanceOrGeneral && (hour < DeoHours.other[day].open)) { //10
            // If Saturday (but not advance or general voting days), and time is between 10am to 4pm
            return DeoHours.other[day].open; //10; //true;
        } else if (day === 0 && !isAdvanceOrGeneral) {
            // If Sunday (but not advance or general voting), closed
            return 0; //false;
        } else if (day < 6 && !isAdvanceOrGeneral && (hour < DeoHours.other[day].open)) { //9
            // Regular hours Monday to Friday
            return DeoHours.other[day].open; //9; //true;
        }
    }
    // Return false in all other situations (shouldn't be any)
    return 0; //false;
}

export const OpenVotingPlaces = (event: IEventInfo, vps: VotingPlace[] | null) => {
    if (!vps) return [];
    console.log('OpenVotingPlaces');
    let openVPs: VotingPlace[] = [];

    switch (event.state) {
      // If prior to writ (handle this at app startup in dashbaoard.js)
      // If closed app status (handle this at app startup in dashboard.js)
        case AppStates.PRE_ADVANCE:
            // Open contains DEO for early voting
            const dt = new Date();
            openVPs = vps.filter(
                vp => !vp.isClosure && (isVPBeforeOpenHoursThisDay(vp, event) > 0 || isVPInOpenHours(vp, event)) && 
                    (vp.votingPlaceTypeCode === VPTypeCodes.DEO && dt >= event.deoOpenDate)
            );
            break;
        // Open contains open advance and advance/general VPs and also DEO
        case AppStates.ADVANCE_1:
            openVPs = vps.filter(
                vp => !vp.isClosure && (isVPBeforeOpenHoursThisDay(vp, event) > 0 || isVPInOpenHours(vp, event)) && 
                    ((vp.openAdvanceDay1 !== 0 && vp.votingPlaceTypeCode !== VPTypeCodes.FINAL) || vp.votingPlaceTypeCode === VPTypeCodes.DEO)
            );
            // If not in VP's open hours OR (advance not open AND VP is not a DEO)
            break;
        case AppStates.ADVANCE_2:
            openVPs = vps.filter(
                vp => !vp.isClosure && (isVPBeforeOpenHoursThisDay(vp, event) > 0 || isVPInOpenHours(vp, event)) && 
                    ((vp.openAdvanceDay2 !== 0 && vp.votingPlaceTypeCode !== VPTypeCodes.FINAL) || vp.votingPlaceTypeCode === VPTypeCodes.DEO)
            );
            break;
        case AppStates.ADVANCE_3:
            openVPs = vps.filter(
                vp => !vp.isClosure && (isVPBeforeOpenHoursThisDay(vp, event) > 0 || isVPInOpenHours(vp, event)) && 
                    ((vp.openAdvanceDay3 !== 0 && vp.votingPlaceTypeCode !== VPTypeCodes.FINAL) || vp.votingPlaceTypeCode === VPTypeCodes.DEO)
            );
            break;
        case AppStates.ADVANCE_4:
            openVPs = vps.filter(
                vp => !vp.isClosure && (isVPBeforeOpenHoursThisDay(vp, event) > 0 || isVPInOpenHours(vp, event)) && 
                ((vp.openAdvanceDay4 !== 0 && vp.votingPlaceTypeCode !== VPTypeCodes.FINAL) || vp.votingPlaceTypeCode === VPTypeCodes.DEO)
            );
            break;
        case AppStates.ADVANCE_5:
            openVPs = vps.filter(
                vp => !vp.isClosure && (isVPBeforeOpenHoursThisDay(vp, event) > 0 || isVPInOpenHours(vp, event)) && 
                ((vp.openAdvanceDay5 !== 0 && vp.votingPlaceTypeCode !== VPTypeCodes.FINAL) || vp.votingPlaceTypeCode === VPTypeCodes.DEO)
            );
            break;
        case AppStates.ADVANCE_6:
            openVPs = vps.filter(
                vp => !vp.isClosure && (isVPBeforeOpenHoursThisDay(vp, event) > 0 || isVPInOpenHours(vp, event)) && 
                ((vp.openAdvanceDay6 !== 0 && vp.votingPlaceTypeCode !== VPTypeCodes.FINAL) || vp.votingPlaceTypeCode === VPTypeCodes.DEO)
            );
            break;
        case AppStates.ADVANCE_7:
            openVPs = vps.filter(
                vp => !vp.isClosure && (isVPBeforeOpenHoursThisDay(vp, event) > 0 || isVPInOpenHours(vp, event)) && 
                ((vp.openAdvanceDay7 !== 0 && vp.votingPlaceTypeCode !== VPTypeCodes.FINAL) || vp.votingPlaceTypeCode === VPTypeCodes.DEO)
            );
            break;
        case AppStates.ADVANCE_8:
            openVPs = vps.filter(
                vp => !vp.isClosure && (isVPBeforeOpenHoursThisDay(vp, event) > 0 || isVPInOpenHours(vp, event)) && 
                    ((vp.openAdvanceDay8 !== 0 && vp.votingPlaceTypeCode !== VPTypeCodes.FINAL) || vp.votingPlaceTypeCode === VPTypeCodes.DEO)
            );
            break;
        case AppStates.ADVANCE_9:
            openVPs = vps.filter(
                vp => !vp.isClosure && (isVPBeforeOpenHoursThisDay(vp, event) > 0 || isVPInOpenHours(vp, event)) && 
                    ((vp.openAdvanceDay9 !== 0 && vp.votingPlaceTypeCode !== VPTypeCodes.FINAL) || vp.votingPlaceTypeCode === VPTypeCodes.DEO)
            );
            break;
        // If post_advance app state (between advance and GVD)
        case AppStates.POST_ADVANCE:
            // Open group will contain DEO
            openVPs = vps.filter(
                vp => !vp.isClosure && (isVPBeforeOpenHoursThisDay(vp, event) > 0 || isVPInOpenHours(vp, event)) && vp.votingPlaceTypeCode === VPTypeCodes.DEO
            );
            break;
        // If general app status
        case AppStates.GENERAL:
            // Open group will contain general VPs and DEOs
            // List of general VPs and those voting places that support advance and general voting purposes are now a general voting place - The symbology should be updated to reflect this
            openVPs = vps.filter(
                vp => (
                //     isOpenVotingPlace(
                //         vp.votingPlaceTypeCode, 
                //         new Date(Date.now()),
                //         [vp.openAdvanceDay1, vp.openAdvanceDay2, vp.openAdvanceDay3, vp.openAdvanceDay4, vp.openAdvanceDay5, vp.openAdvanceDay6, vp.openAdvanceDay7, vp.openAdvanceDay8, vp.openAdvanceDay9],
                //         event)
                // )
                    isVPBeforeOpenHoursThisDay(vp, event) > 0 || isVPInOpenHours(vp, event) 
                ) && (vp.votingPlaceTypeCode === VPTypeCodes.FINAL || vp.votingPlaceTypeCode === VPTypeCodes.DEO || vp.votingPlaceTypeCode === VPTypeCodes.ADVANCE_FINAL)
                    
                // const vpTypeId = vp.votingPlaceTypeCode;
                // if ((isVPBeforeOpenHoursThisDay(vp, event.state, event.holidays) > 0 || isVPInOpenHours(vp, event.state, event.holidays, event.advanceVotingDates)) && (vpTypeId === VPTypeCodes.FINAL || vpTypeId === VPTypeCodes.DEO || vpTypeId === VPTypeCodes.ADVANCE_FINAL)) {
                //     return true;
                // } else { 
                //     return false; 
                // }
            );
            break;
        default:
            openVPs = vps;
            break;
    }
    openVPs.sort((a,b) => a.distance - b.distance);

    // const changedArr = [...openVPs.filter(a => a.votingPlaceTypeCode !== VPTypeCodes.DEO), openVPs.filter(a => a.votingPlaceTypeCode === VPTypeCodes.DEO).length > 0 ? openVPs.filter(a => a.votingPlaceTypeCode === VPTypeCodes.DEO)[0]: ];
    // const changedArr = [...openVPs.filter(a => a.votingPlaceTypeCode !== VPTypeCodes.DEO), ...openVPs.filter(a => a.votingPlaceTypeCode === VPTypeCodes.DEO)];

    return [...openVPs.filter(a => a.votingPlaceTypeCode !== VPTypeCodes.DEO), ...openVPs.filter(a => a.votingPlaceTypeCode === VPTypeCodes.DEO)];
    // return openVPs.sort((a,b) => (a.distance > b.distance) ? 1 : ((b.distance < a.distance) ? -1 : 0));
}

export const ClosedVotingPlaces = (event: IEventInfo, vps: VotingPlace[] | null) => {
    if (!vps) return [];

    let closedVPs: VotingPlace[] = [];

    switch (event.state) {
      // If prior to writ (handle this at app startup in dashbaoard.js)
      // If closed app status (handle this at app startup in dashboard.js)
      // If pre_advance app state
        case AppStates.PRE_ADVANCE:
            // Closed contains general, advance, and advance & general VPs
            const dt = new Date();
            closedVPs = vps.filter(
                vp => (
                    vp.votingPlaceTypeCode !== VPTypeCodes.DEO
                ) || (
                    isVPBeforeOpenHoursThisDay(vp, event) === 0 && !isVPInOpenHours(vp, event) 
                ) || (
                    vp.votingPlaceTypeCode === VPTypeCodes.DEO && dt < event.deoOpenDate
                )
            );
            break;
        // If advance app state
        // Closed contains closed advance and advance-general VPs, general VPs
        // Sort order in closed group will be: Advance, Advance and General, General
        case AppStates.ADVANCE_1:
            // If not in VP's open hours OR (advance not open AND VP is not a DEO)
            closedVPs = vps.filter(
                vp => vp.isClosure || (vp.votingPlaceTypeCode === VPTypeCodes.FINAL) || (
                    isVPBeforeOpenHoursThisDay(vp, event) === 0 && !isVPInOpenHours(vp, event)
                ) || (
                    vp.openAdvanceDay1 === 0 && vp.votingPlaceTypeCode !== VPTypeCodes.DEO
                )
            );
            break;
        case AppStates.ADVANCE_2:
            closedVPs = vps.filter(
                vp => vp.isClosure || (vp.votingPlaceTypeCode === VPTypeCodes.FINAL) || (
                    isVPBeforeOpenHoursThisDay(vp, event) === 0 && !isVPInOpenHours(vp, event)
                ) || (
                    vp.openAdvanceDay2 === 0 && vp.votingPlaceTypeCode !== VPTypeCodes.DEO
                )
            );
            break;
        case AppStates.ADVANCE_3:
            closedVPs = vps.filter(
                vp => vp.isClosure || (vp.votingPlaceTypeCode === VPTypeCodes.FINAL) || (
                    isVPBeforeOpenHoursThisDay(vp, event) === 0 && !isVPInOpenHours(vp, event)
                ) || (
                    vp.openAdvanceDay3 === 0 && vp.votingPlaceTypeCode !== VPTypeCodes.DEO
                )
            );
            break;
        case AppStates.ADVANCE_4:
            closedVPs = vps.filter(
                vp => vp.isClosure || (vp.votingPlaceTypeCode === VPTypeCodes.FINAL) || (
                    isVPBeforeOpenHoursThisDay(vp, event) === 0 && !isVPInOpenHours(vp, event)
                ) || (
                    vp.openAdvanceDay4 === 0 && vp.votingPlaceTypeCode !== VPTypeCodes.DEO
                )
            );
            break;
        case AppStates.ADVANCE_5:
            closedVPs = vps.filter(
                vp => vp.isClosure || (vp.votingPlaceTypeCode === VPTypeCodes.FINAL) || (
                    isVPBeforeOpenHoursThisDay(vp, event) === 0 && !isVPInOpenHours(vp, event)
                ) || (
                    vp.openAdvanceDay5 === 0 && vp.votingPlaceTypeCode !== VPTypeCodes.DEO
                )
            );
            break;
        case AppStates.ADVANCE_6:
            closedVPs = vps.filter(
                vp => vp.isClosure || (vp.votingPlaceTypeCode === VPTypeCodes.FINAL) || (
                    isVPBeforeOpenHoursThisDay(vp, event) === 0 && !isVPInOpenHours(vp, event)
                ) || (
                    vp.openAdvanceDay6 === 0 && vp.votingPlaceTypeCode !== VPTypeCodes.DEO
                )
            );
            break;
        case AppStates.ADVANCE_7:
            closedVPs = vps.filter(
                vp => vp.isClosure || (vp.votingPlaceTypeCode === VPTypeCodes.FINAL) || (
                    isVPBeforeOpenHoursThisDay(vp, event) === 0 && !isVPInOpenHours(vp, event)
                ) || (
                    vp.openAdvanceDay7 === 0 && vp.votingPlaceTypeCode !== VPTypeCodes.DEO
                )
            );
            break;
        case AppStates.ADVANCE_8:
            closedVPs = vps.filter(
                vp => vp.isClosure || (vp.votingPlaceTypeCode === VPTypeCodes.FINAL) || (
                    isVPBeforeOpenHoursThisDay(vp, event) === 0 && !isVPInOpenHours(vp, event)
                ) || (
                    vp.openAdvanceDay8 === 0 && vp.votingPlaceTypeCode !== VPTypeCodes.DEO
                )
            );
            break;
        case AppStates.ADVANCE_9:
            closedVPs = vps.filter(
                vp => vp.isClosure || (vp.votingPlaceTypeCode === VPTypeCodes.FINAL) || (
                    isVPBeforeOpenHoursThisDay(vp, event) === 0 && !isVPInOpenHours(vp, event)
                ) || (
                    vp.openAdvanceDay9 === 0 && vp.votingPlaceTypeCode !== VPTypeCodes.DEO
                )
            );
            break;
        // If post_advance app state (between advance and final)
        case AppStates.POST_ADVANCE:
            closedVPs = vps.filter(
                vp => vp.isClosure || (
                    isVPBeforeOpenHoursThisDay(vp, event) === 0 && !isVPInOpenHours(vp, event)
                ) || (
                    vp.votingPlaceTypeCode !== VPTypeCodes.DEO && (vp.votingPlaceTypeCode === VPTypeCodes.ADVANCE_FINAL || vp.votingPlaceTypeCode === VPTypeCodes.FINAL)
                )
            );
            break;
        case AppStates.GENERAL:
            closedVPs = vps.filter(vp => {
                const vpTypeId = vp.votingPlaceTypeCode;
                if (vp.isClosure || (isVPBeforeOpenHoursThisDay(vp, event) === 0 && !isVPInOpenHours(vp, event)) || (vpTypeId !== VPTypeCodes.FINAL && vpTypeId !== VPTypeCodes.DEO && vpTypeId !== VPTypeCodes.ADVANCE_FINAL)) {
                    return true;
                } else {
                    return false;
                }
            });
            break;
        default:
            closedVPs = [];
            break;
    }
    return closedVPs;
}

export const StatHoliday = (event: IEventInfo) => {
    const currentDate = new Date();
    const h = event.holidayInfos.filter(h => (
        h.date.getDate() === currentDate.getDate() &&
        h.date.getMonth() === currentDate.getMonth() &&
        h.date.getFullYear() === currentDate.getFullYear()
    ));
    if (h.length === 1) {
        return h[0].name;
    } else {
        return null;
    }
}

export function IsStatHoliday (currentDate: Date, holidays: Date[]) {
    // return holidays.filter(h => (
    //     new Date(h).getDay() === currentDate.getDay() &&
    //     new Date(h).getMonth() === currentDate.getMonth() &&
    //     new Date(h).getFullYear() === currentDate.getFullYear()
    // )).length > 0;
    return holidays.filter(h => (
        h.getDate() === currentDate.getDate() &&
        h.getMonth() === currentDate.getMonth() &&
        h.getFullYear() === currentDate.getFullYear()
    )).length > 0;

    // for (let i = 0; i < holidays.length; i++) {
    //     if (currentDate.getTime() >= new Date(holidays[i].getFullYear(), holidays[i].getMonth(), holidays[i].getDate(), 0, 0, 0).getTime() &&
    //         currentDate.getTime() <= new Date(holidays[i].getFullYear(), holidays[i].getMonth(), holidays[i].getDate(), 23, 59, 59, 59).getTime()) {
    //         return true;
    //     }
    // }
    // return false;
}

export function isOpenAdvanceVotingPlace(usageTypeId: number, currentDate: Date, advDays: number[], advVotingDates: Date[]) {
    if (usageTypeId === VPTypeCodes.ADVANCE || usageTypeId === VPTypeCodes.ADVANCE_FINAL) {
        for (let i = 0; i < advVotingDates.length; i++) {
            if (advDays[i] !== 0 && currentDate.getTime() <= advVotingDates[i].getTime()) { //.Date.getTime()) {
                return true;
            }
        }
    }
    return false;
}

export function isOpenDEOOffice(event: IEventInfo) {
    const currentDate = new Date();
    if (IsStatHoliday(currentDate, event.holidays))
        return false;
  
    if (currentDate < event.deoOpenDate)
        return false;
  
    //if during the adv voting dates -> 8 a.m. to 8 p.m. (local time)
    // if (new Date(advVotingDates[0].Date.getFullYear(), advVotingDates[0].Date.getMonth(), advVotingDates[0].Date.getDate()) <= currentDate.getTime() &&
    //     currentDate.getTime() <= new Date(advVotingDates[advVotingDates.length - 1].Date.getFullYear(), advVotingDates[advVotingDates.length - 1].Date.getMonth(), advVotingDates[advVotingDates.length - 1].Date.getDate())) {
    if (event.advanceVotingDates[0].getTime() <= currentDate.getTime() && currentDate.getTime() <= event.advanceVotingDates[event.advanceVotingDates.length-1].getTime()) {
            // 2022April25 - During advance voting, the DEO office should be open 8AM to 8PM, updated hours below to account for this.
        // Saturday, 10 a.m. to 4 p.m. (local time)
        if (currentDate.getDay() === 6 && currentDate.getHours() >= 8 && currentDate.getHours() < 20) {
            return true;
        }

        // Monday to Friday, 8 a.m. to 8 p.m. (local time)
        if (currentDate.getDay() < 6 && currentDate.getHours() >= 8 && currentDate.getHours() < 20) {
            return true;
        }
    }

    // if it is Sunday
    if (currentDate.getDay() === 0)
        return false;

    // if before and after the advance voting dates
    // if (currentDate.getTime() <= new Date(advVotingDates[0].Date.getFullYear(), advVotingDates[0].Date.getMonth(), advVotingDates[0].Date.getDate()) ||
    //     currentDate.getTime() >= new Date(advVotingDates[advVotingDates.length - 1].Date.getFullYear(), advVotingDates[advVotingDates.length - 1].Date.getMonth(), advVotingDates[advVotingDates.length - 1].Date.getDate())) {

    if (currentDate.getTime() <= event.advanceVotingDates[0].getTime() || currentDate.getTime() >= event.advanceVotingDates[event.advanceVotingDates.length - 1].getTime()) {

    //General Voting Day: 8 a.m. to 8 p.m. (Pacific time)
    const offsetMiliseconds = currentDate.getTimezoneOffset() * 60000;
    if (event.finalVotingDate.getTime() - (12 * 60 * 60 * 1000) <= currentDate.getTime() + offsetMiliseconds &&
        currentDate.getTime() + offsetMiliseconds <= event.finalVotingDate.getTime()) { //@CT Fix for DEO office- (4 * 60 * 60 * 1000)) {
      return true;
    }
         
      // Monday to Friday, 9 a.m. to 5 p.m. (local time)
      if (currentDate.getDay() >= 1 && currentDate.getDay() <= 5 &&
          currentDate.getHours() >= 9 && currentDate.getHours() < 17) {
            return true;
      }

      // Saturday, 10 a.m. to 4 p.m. (local time)
      if (currentDate.getDay() === 6 &&
          currentDate.getHours() >= 10 && currentDate.getHours() < 16) {
            return true;
      }
    }

  return false;
}    

export function isOpenVotingPlace_(usageTypeId: number, currentDate: Date, advDays: number[], eventInfo: IEventInfo) {
    //debugger;
    // Initializing this date to deal with an issue with Safari that messes up the UTC time.  Note the time is hardcoded here to 3:00, 
    // which is 20:00 Pacific Time
    //const gvd = new Date(generalVotingDate.getFullYear(), generalVotingDate.getUTCMonth(), generalVotingDate.getUTCDate(), 3);
    //console.log(generalVotingDate);
    //console.log(currentDate);
    
    //if today is the general voting day and the place is either general only or adv + general
    if (usageTypeId === VPTypeCodes.FINAL || usageTypeId === VPTypeCodes.ADVANCE_FINAL) {
      /// We need to convert times into utc to account for local (pacific/mountain) time by adding the timezone offset in miliseconds to the times
      const offsetMiliseconds = currentDate.getTimezoneOffset() * 60000;
      if (eventInfo.finalVotingDate.getTime() - (12 * 60 * 60 * 1000) <= currentDate.getTime() + offsetMiliseconds &&
          currentDate.getTime() + offsetMiliseconds <= eventInfo.finalVotingDate.getTime()) {
        return true;
      }
    }
  
    // if this is a DEO Office then it's open Mon-Fri 9-5
    if (usageTypeId === VPTypeCodes.DEO) {
      //return isOpenDEOOffice(currentDate, generalVotingDate, advVotingDates, holidays);
      return isOpenDEOOffice(eventInfo);
    }
  
    //if this is an advance or adv + general voting place
    if (usageTypeId === VPTypeCodes.ADVANCE || usageTypeId === VPTypeCodes.ADVANCE_FINAL) {
        for (let i = 0; i < eventInfo.advanceVotingDates.length; i++) {
            if (advDays[i] !== 0 &&
                // advVotingDates[i].Date.getTime() - (12 * 60 * 60 * 1000) <= currentDate.getTime() && currentDate.getTime() <= advVotingDates[i].Date.getTime()) {
                eventInfo.advanceVotingDates[i].getTime() - (12 * 60 * 60 * 1000) <= currentDate.getTime() && currentDate.getTime() <= eventInfo.advanceVotingDates[i].getTime()) {
                return true;
            }
        }
    }
  
    return false;
}