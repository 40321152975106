export interface IOpenClose {
    day: number // 0 (Sunday) to 6 (Saturday)
    open: number, // Open time as 24 hour
    closed: number, // Close time as 24 hour
}

export interface IVotingPlaceHours {
    advance: IOpenClose,
    other: IOpenClose[],
    final: IOpenClose,
}

export interface IVotingPlace {
    addressStandardId: number,
    buildingName: string,
    streetAddress: string,
    locality: string
    votingPlaceTypeCode: number,
    votingPlaceType: string,
    edName: string,
    edAbbr: string,
    isMt: boolean,
    isWheelChairAccessible: boolean,
    latLng: google.maps.LatLngLiteral,
    openAdvanceDay1: number,
    openAdvanceDay2: number,
    openAdvanceDay3: number,
    openAdvanceDay4: number,
    openAdvanceDay5: number,
    openAdvanceDay6: number,
    openAdvanceDay7: number,
    openAdvanceDay8: number,
    openAdvanceDay9: number,
    advanceScore: number,
    icon1: string,
    icon2: string,
    icon3: string,
    distance: number;
    waitTime: number;
    uploadDateTime: Date;
    isClosure: boolean;
    closureNote: string;
}

export interface IVotingPlaceResult {
    votingPlaces: IVotingPlace[],
    kind: string,
    columns: string[],
    rows: any[],
}

export interface IBcpagResult {
    queryAddress: string,
    searchTimestamp: string,
    executionTime: number,
    version: string,
    srsCode: number,
    echo: string,
    interpolation: string,
    locationDescriptor: string,
    setBack: string,
    minScore: number,
    maxResults: number,
    disclaimer: string,
    privacyStatement: string,
    copyrightNotice: string,
    copyrightLicense: string,
    type: string,
    crs: Crs,
    features: any[], //Feature[],
}

export interface Crs {
    type: string,
    properties: {code: number},
}

/* Represents a feature returned from geoserver */
export interface IVotingPlaceFeature {
    ADDRESS: string,
    ADDR_ST_ID: number,
    ADV_SCORE: number,
    BLDNG_NAME: string,
    ED_ABBR: string,
    ED_NAME: string,
    ICON_1: string,
    ICON_2: string,
    ICON_3: string,
    LOCALITY: string,
    MST_IND: string,
    OPEN_ADV_1: number,
    OPEN_ADV_2: number,
    OPEN_ADV_3: number,
    OPEN_ADV_4: number,
    OPEN_ADV_5: number,
    OPEN_ADV_6: number,
    OPEN_ADV_7: number,
    OPEN_ADV_8: number,
    OPEN_ADV_9: number,
    POINT_X: number,
    POINT_Y: number,
    VP_TYPE: string,
    VP_TYPE_ID: number,
    WHLCHR_ACC: string,
}

/* Represents a feature returned from API (legacy Fusion Table object) */
export interface IVotingPlaceFusion {
    STREET_ADDRESS: string,
    ADDRESS_STANDARD_ID: number,
    ADVANCE_SCORE: number,
    BUILDING_NAME: string,
    ED_ABBREVIATION: string,
    ED_NAME: string,
    ICON_1: string,
    ICON_2: string,
    ICON_3: string,
    LOCALITY: string,
    MST_IND: string,
    OPEN_ADVANCE_DAY_1: number,
    OPEN_ADVANCE_DAY_2: number,
    OPEN_ADVANCE_DAY_3: number,
    OPEN_ADVANCE_DAY_4: number,
    OPEN_ADVANCE_DAY_5: number,
    OPEN_ADVANCE_DAY_6: number,
    OPEN_ADVANCE_DAY_7: number,
    OPEN_ADVANCE_DAY_8: number,
    OPEN_ADVANCE_DAY_9: number,
    POINT_X: number,
    POINT_Y: number,
    VOTING_PLACE_TYPE: string,
    VOTING_PLACE_TYPE_ID: number,
    WHEELCHAIR_ACCESSIBLE_IND: string,
}

export interface IElectoralDistrict {
    edAbbr: string,
    edName: string,
}

export interface IVoterElectoralDistrictInfo {
    assignedVotingPlace: AssignedVotingPlace,
    electoralDistrict: IElectoralDistrict,
    status: string,
    message: string,
}

export interface IAssignedVotingPlace {
    id: number,
    name: string,
    vaCode: string
}

export class AssignedVotingPlace implements IAssignedVotingPlace {
    id: number;
    name: string;
    vaCode: string;

    constructor(id: number, name: string, vaCode: string) {
        this.id = id;
        this.name = name;
        this.vaCode = vaCode;
    }
}

export class VoterElectoralDistrictInfo implements IVoterElectoralDistrictInfo {
    assignedVotingPlace: AssignedVotingPlace;
    electoralDistrict: IElectoralDistrict;
    status: string;
    message: string;
    
    constructor(assignedVotingPlace: AssignedVotingPlace, electoralDistrict: IElectoralDistrict, status: string,message: string) {
        this.assignedVotingPlace = assignedVotingPlace;
        this.electoralDistrict = electoralDistrict;
        this.status = status;
        this.message = message;
    }
}

export class VotingPlace implements IVotingPlace {
    constructor(addressStandardId: number, buildingName: string, streetAddress: string, locality: string,
        votingPlaceTypeCode: number, votingPlaceType: string, 
        latLng: google.maps.LatLngLiteral, 
        edAbbr: string, edName: string, 
        isMt: boolean, isWheelChairAccessible: boolean,
        openAdvanceDay1: number, openAdvanceDay2: number, openAdvanceDay3: number, openAdvanceDay4: number, openAdvanceDay5: number, 
        openAdvanceDay6: number, openAdvanceDay7: number, openAdvanceDay8: number,openAdvanceDay9: number, advanceScore: number,
        icon1: string, icon2: string, icon3: string, distance: number, waitTime: number, uploadDateTime: Date, isClosure: boolean, closureNote: string) {
            this.addressStandardId = addressStandardId;
            this.buildingName = buildingName;
            this.streetAddress = streetAddress;
            this.locality = locality;
            this.votingPlaceTypeCode = votingPlaceTypeCode;
            this.votingPlaceType = votingPlaceType;
            this.latLng = latLng;
            this.edAbbr = edAbbr;
            this.edName = edName;
            this.isMt = isMt;
            this.isWheelChairAccessible = isWheelChairAccessible;
            this.openAdvanceDay1 = openAdvanceDay1;
            this.openAdvanceDay2 = openAdvanceDay2;
            this.openAdvanceDay3 = openAdvanceDay3;
            this.openAdvanceDay4 = openAdvanceDay4;
            this.openAdvanceDay5 = openAdvanceDay5;
            this.openAdvanceDay6 = openAdvanceDay6;
            this.openAdvanceDay7 = openAdvanceDay7;
            this.openAdvanceDay8 = openAdvanceDay8;
            this.openAdvanceDay9 = openAdvanceDay9;
            this.advanceScore = advanceScore;
            this.icon1 = icon1;
            this.icon2 = icon2;
            this.icon3 = icon3;
            this.distance = distance;
            this.waitTime = waitTime;
            this.uploadDateTime = uploadDateTime;
            this.isClosure = isClosure;
            this.closureNote = closureNote;
    }
    addressStandardId: number;
    buildingName: string;
    streetAddress: string;
    locality: string;
    votingPlaceTypeCode: number;
    votingPlaceType: string;
    edName: string;
    edAbbr: string;
    isMt: boolean;
    isWheelChairAccessible: boolean;
    latLng: google.maps.LatLngLiteral;
    openAdvanceDay1: number = 0;
    openAdvanceDay2: number = 0;
    openAdvanceDay3: number = 0;
    openAdvanceDay4: number = 0;
    openAdvanceDay5: number = 0;
    openAdvanceDay6: number = 0;
    openAdvanceDay7: number = 0;
    openAdvanceDay8: number = 0;
    openAdvanceDay9: number = 0;
    advanceScore: number = 0;
    icon1: string;
    icon2: string;
    icon3: string;
    distance: number = 0;
    waitTime: number = 0;
    uploadDateTime: Date = new Date();
    isClosure: boolean;
    closureNote: string;
}