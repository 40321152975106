import * as React from 'react';
import { useAppStateStore, useDirectionsStore, useEventInfoStore, useLayerVisibilityStore, useUIStateStore, useVotingPlaceStore } from "../../store/layerStore";
import { Box, CardHeader, Divider, Fab, IconButton, Link, Stack, Typography } from '@mui/material';
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import DirectionsIcon from '@mui/icons-material/Directions';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import ApartmentIcon from '@mui/icons-material/Apartment';
import PermDeviceInformationIcon from '@mui/icons-material/PermDeviceInformation';
import CloseIcon from '@mui/icons-material/Close';
import BlockIcon from '@mui/icons-material/Block';
import Icon from '@mdi/react';
import { mdiWheelchair, mdiAccountClockOutline } from '@mdi/js';
import { IVotingPlace } from '../../model/VotingPlace';
import { WaitTimesChart } from './WaitTimesChart';
import { IEventInfo, IHistoricWaitTimes, IMenuOption, IWaitTimeChartData, IWaitTimeInterval } from '../../model/Event';
import { AppScopes, ClosedVotingPlaces, DeoHours, IsStatHoliday, MenuOptionKeys, OpenVotingPlaces, StatHoliday, UserStates, VPTypeCodes, VpHours, isVPBeforeOpenHoursThisDay, months, weekDaysLong, weekDaysShort } from '../../util';

const renderCandidateLink = (eventInfo: IEventInfo | undefined, ed?: string) => { // menuOptions?: IMenuOption[], 
    if (eventInfo && eventInfo.menuOptions) {
        const item = eventInfo.menuOptions.find(m => {
            return m.key === MenuOptionKeys.CANDIDATES
        });
        if (item) {
            return ed !== undefined ? `${item.url}/#${ed}` : item.url;
        }
    }
}

const renderDEOLink = (menuOptions?: IMenuOption[]) => {
    if (menuOptions) {
        const item = menuOptions.find(m => {
            return m.key === MenuOptionKeys.DISTRICT_ELECTORAL_OFFICE
        });
        if (item) {
            return item.url;
        }
    }
}

// const chartData = (historicWaitTimeData: IHistoricWaitTimes): IWaitTimeChartData[] => {
//     var intervals: IWaitTimeInterval[] = [];
//     switch (new Date(Date.now()).getDay()) {
//         case 0:
//             intervals = historicWaitTimeData.sundayWaitTimes;
//             break;
//         case 6:
//             intervals = historicWaitTimeData.saturdayWaitTimes;
//             break;
//         default:
//             intervals = historicWaitTimeData.weekdayWaitTimes;
//             break;
//     }
//     return (intervals.map<IWaitTimeChartData>(i => (
//         { 
//             interval: `${i.intervalStart} - ${i.intervalEnd}`, 
//             label: i.intervalLabel ? i.intervalLabel : '', 
//             value: i.voterPercent, 
//             color: '#488fed', 
//             isActive: false
//         }
//     )));

// }

/* */

function isOpenAdvanceVotingPlace(usageTypeId: number, currentDate: Date, advDays: number[], advVotingDates: Date[]) {
    console.log('isOpenAdvanceVotingPlace',advVotingDates);
    if (usageTypeId === VPTypeCodes.ADVANCE || usageTypeId === VPTypeCodes.ADVANCE_FINAL) {
        for (let i = 0; i < advVotingDates.length; i++) {
            if (advDays[i] !== 0 && currentDate.getTime() <= advVotingDates[i].getTime()) { //.Date.getTime()) {
                return true;
            }
        }
    }
    return false;
}

function isOpenDEOOffice(currentDate: Date, eventInfo: IEventInfo) {//generalVotingDate: Date, advVotingDates: Date[], holidays: Date[], deoOpenDate: Date) {

    if (IsStatHoliday(currentDate, eventInfo.holidays)) return false;
  
    if (currentDate < eventInfo.deoOpenDate) return false;
    //if during the adv voting dates -> 8 a.m. to 8 p.m. (local time)
    // if (new Date(advVotingDates[0].Date.getFullYear(), advVotingDates[0].Date.getMonth(), advVotingDates[0].Date.getDate()) <= currentDate.getTime() &&
    //     currentDate.getTime() <= new Date(advVotingDates[advVotingDates.length - 1].Date.getFullYear(), advVotingDates[advVotingDates.length - 1].Date.getMonth(), advVotingDates[advVotingDates.length - 1].Date.getDate())) {
    // if (advVotingDates[0].getTime() <= currentDate.getTime() && currentDate.getTime() <= advVotingDates[advVotingDates.length-1].getTime()) {
    if (eventInfo.advanceVotingDates.filter(d => d.getDate() === currentDate.getDate() && d.getMonth() === currentDate.getMonth() && d.getFullYear() === currentDate.getFullYear()).length > 0) {
        // // 2022April25 - During advance voting, the DEO office should be open 8AM to 8PM, updated hours below to account for this.
        // // Saturday, 10 a.m. to 4 p.m. (local time)
        // if (currentDate.getDay() === 6 && currentDate.getHours() >= 8 && currentDate.getHours() < 20) {
        //     return true;
        // }
        // // Monday to Friday, 8 a.m. to 8 p.m. (local time)
        // if (currentDate.getDay() < 6 && currentDate.getHours() >= 8 && currentDate.getHours() < 20) {
        //     return true;
        // }
        return (currentDate.getHours() >= DeoHours.advance.open && currentDate.getHours() < DeoHours.advance.closed);
    }
    // if it is Sunday
    if (currentDate.getDay() === 0) return false;

    // if before and after the advance voting dates
    // if (currentDate.getTime() <= new Date(advVotingDates[0].Date.getFullYear(), advVotingDates[0].Date.getMonth(), advVotingDates[0].Date.getDate()) ||
    //     currentDate.getTime() >= new Date(advVotingDates[advVotingDates.length - 1].Date.getFullYear(), advVotingDates[advVotingDates.length - 1].Date.getMonth(), advVotingDates[advVotingDates.length - 1].Date.getDate())) {

    if (currentDate.getTime() <= eventInfo.advanceVotingDates[0].getTime() || currentDate.getTime() >= eventInfo.advanceVotingDates[eventInfo.advanceVotingDates.length - 1].getTime()) {

        //General Voting Day: 8 a.m. to 8 p.m. (Pacific time)
        // const offsetMiliseconds = currentDate.getTimezoneOffset() * 60000;
        // if (eventInfo.finalVotingDate.getTime() - (12 * 60 * 60 * 1000) <= currentDate.getTime() + offsetMiliseconds &&
        //     currentDate.getTime() + offsetMiliseconds <= eventInfo.finalVotingDate.getTime()) { //@CT Fix for DEO office- (4 * 60 * 60 * 1000)) {
        // return true;
        // }
        if (eventInfo.finalVotingDateLocal.getDate() === currentDate.getDate() 
            && eventInfo.finalVotingDateLocal.getMonth() === currentDate.getMonth()
            && eventInfo.finalVotingDateLocal.getFullYear() === currentDate.getFullYear()) {
                return (currentDate.getHours() >= DeoHours.final.open && currentDate.getTime() < eventInfo.finalVotingDateLocal.getTime());
        }        

        if (currentDate.getHours() >= DeoHours.other[currentDate.getDay()].open && currentDate.getHours() < DeoHours.other[currentDate.getDay()].closed) {
            return true;
        }

        // // Monday to Friday, 9 a.m. to 5 p.m. (local time)
        // if (currentDate.getDay() >= 1 && currentDate.getDay() <= 5 && currentDate.getHours() >= 9 && currentDate.getHours() < 17) {
        //     return true;
        // }

        // // Saturday, 10 a.m. to 4 p.m. (local time)
        // if (currentDate.getDay() === 6 && currentDate.getHours() >= 10 && currentDate.getHours() < 16) {
        //     return true;
        // }

    }
  return false;
}    

function isOpenVotingPlace(usageTypeId: number, currentDate: Date, advDays: number[], eventInfo: IEventInfo) {
    //debugger;
    // Initializing this date to deal with an issue with Safari that messes up the UTC time.  Note the time is hardcoded here to 3:00, 
    // which is 20:00 Pacific Time
    //const gvd = new Date(generalVotingDate.getFullYear(), generalVotingDate.getUTCMonth(), generalVotingDate.getUTCDate(), 3);
    //console.log(generalVotingDate);
    //console.log(currentDate);
    
    //if today is the final voting day and the place is either final only or adv + final
    if (usageTypeId === VPTypeCodes.FINAL || usageTypeId === VPTypeCodes.ADVANCE_FINAL) {
    //   /// We need to convert times into utc to account for local (pacific/mountain) time by adding the timezone offset in miliseconds to the times
    //   const offsetMiliseconds = currentDate.getTimezoneOffset() * 60000;
    //   if (eventInfo.finalVotingDate.getTime() - (12 * 60 * 60 * 1000) <= currentDate.getTime() + offsetMiliseconds &&
    //       currentDate.getTime() + offsetMiliseconds <= eventInfo.finalVotingDate.getTime()) {
    //     return true;
    //   }

        if (eventInfo.finalVotingDateLocal.getDate() === currentDate.getDate() 
            && eventInfo.finalVotingDateLocal.getMonth() === currentDate.getMonth()
            && eventInfo.finalVotingDateLocal.getFullYear() === currentDate.getFullYear()) {
                return (currentDate.getHours() >= VpHours.final.open && currentDate.getTime() < eventInfo.finalVotingDateLocal.getTime());
        }

        // if (eventInfo.finalVotingDateLocal.getTime() - (12 * 60 * 60 * 1000) <= currentDate.getTime() && currentDate.getTime() < eventInfo.finalVotingDateLocal.getTime()) {
        //     return true;
        // }
    }
  
    // if this is a DEO Office then it's open Mon-Fri 9-5
    if (usageTypeId === VPTypeCodes.DEO) {
      //return isOpenDEOOffice(currentDate, generalVotingDate, advVotingDates, holidays);
      return isOpenDEOOffice(currentDate, eventInfo);
    }
  
    //if this is an advance or adv + general voting place
    if (usageTypeId === VPTypeCodes.ADVANCE || usageTypeId === VPTypeCodes.ADVANCE_FINAL) {
        for (let i = 0; i < eventInfo.advanceVotingDates.length; i++) {
            // if (advDays[i] !== 0 &&
            //     // advVotingDates[i].Date.getTime() - (12 * 60 * 60 * 1000) <= currentDate.getTime() && currentDate.getTime() <= advVotingDates[i].Date.getTime()) {
            //     eventInfo.advanceVotingDates[i].getTime() - (12 * 60 * 60 * 1000) <= currentDate.getTime() && currentDate.getTime() <= eventInfo.advanceVotingDates[i].getTime()) {
            //     return true;
            // }
            if (advDays[i] !== 0 && 
                eventInfo.advanceVotingDates[i].getDate() === currentDate.getDate() && 
                eventInfo.advanceVotingDates[i].getMonth() === currentDate.getMonth() && 
                eventInfo.advanceVotingDates[i].getFullYear() === currentDate.getFullYear()) {
                    return (currentDate.getHours() >= VpHours.advance.open && currentDate.getHours() < VpHours.advance.closed);
            }
        }
    }
  
    return false;
}

const renderAdvanceVotingDateContent = (currentDate: Date, advVotingDates: Date[], advDays: number[]) => {
    var renderDates = [];
    for (var i = 0; i < advVotingDates.length; i++) {
        var d = advDays[i];
        if (d !== 0 && currentDate.getTime() < advVotingDates[i].getTime()) {
            renderDates.push(advVotingDates[i]);
        }
    }
    // advDays.forEach(d => {
    //    if (d !== "0" && currentDate.getTime() < advVotingDates[i].getTime()) {
    //       renderDates.push(advVotingDates(i));
    //    }
    //    i += 1;
    // });
    //console.log(renderDates);
    var isConsecutive = false;
    var consecutiveCount = 1;
    var lines = [];
    var startIndex = 0;
    var endIndex = 0;
 
    if (renderDates.length === 1) {
        lines.push({type: 'single', start: renderDates[0], end: renderDates[0], count: 1});
    } else {
        for (var i = 1; i < renderDates.length; i += 1) {
            var d1 = renderDates[i - 1];
            var d2 = renderDates[i];
            var delta = d2.getTime() - d1.getTime();
 
            if (delta <= 90000000) {
                consecutiveCount += 1;
                endIndex = i;
                isConsecutive = true;
            } else {
                // Add last consecutive dates
                //console.log(`push ${consecutiveCount > 1 ? 'range' : 'single'} ${renderDates[startIndex]} ${renderDates[endIndex]}`);
                lines.push({type: consecutiveCount > 1 ? 'range' : 'single', start: renderDates[startIndex], end: renderDates[endIndex], count: consecutiveCount});
                consecutiveCount = 1;
                startIndex = i;
                endIndex = i;
                isConsecutive = false;
            }
            if (i === renderDates.length - 1) {
                // Add last consecutive dates
                //console.log(`push last ${consecutiveCount > 1 ? 'range' : 'single'} ${renderDates[startIndex]} ${renderDates[endIndex]}`);
                lines.push({type: consecutiveCount > 1 ? 'range' : 'single', start: renderDates[startIndex], end: renderDates[endIndex], count: consecutiveCount});
            }
        }
    }
    //console.log('Preliminary Lines:');
    //console.log(lines);
 
    var renderLines = [];
    var maxLines = 3;
    if (lines.length > maxLines) {
        //console.log(`Condsolidate lines from ${lines.length} to ${maxLines}`);
        for (var i = 0; i < lines.length; i++) {
            if (i < lines.length - 1 && lines[i].count === 1 && lines[i + 1].count === 1) {
                renderLines.push({type: 'multi', start:lines[i].start, end: lines[i + 1].end, count: 2});
                i += 1;
            } else {
                renderLines.push(lines[i]);
            }
        }
    } else {
        renderLines = lines;
    }
    //console.log('Render Lines:');
    //console.log(renderLines);
 
    return (
       <>
            {renderLines.length > 0 && renderAdvanceVotingDatesLine(renderLines[0])}
            {renderLines.length > 1 && renderAdvanceVotingDatesLine(renderLines[1])}
            {renderLines.length > 2 && renderAdvanceVotingDatesLine(renderLines[2])}
        </>
    )
}

function renderAdvanceVotingDatesLine (content: any) {
    if (content.type === "single") {
        return (
            // <div className="cardInfoText time-text">{weekDaysShort[content.start.getDay()]} {months[content.start.getMonth()]} {content.start.getDate()}</div>
            <Typography fontFamily='BC Sans' paddingLeft='40px'>{weekDaysShort[content.start.getDay()]} {months[content.start.getMonth()]} {content.start.getDate()}</Typography>
        )
    } else if (content.type === "range") {
        if (content.start.getMonth() === content.end.getMonth()) {
            return (
                <Typography fontFamily='BC Sans' paddingLeft='40px'>{weekDaysShort[content.start.getDay()]} - {weekDaysShort[content.end.getDay()]} {months[content.start.getMonth()]} {content.start.getDate()}-{content.end.getDate()}</Typography>
            )
        } else {
            return (
                <Typography fontFamily='BC Sans' paddingLeft='40px'>{weekDaysShort[content.start.getDay()]} {months[content.start.getMonth()]} {content.start.getDate()} - {weekDaysShort[content.end.getDay()]} {months[content.end.getMonth()]} {content.end.getDate()}</Typography>
            )
        }
    } else {
        return (
            <Typography fontFamily='BC Sans' paddingLeft='40px'>{weekDaysShort[content.start.getDay()]} {months[content.start.getMonth()]} {content.start.getDate()}, {weekDaysShort[content.end.getDay()]} {months[content.end.getMonth()]} {content.end.getDate()}</Typography>
        )
    }
}


function renderFinalVotingDate(usageTypeId: number, currentDate: Date, eventScope: string, finalVotingDate: Date) {
    // Subtract the timezone offset from the UTC general voting date (convert time zone difference to miliseconds)
    //const generalVotingDateOffset = new Date(generalVotingDate - (generalVotingDate.getTimezoneOffset() * 60 * 1000));
    const dt = new Date(finalVotingDate.getFullYear(), finalVotingDate.getUTCMonth(), finalVotingDate.getUTCDate() - 1);
    if (usageTypeId === VPTypeCodes.FINAL || usageTypeId === VPTypeCodes.ADVANCE_FINAL) {
        return (
            <>
            <Divider /> 
            <Stack direction='column' spacing={1.8} sx={{backgroundColor:'white', padding:'10px'}}>
                <Stack direction='row' alignItems="center" spacing={2} >
                    <AccessTimeIcon fontSize='medium' sx={{color:'#db282e'}} />
                    <Typography fontFamily='BC Sans' sx={{fontWeight:'bold'}}>Final Voting</Typography>
                </Stack>
                {/* <Typography paddingLeft='40px'>Mon - Wed Mar 18-20</Typography>
                <Typography paddingLeft='40px'>8 a.m. to 8 p.m.</Typography> */}
                {/* show hours of operation */}

                {/*<div className="cardInfoText time-text">{weekDayLong[(new Date(generalVotingDate.getFullYear(), generalVotingDate.getMonth(), generalVotingDate.getDate() - 1, 20, 0, 0)).getDay()]}, {month[generalVotingDate.getMonth()]} {generalVotingDate.getDate() - 1}</div>*/}
                {/* 2022 March 31, This date was displaying incorrectly.
                    <div className="cardInfoText time-text">{weekDayLong[(new Date(generalVotingDate.getFullYear(), generalVotingDate.getUTCMonth(), generalVotingDate.getUTCDate() - 1)).getDay()]}, {month[generalVotingDate.getUTCMonth()]} {generalVotingDate.getUTCDate() - 1}</div>
                */}
                <Typography fontFamily='BC Sans' paddingLeft='40px'>{weekDaysLong[dt.getDay()]}, {months[dt.getMonth()]} {dt.getDate()}</Typography>
                {/*<div className="cardInfoText time-text">Saturday, Oct 24</div>*/}
                <Typography fontFamily='BC Sans' paddingLeft='40px'>8 a.m. to 8 p.m. {eventScope !== AppScopes.BYELECTION && "(Pacific time)"}</Typography>
                {/*<div className="cardInfoText time-text">{weekDay[generalVotingDate.getDay() - 1]} {month[generalVotingDate.getMonth()]} {generalVotingDate.getDate() - 1}</div>*/}
            </Stack>            
            </>
        )
    }
 
    if (usageTypeId === VPTypeCodes.DEO) {
        //{month[generalVotingDate.getMonth()]} {generalVotingDate.getDate() - 1},
        //<div className="cardInfoText time-text">
        //console.log(new Date(generalVotingDate.getFullYear(), generalVotingDate.getMonth(), generalVotingDate.getDate() - 1, 20, 0, 0));
        //console.log(new Date(Date.UTC(generalVotingDate.getFullYear(), generalVotingDate.getMonth(), generalVotingDate.getDate(), generalVotingDate.getHours())));
        //console.log(new Date(generalVotingDate.getFullYear(), generalVotingDate.getUTCMonth(), generalVotingDate.getUTCDate() - 1, 20, 0, 0));
        return (
            <>
                {/* { (currentDate.getTime() >= new Date(finalVotingDate.getFullYear(), finalVotingDate.getMonth(), finalVotingDate.getDate() - 1, 20, 0, 0).getTime())
                    ? <div className="cardInfoText"><i className="small material-icons ebcRed-text">access_time</i><strong> Final Voting</strong></div>
                    : <div className="cardInfoText"><i className="small material-icons ebcRed-text">access_time</i><strong>Final Voting</strong></div>} */}
                {/*<div className="cardInfoText time-text">{weekDayLong[(new Date(generalVotingDate.getFullYear(), generalVotingDate.getMonth(), generalVotingDate.getDate() - 1, 20, 0, 0)).getDay()]}, 8 a.m. to 8 p.m. {eventScope !== appScopes.BYELECTION && "(Pacific time)"}</div>*/}
                {/* 2022 March 31, This date was displaying incorrectly.
                    <div className="cardInfoText time-text">{weekDayLong[(new Date(generalVotingDate.getFullYear(), generalVotingDate.getUTCMonth(), generalVotingDate.getUTCDate() - 1)).getDay()]}, {month[generalVotingDate.getUTCMonth()]} {generalVotingDate.getUTCDate() - 1}</div>
                */}
                <Divider />
                <Stack direction='column' spacing={1.8} sx={{backgroundColor:'white', padding:'10px'}}>
                    <Stack direction='row' alignItems="center" spacing={2} >
                        <AccessTimeIcon fontSize='medium' sx={{color:'#db282e'}} />
                        <Typography fontFamily='BC Sans' sx={{fontWeight:'bold'}}>Final Voting</Typography>
                    </Stack>
                    <Typography fontFamily='BC Sans' paddingLeft='40px'>{weekDaysLong[dt.getDay()]}, {months[dt.getMonth()]} {dt.getDate()}</Typography>
                    {/*<div className="cardInfoText time-text">Saturday, Oct 24</div>*/}
                    <Typography fontFamily='BC Sans' paddingLeft='40px'>8 a.m. to 4 p.m. {eventScope !== AppScopes.BYELECTION && "(Pacific time)"}</Typography>
                    <Typography fontFamily='BC Sans' paddingLeft='40px'>Package drop off 8 a.m. to 8 p.m. {eventScope !== AppScopes.BYELECTION && "(Pacific time)"}</Typography>
                </Stack>
            </>
        )
    }
    return (
        <span></span>
    )
}

function renderDEORegularOfficeDates(currentDate: Date, finalVotingDate: Date, advVotingDates: Date[], deoOpenDate: Date, lastAdvDayIndex:number) {
    var fromDt;
    var toDt = new Date(finalVotingDate.getFullYear(), finalVotingDate.getUTCMonth(), finalVotingDate.getUTCDate(), finalVotingDate.getHours()-finalVotingDate.getTimezoneOffset()/60);
    var fromMonth;
    var toMonth;
    // const finalVotingDate = new Date(finalVotingDate.getFullYear(), finalVotingDate.getUTCMonth(), finalVotingDate.getUTCDate(), 3);
    if (currentDate < deoOpenDate || currentDate < advVotingDates[0]) {
        // Before DEO Office is open
        fromDt = new Date(deoOpenDate.getFullYear(), deoOpenDate.getMonth(), deoOpenDate.getDate());
        //toDt = new Date(generalVotingDate.getFullYear(), generalVotingDate.getMonth(), generalVotingDate.getDate()-1, generalVotingDate.getHours()-generalVotingDate.getTimezoneOffset()/60);
        fromMonth = months[fromDt.getMonth()];
        toMonth = fromDt.getMonth() === toDt.getMonth() ? '' : months[toDt.getMonth()];
        return (
            <Typography fontFamily='BC Sans' paddingLeft='40px'>{fromMonth} {fromDt.getDate()} - {toMonth} {toDt.getDate()}</Typography>
        )
    } else if (currentDate < new Date(finalVotingDate.getFullYear(), finalVotingDate.getMonth(), finalVotingDate.getDate())) {
        // Before Final Voting but after advance voting
        fromDt = new Date(advVotingDates[lastAdvDayIndex].getFullYear(), advVotingDates[lastAdvDayIndex].getMonth(), advVotingDates[lastAdvDayIndex].getDate()+1);
        //toDt = new Date(generalVotingDate.getYear(), generalVotingDate.getMonth(), generalVotingDate.getDate()-1, generalVotingDate.getHours()-(generalVotingDate.getTimezoneOffset/60));
        fromMonth = months[fromDt.getMonth()];
        
        if (toDt.getMonth() === fromDt.getMonth() && toDt.getDate() === fromDt.getDate()) {
            return (
                <Typography fontFamily='BC Sans' paddingLeft='40px'>{fromMonth} {fromDt.getDate()}</Typography>
            )
        } else {
            toMonth = fromDt.getMonth() === toDt.getMonth() ? '' : months[toDt.getMonth()];
            return (
                <Typography fontFamily='BC Sans' paddingLeft='40px'>{fromMonth} {fromDt.getDate()} - {toMonth} {toDt.getDate()}</Typography>
            )
        }
        //if (currentDate.getTime() <= new Date(finalVotingDate.Date.getFullYear(), finalVotingDate.Date.getMonth(), finalVotingDate.Date.getDate() - 1, 17, 0, 0).getTime()) {
        //} 
    } else {
        return (
            <></>
        )
    }
}

function renderDEOOfficeDates(currentDate: Date, eventScope: string, finalVotingDate: Date, advVotingDates: Date[], deoOpenDate: Date) {
    const tmpGeneralVotingDate: Date = finalVotingDate;
    const tmpAdvVotingDates: Date[] = advVotingDates;
    const tmpCurrentDate: Date = currentDate;
    const lastAdvDayIndex: number = advVotingDates.length - 1;
    var advDays: number[] = [1];
    for (var i = 1; i <= advVotingDates.length; i++) {
        advDays.push(advDays[i - 1] * 2);
    }
    //console.log(advDays);
    return (
        (currentDate.getTime() <= new Date(finalVotingDate.getFullYear(), finalVotingDate.getMonth(), finalVotingDate.getDate() - 1, 17, 0, 0).getTime()) ? 
            <>
                <Divider />
                <Stack direction='column' spacing={1.8} sx={{backgroundColor:'white', padding:'10px'}}>
                    <Stack direction='row' alignItems="center" spacing={2} >
                        <AccessTimeIcon fontSize='medium' sx={{color:'#db282e'}} />
                        <Typography fontFamily='BC Sans' sx={{fontWeight: 'bold'}}>Regular Office Hours</Typography>
                    </Stack>
                    { renderDEORegularOfficeDates(currentDate, finalVotingDate, advVotingDates, deoOpenDate, lastAdvDayIndex) }
                
                    {(currentDate.getTime() <= new Date(finalVotingDate.getFullYear(), finalVotingDate.getMonth(), finalVotingDate.getDate() - 1, 17, 0, 0).getTime()) ? 
                        <>
                            <Typography fontFamily='BC Sans' paddingLeft='40px'>Monday to Friday, 9 a.m. to 5 p.m. {eventScope !== AppScopes.BYELECTION && "(local time)"}</Typography>
                            <Typography fontFamily='BC Sans' paddingLeft='40px'>Saturday, 10 a.m. to 4 p.m. {eventScope !== AppScopes.BYELECTION && "(local time)"}</Typography>
                            { /* TODO: Take stat holiday dates from config and provide info based on them (maybe need to add more info like the holiday name) */ }
                            <Typography fontFamily='BC Sans' paddingLeft='40px'>Closed Sundays</Typography>
                        </>
                    : 
                        <></>
                    }
                </Stack>    
    

                {/* <div className="cardInfoText time-text">
                    {(currentDate.getTime() <= new Date(advVotingDates[lastAdvDayIndex].Date.getFullYear(), advVotingDates[lastAdvDayIndex].Date.getMonth(), advVotingDates[lastAdvDayIndex].Date.getDate() - 1, 20, 0, 0))
                    ? (advVotingDates[lastAdvDayIndex].Date.getMonth() > advVotingDates[0].Date.getMonth())
                    ? <div> <strong>{month[advVotingDates[0].Date.getMonth()]} {advVotingDates[0].Date.getDate()} - {month[advVotingDates[lastAdvDayIndex].Date.getMonth()]} {advVotingDates[lastAdvDayIndex].Date.getDate()}, Advance Voting</strong> </div>
                    : <div> <strong>{month[advVotingDates[lastAdvDayIndex].Date.getMonth()]} {advVotingDates[0].Date.getDate()} - {advVotingDates[lastAdvDayIndex].Date.getDate()}, Advance Voting</strong> </div>
                    :  }
                </div> */}
                {(currentDate.getTime() <= advVotingDates[lastAdvDayIndex].getTime()) ? 
                    <>
                        <Divider />
                        <Stack direction='column' spacing={1.8} sx={{backgroundColor:'white', padding:'10px'}}>
                            <Stack direction='row' alignItems="center" spacing={2} >
                                <AccessTimeIcon fontSize='medium' sx={{color:'#db282e'}} />
                                <Typography fontFamily='BC Sans' sx={{fontWeight: 'bold'}}>Advance Voting</Typography>
                            </Stack>                                
                        {/*renderAdvanceVotingDateTitle(advVotingDates, advDays)*/}
                        {renderAdvanceVotingDateContent(currentDate, advVotingDates, advDays)}
                        <Typography fontFamily='BC Sans' paddingLeft='40px'>8 a.m. to 8 p.m. {eventScope !== AppScopes.BYELECTION && "(local time)"}</Typography>
                        </Stack>
                    </>
                : 
                    <></>
                }

    
            </> 
        : 
            <></>
    )
}

const renderOpenDates = (vp: IVotingPlace, advDays: number[], eventInfo?: IEventInfo) => {
    if (!eventInfo || vp.isClosure) { return <></>}
    const currentDate = new Date();
    const finalVotingDate = new Date(eventInfo?.finalVotingDate.getFullYear(), eventInfo.finalVotingDate.getUTCMonth(), eventInfo.finalVotingDate.getUTCDate(), 3);
    //const currentDate = new Date(2017, 3, 29, 20, 0, 0);
    return (
        <>
            {/* { (isOpenVotingPlace(usageTypeId, currentDate, advDays, eventInfo)) ? (
                <div className="cardInfoText time-text ebcRed-text">Open Now</div>
            ) : (
                <div className="cardInfoText time-text ebcRed-text">Now Closed</div>
            ) }
            <div className="divider"></div> */}
  
            {/* render advance voting title */}
            { (isOpenAdvanceVotingPlace(vp.votingPlaceTypeCode, currentDate, advDays, eventInfo.advanceVotingDates)) ? (
                <>
                    <Divider /> 
                    <Stack direction='column' spacing={1.8} sx={{backgroundColor:'white', padding:'10px'}}>
                        <Stack direction='row' alignItems="center" spacing={2} >
                            <AccessTimeIcon fontSize='medium' sx={{color:'#db282e'}} />
                            <Typography sx={{fontWeight:'bold', fontFamily:'BC Sans'}}>Advance Voting</Typography>
                        </Stack>                
            
                        { /*@CT SEE JB COMMENTS BELOW ABOUT BYELECTION - COMMENTED OUT DEC 2019 AS PART OF FUSION TO GEOSERVER */}
                        {/* JB (Jan 2018) - For the Kelowna West by-election, dates are always consecutive so display them on one line */}
                        {/*@CT 2020Aug21, a more generic approach to rendering the advance voting dates has been developed so line below is commented. */}
                        {/* (eventScope === appScopes.BYELECTION) && renderConsecutiveAdvanceDateLine(currentDate, advVotingDates, advDays) */}
                        { (eventInfo.scope === AppScopes.BYELECTION) && renderAdvanceVotingDateContent(currentDate, eventInfo.advanceVotingDates, advDays)}
    
                        {/*@CT Added single render method instead of the three below.  This can be cleaned up when someone figures out
                            the best generic approach to adding the advance voting content */}
                        { (eventInfo.scope === AppScopes.GENERAL) && renderAdvanceVotingDateContent(currentDate, eventInfo.advanceVotingDates, advDays)}
                        {/* The 3 lines below render the advance dates in sets of two on seperate lines (used in 2017 General Election) */}
                        {/*renderAdvanceVotingDateLine(currentDate, advVotingDates[0].Date, advVotingDates[1].Date, advDays[0], advDays[1])*/}
                        {/*renderAdvanceVotingDateLine(currentDate, advVotingDates[2].Date, advVotingDates[3].Date, advDays[2], advDays[3])*/}
                        {/*renderAdvanceVotingDateLine(currentDate, advVotingDates[4].Date, advVotingDates[5].Date, advDays[4], advDays[5])*/}
                
                        {/*@CT 2020Sept27, moved this line from below the title to below dates to be consistent with DEO content. */}
                        <Typography fontFamily='BC Sans' paddingLeft='40px'>8 a.m. to 8 p.m. {eventInfo.scope !== AppScopes.BYELECTION && "(local time)"}</Typography>
                    </Stack>
                </>
            ) : (
                <></>
            )}
  
            {
                (vp.votingPlaceTypeCode === VPTypeCodes.DEO) ? 
                    renderDEOOfficeDates(currentDate, eventInfo.scope, finalVotingDate, eventInfo.advanceVotingDates, eventInfo.deoOpenDate) : 
                    <span></span>
            }
  
            {/* render general voting date !! @CTThis needs to be fixed to deal with Safari bug by properly using the 'gvd' variable */}
            {
                renderFinalVotingDate(vp.votingPlaceTypeCode, currentDate, eventInfo.scope, finalVotingDate)
            }

            <div className="divider"></div>
        </>
    )
}

const renderWaitTime = (vp: IVotingPlace) => {
    var wt: string = '';
    if (vp.waitTime !== null && vp.uploadDateTime && (vp.votingPlaceTypeCode === VPTypeCodes.ADVANCE || vp.votingPlaceTypeCode === VPTypeCodes.ADVANCE_FINAL)) {
        if (vp.waitTime < 0 || Date.now() - vp.uploadDateTime.getTime() > (45 * 60000)) {
            wt = ('Estimated wait time unavailable');
        } else {
            if (vp.waitTime <= 5) {
                wt = ('Estimated wait time 0 to 5 minutes');
            } else if (vp.waitTime > 5 && vp.waitTime <= 15) {
                wt = ('Estimated wait time 5 to 15 minutes');
            } else if (vp.waitTime > 15 && vp.waitTime <= 30) {
                wt = ('Estimated wait time 15 to 30 minutes');
            } else if (vp.waitTime > 30) {
                wt = ('Estimated wait time 60 minutes');
            }
        }
        return <Typography variant='body1' fontFamily='BC Sans' marginLeft='10px' marginRight='auto'>{wt}</Typography>
    } else {
        return(<></>);
    }
}

const renderOpenClosed = (vp: IVotingPlace, advDays: number[], eventInfo: IEventInfo, votingPlaces: IVotingPlace[] | null) => {
    const openVotingPlaces = OpenVotingPlaces(eventInfo,[vp]);
    console.log("*** renderOpenClosed",openVotingPlaces);
    if (vp.isClosure) {
        return <>
            <Typography paddingLeft='40px' sx={{color:'#db282e', fontFamily: 'BC Sans'}}>{vp.closureNote}</Typography>
            <Typography paddingLeft='40px'>If this was your assigned voting place, please vote at any other voting place that is open today. Please call Elections BC at 1-800-661-8683 if you require assistance.</Typography>
        </>

    // } else if (isOpenVotingPlace(vp.votingPlaceTypeCode, new Date(Date.now()), advDays, eventInfo)) {
    } else if (openVotingPlaces.filter(o => o.addressStandardId === vp.addressStandardId).length > 0) {
        if (isVPBeforeOpenHoursThisDay(vp, eventInfo)) {
            return <Typography paddingLeft='40px' sx={{color:'#db282e', fontFamily: 'BC Sans'}}>Closed. Opens at {isVPBeforeOpenHoursThisDay(vp, eventInfo)} a.m.</Typography>
        } else {
            return <Typography paddingLeft='40px' sx={{color:'#db282e', fontFamily: 'BC Sans'}}>Open Now</Typography>
        }
    } else {
        // if (isVPBeforeOpenHoursThisDay(vp, eventInfo)) {
        //     return <Typography paddingLeft='40px' sx={{color:'#db282e', fontFamily: 'BC Sans'}}>Closed. Opens at {isVPBeforeOpenHoursThisDay(vp, eventInfo)} a.m.</Typography>
        // } else {
            const statHoliday = StatHoliday(eventInfo);
            if (vp.votingPlaceTypeCode === VPTypeCodes.DEO && statHoliday) {
                return <Typography paddingLeft='40px' sx={{color:'#db282e', fontFamily: 'BC Sans'}}>{`Closed for ${statHoliday}`}</Typography>
            } else {
                console.log("**** renderOpenClosed",vp);
                return <Typography paddingLeft='40px' sx={{color:'#db282e', fontFamily: 'BC Sans'}}>Now Closed</Typography>
            }
        // }
    }
}

/* */

export const VotingPlaceDetailsContent = ({vp}: {vp: IVotingPlace}) => {
    // const [open, setOpen] = React.useState(false);
    // const { setEdInfo } = useLayerVisibilityStore();
    const { activeVotingPlace, setActiveVotingPlace, nearestVotingPlaces, inEdVotingPlaces } = useVotingPlaceStore();
    const { setDestination, setOrigin } = useDirectionsStore();
    const { searchLocation, userState } = useAppStateStore();
    const { setShowDirections } = useUIStateStore();
    const { eventInfo } = useEventInfoStore();
    const advDays: number[] = [
        vp.openAdvanceDay1, 
        vp.openAdvanceDay2, 
        vp.openAdvanceDay3, 
        vp.openAdvanceDay4, 
        vp.openAdvanceDay5, 
        vp.openAdvanceDay6, 
        vp.openAdvanceDay7, 
        vp.openAdvanceDay8, 
        vp.openAdvanceDay9
    ];

    const handleDirectionsClick = () => {
        // Set the origin (userLocation) and destination (vp)
        setOrigin(searchLocation);
        setDestination(activeVotingPlace ? activeVotingPlace.latLng : null);
        setShowDirections(true);
    }

    return (
        <Box sx={{overflowY:'auto', flexGrow: 1, p: 0, fontFamily:'BC Sans' }}>
        {/* <Card elevation={0} sx={{borderRadius:'0px'}}> */}
            <CardHeader
                sx={{backgroundColor:'#db282e', paddingBottom:'0px', marginBottom:'-10px'}}
                action={
                    <IconButton onClick={() => {setActiveVotingPlace(undefined)}}>
                        <CloseIcon fontSize='small' sx={{color:'white', }} />
                    </IconButton>
                }
            />
            <Box sx={{backgroundColor:'#db282e'}}>
                <Typography paragraph fontFamily='BC Sans' variant='body1' marginLeft='10px' marginBottom='0px' sx={{color:'white',fontSize:'1.5em', fontWeight:'bold'}}>{vp.buildingName}</Typography>
                <Typography fontFamily='BC Sans' variant='h6' marginLeft='10px' paddingBottom='16px' sx={{color:'white'}}>{vp.edName}</Typography>
                {/* <Typography paragraph fontFamily='Bebas Neue' variant='h4' marginLeft='16px' sx={{color:'white'}}>{vp.buildingName}</Typography>
                <Typography fontFamily='Bebas Neue' variant='h5' marginLeft='16px' paddingBottom='16px' sx={{color:'white'}}>{vp.edName}</Typography>                 */}
            </Box>
            { !vp.isClosure &&
                <Stack justifyContent="flex-end" marginTop='-24px'>
                    <Fab size='medium' sx={{marginRight: '16px', marginLeft: 'auto', backgroundColor: 'white'}} onClick={handleDirectionsClick}><DirectionsIcon color='action' /></Fab>
                </Stack>
            }
            <Box sx={{backgroundColor:'white', overflowY: 'auto'}}>
                <Typography marginLeft='10px' variant='h5' fontFamily='BC Sans'>{vp.votingPlaceType}</Typography>
                {/* Use padding 10px for stack below if medium icon, 5px for large */}
                <Stack direction='column' spacing={2} sx={{padding:'10px'}}> 
                    <Stack direction='row' alignItems="center" spacing={2}>
                        <WhereToVoteIcon fontSize='medium' sx={{color:'#db282e'}} />
                        {/* <WhereToVoteIcon sx={{color: (eventInfo?.state === AppStates.POST_ADVANCE || eventInfo?.state === AppStates.GENERAL ? vp.icon2 : vp.icon1), stroke:'black', strokeWidth: 0.5}} fontSize='medium' /> */}
                        {/* marginLeft of 11px only if large icon used, otherwise do not specify margin for typography below */}
                        <Typography fontFamily='BC Sans'>{vp.streetAddress}, {vp.locality}</Typography>
                    </Stack>
                    { 
                        eventInfo && 
                            (renderOpenClosed(vp,advDays,eventInfo,userState == UserStates.ED ? inEdVotingPlaces : nearestVotingPlaces))
                            // (isOpenVotingPlace(vp.votingPlaceTypeCode, new Date(Date.now()), advDays, eventInfo)) ? (
                            //     <Typography paddingLeft='40px' sx={{color:'#db282e'}}>Open Now</Typography>
                            // ) : (
                            //     <Typography paddingLeft='40px' sx={{color:'#db282e'}}>Now Closed</Typography>
                            // ) 
                    }
                </Stack>        
            </Box>
            { eventInfo && !vp.isClosure && isOpenVotingPlace(vp.votingPlaceTypeCode, new Date(Date.now()), advDays, eventInfo) && vp.waitTime >= 0 && vp.uploadDateTime && (vp.votingPlaceTypeCode === VPTypeCodes.ADVANCE || vp.votingPlaceTypeCode === VPTypeCodes.ADVANCE_FINAL) &&
                <>
                <Box width='100%'>
                    <Stack direction='column' spacing={2}  sx={{backgroundColor:'white', padding:'10px'}}> 
                        <Stack direction='row' alignItems="center" spacing={2}>
                            <Icon path={mdiAccountClockOutline} size={1} color='#db282e' />
                            { renderWaitTime(vp) }
                        </Stack>
                    </Stack>
                </Box>
                <Typography paddingLeft='50px' variant='body2' fontFamily='BC Sans' sx={{color:'#488fed'}}>Historical activity by hour</Typography>
                {/* <Typography variant='body2' marginLeft='50px' marginRight='auto'>Historical activity by hour</Typography> */}
                <Box width='100%' height={110}>
                    <WaitTimesChart />
                </Box>
                </>
            }
            
            { renderOpenDates(vp, advDays, eventInfo) }

            <Divider/>

            {/* <Stack direction='column' spacing={1.8} sx={{backgroundColor:'white', padding:'10px'}}>
                <Stack direction='row' alignItems="center" spacing={2} sx={{marginLeft: '40px'}}  >
                    <Chip label='Open Now' sx={{backgroundColor:'#db282e', color: 'white', fontWeight: 500, fontSize: '1.0rem', borderRadius: '5px'}} />
                    <Typography fontStyle={'italic'}>Less busy than usual</Typography>
                    <Typography fontStyle={'italic'}>Not too busy</Typography>
                    <Typography fontStyle={'italic'}>A little busy</Typography>
                    <Typography fontStyle={'italic'}>As busy as it gets</Typography>
                </Stack>
            </Stack>
            <Divider /> */}

            <Stack direction='column' spacing={2} sx={{backgroundColor:'white', padding:'10px'}}>
                <Stack direction='row' alignItems="center" spacing={2} >
                    <PeopleOutlineIcon fontSize='medium' sx={{color:'#db282e'}} />
                    <Link variant='body1' fontFamily='BC Sans' target="_blank" href={renderCandidateLink(eventInfo,userState === UserStates.ED ? vp.edName : undefined)}>
                        {userState === UserStates.ED ? `Candidates for ${vp.edName}` : 'Provincial Candidates'}
                    </Link>
                </Stack>
                { userState === UserStates.ED && 
                    <Stack direction='row' alignItems="center" spacing={2} >
                        <ApartmentIcon fontSize='medium' sx={{color:'#db282e'}} />
                        {/* <Icon path={mdiOfficeBuildingMarker} size={1.5} color='#db282e' /> */}
                        <Link variant='body1' fontFamily='BC Sans' target="_blank" href={renderDEOLink(eventInfo?.menuOptions)}>District Electoral Office for {vp.edName}</Link>
                    </Stack>
                }
                <Stack direction='row' alignItems="center" spacing={2} >
                    <PermDeviceInformationIcon fontSize='medium' sx={{color:'#db282e'}} />
                    <Typography fontFamily='BC Sans'>More Info:</Typography>
                    <Link variant='body1' fontFamily='BC Sans' target="_blank" href="tel:+18006618683">1-800-661-8683</Link>
                </Stack>
                { 
                    vp.isWheelChairAccessible ? (
                        <Stack direction='row' alignItems="center" spacing={2} >
                            <Icon path={mdiWheelchair} size={1} color='#db282e' />
                            <Typography fontFamily='BC Sans'>Wheelchair accessible</Typography>
                        </Stack>
                    ) : (
                        <Stack direction='row' alignItems="center" spacing={2} >
                            <BlockIcon fontSize='medium' sx={{color:'#db282e'}} />
                            <Typography fontFamily='BC Sans'>This building is not wheelchair accessible, but curbside voting is an option. Call 1-800-661-8683 to learn about accessible voting opportunities.</Typography>
                        </Stack>
                    )
                }                             
            </Stack>
        {/* </Card> */}
        </Box>
    );
}


