import {useEffect} from 'react';

import {
  useMapsLibrary,
  useMap
} from '@vis.gl/react-google-maps';
import { useDirectionsStore } from '../../store/layerStore';

// const API_KEY =
//   globalThis.GOOGLE_MAPS_API_KEY ?? (process.env.GOOGLE_MAPS_API_KEY as string);

export default function Directions() {
    const map = useMap();
    const routesLibrary = useMapsLibrary('routes');
    // const [directionsService, setDirectionsService] = useState<google.maps.DirectionsService>();
    // const [directionsRenderer, setDirectionsRenderer] = useState<google.maps.DirectionsRenderer>();
    // const { setRenderer } = useDirectionsStore();
    //   const [routes, setRoutes] = useState<google.maps.DirectionsRoute[]>([]);
    //   const [routeIndex, setRouteIndex] = useState(0);
    //   const selected = routes[routeIndex];
    //   const leg = selected?.legs[0];
    const {setMapRef, setRoutesLibraryRef} = useDirectionsStore();
 
    // Initialize directions service and renderer
    useEffect(() => {
        if (!routesLibrary || !map) return;
        // setDirectionsService(new routesLibrary.DirectionsService());
        // setDirectionsRenderer(new routesLibrary.DirectionsRenderer({map}));
        // setRenderer(directionsRenderer);
        setMapRef(map);
        setRoutesLibraryRef(routesLibrary);
    }, [routesLibrary, map]);

    // // Use directions service
    // useEffect(() => {
        
    //     if (!directionsService || !directionsRenderer || (!origin && !destination)) return;

    //     if (origin && destination) {
    //         directionsService
    //         .route({
    //             origin: origin, //'100 Front St, Toronto ON',
    //             destination: destination, //'500 College St, Toronto ON',
    //             travelMode: google.maps.TravelMode.DRIVING,
    //             provideRouteAlternatives: true
    //         })
    //         .then(response => {
    //             directionsRenderer.setDirections(response);
    //             directionsRenderer.setPanel(directionsPanelRef);
    //             setRoutes(response.routes);
    //             console.log(directionsRenderer.getPanel());
    //         });
    //     }

    //     return () => directionsRenderer.setMap(null);
    // }, [directionsService, directionsRenderer, origin, destination, directionsPanelRef]); 

    // // Update direction route
    // useEffect(() => {
    //     if (!directionsRenderer) return;
    //     directionsRenderer.setRouteIndex(routeIndex);
    // }, [routeIndex, directionsRenderer]);

    return (<></>);
}