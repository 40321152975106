import { Box, CircularProgress } from "@mui/material";
import React from "react";

export default function Loading(props: {minHeight?:string}) {
    // React.useEffect = () => {
    // }
    const dvh = window.CSS.supports('height', '100vh');
    return (
        <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight={`calc(100${dvh ? 'dvh' : 'vh'} - 128px)`}
        // minHeight={`calc(100vh - ${baseHeaderHeight + baseSearchHeight - (largeScreen ? 0 : 16)}px)`}
        >
            <CircularProgress sx={{color: '#db282e'}} />
        </Box>
    );
}