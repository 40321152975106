import { Box, Stack, Typography } from "@mui/material";

export default function PreWrit(props: {minHeight?:string}) {
    return (
        <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight={`calc(100vh - 128px)`}
        >
            <Stack direction='column' alignItems='center' margin='10%'>
                <Typography align="center" paragraph variant="h4">The 2024 Provincial General Election is scheduled for Saturday, October 19, 2024. Voting places for the election will be published here when available.</Typography>
                <Typography paragraph align="center" variant="h5">See the <a href="https://elections.bc.ca/2024-provincial-election/" target="_blank" rel="noreferrer">2024 Provincial Election</a> page for  more information.</Typography>
            </Stack>
        </Box>
    );
}