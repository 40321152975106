import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SearchIcon from "@mui/icons-material/Search";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';
import styled from 'styled-components';
import { InputAdornment, alpha } from '@mui/material';
import { SearchType, useAppStateStore, useLayerVisibilityStore } from '../../store/layerStore';
import { bs11EdInfo as edInfo } from '../GoogleMaps/utils/utils';
import { UserStates } from '../../util';
import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import { useEffect, useState, useRef } from 'react';


// This key was created specifically for the demo in mui.com.
// You need to create a new one for your application.
//const GOOGLE_MAPS_API_KEY = 'AIzaSyDKnNJeo0PnpWTTQ3DtMSPQrr6dQdxIUWc';//'AIzaSyC3aviU6KHXAjoSnxcw6qbOhjnFctbxPkE';
// const API_KEY = 'AIzaSyAjxt6nhZhlXOZvKqoFRVDbVzxa_GL_Jg4';

// const prepareSearchQuery = (query: string) => {
//     const url__ = `http://api.tvmaze.com/search/shows?q=${query}`;
//     const url_ = `https://wtv-address-search.search.windows.net/indexes/azuresql-index/docs?api-version=2020-06-30&search=${query}*&$top=10&api-key=xndNyuc8J3wOR0lFw6BnO8QACFxhdUXOtF8ZfqvkAnAzSeAcDSOD`;
//     const url = `https://wtv-addr-ed.search.windows.net/indexes/azuresql-index/docs?api-version=2020-06-30&search=${query}*&$top=10&api-key=l4VEL984tkJn6KEIrLJRUtCrTQdAXYIhMQM685emrYAzSeAVjXLM`;
//     const SEARCH_URL___ = `https://wtv-ed11-scored-pktest.search.windows.net/indexes/azuresql-index/docs?api-version=2020-06-30&search=${query}*&$top=10&api-key=mT98zbNzzoFCNxkGc5qmQRsV8ws5cY51NSAB10sUxOAzSeDO7HG2`;
//     const SEARCH_URL__ = `https://wtv-address-b11-scored-standbldgnum.search.windows.net/indexes/azuresql-index/docs?api-version=2020-06-30&search=${query}*&$top=10&api-key=HOxBb9mHWgb0G31M3Peg3P424Qbw8pVH4QJDlddtQLAzSeDW1cUG`;
//     const SEARCH_URL_ = `https://wtv-address-b11.search.windows.net/indexes/azuresql-index/docs?api-version=2020-06-30&search=${query}*&$top=10&api-key=RC0EhoQkrUcL7MKPFrBfTZLiTSFJe6ujTJbpMOOAbTAzSeDUxPHK`;
//     // const SEARCH_URL = `https://wtv-address-b11.search.windows.net/indexes/azuresql-index/docs?api-version=2020-06-30&search=${query}*&$top=10&api-key=VCHUfxeHttzb71vzzwSKcpAvLoWExmHsGvXpOLUYMQAzSeCoOgwa`;

//     const SEARCH_URL_EBC = `https://my-district-bs11-b.search.windows.net/indexes/azuresql-index/docs?api-version=2020-06-30&search=${query}*&$top=10&api-key=pJU9er3NmwpbYlN9DuCrvWxWbQqNdXZwvhgfIq18FXAzSeDrVdIp`;
//     // const SEARCH_URL = `https://my-district-bs11-b.search.windows.net/indexes/address-search-bs11-index/docs?api-version=2023-10-01-Preview&search=${query}*&$top=10&api-key=pJU9er3NmwpbYlN9DuCrvWxWbQqNdXZwvhgfIq18FXAzSeDrVdIp`;
//     // const SEARCH_URL = `https://my-district-bs11-b.search.windows.net/indexes/address-search-bs11-index/docs?api-version=2023-10-01-Preview&search=${query}*&$top=10&api-key=pJU9er3NmwpbYlN9DuCrvWxWbQqNdXZwvhgfIq18FXAzSeDrVdIp`;
//     //const SEARCH_URL =     `https://wtv-address-b11.search.windows.net/indexes/address-search-bs11-index/docs?api-version=2023-11-01&search=${query}*&$top=10&api-key=VCHUfxeHttzb71vzzwSKcpAvLoWExmHsGvXpOLUYMQAzSeCoOgwa`;
//     //const SEARCH_URL =     `https://wtv-address-b11.search.windows.net/indexes/address-search-bs11-index/docs?api-version=2023-11-01&search=${query}*&$top=10&api-key=VCHUfxeHttzb71vzzwSKcpAvLoWExmHsGvXpOLUYMQAzSeCoOgwa`;
//     //new forte const SEARCH_URL =     `https://wtv-address-b11.search.windows.net/indexes/my-district-search-bs11-index/docs?api-version=2023-10-01-Preview&search=${query}*&$top=10&api-key=VCHUfxeHttzb71vzzwSKcpAvLoWExmHsGvXpOLUYMQAzSeCoOgwa`;
//     //const SEARCH_URL = `https://my-district-bs11-b.search.windows.net/indexes/my-district-search-bs11-index/docs?api-version=2023-10-01-Preview&search=${query}*&$top=10&api-key=pJU9er3NmwpbYlN9DuCrvWxWbQqNdXZwvhgfIq18FXAzSeDrVdIp`;
//     const SEARCH_URL = `https://my-district-bs11-b.search.windows.net/indexes/my-district-search-bs11-index/docs?api-version=2020-06-30&search=${query}*&$top=10&api-key=pJU9er3NmwpbYlN9DuCrvWxWbQqNdXZwvhgfIq18FXAzSeDrVdIp`;

//     return encodeURI(SEARCH_URL);
//   };

// 2024June21: @CT commented
// function loadScript(src: string, position: HTMLElement | null, id: string) {
//     if (!position) {
//         return;
//     }

//     const script = document.createElement('script');
//     script.setAttribute('async', '');
//     script.setAttribute('id', id);
//     script.src = src;
//     position.appendChild(script);
// }

// const autocompleteService = { current: null };
// const placesService = { current: null };
// var svc: any;

interface MainTextMatchedSubstrings {
    offset: number;
    length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}
export interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
  place_id: string;
  result?: any;
}

const StyledTextField = styled(TextField)(({ theme }) => ({
    color: "#fff",
    background: "rgba(255, 255, 255, 0.15)",
    borderRadius: "4px",
    width: "100%",
    "& input": {
      color: "#fff !important",
      "&::placeholder": {
        color: 'white',
        opacity: 1.0,
      }
    },
    "& fieldset": {
      borderWidth: "0px",
      "& fieldset:hover, & fieldset:focus, & fieldset:active": {
        borderWidth: "0px"
      },
      "& .MuiInputBase-input": {
        padding: theme.spacing(2, 1, 1, 2),
        transition: theme.transitions.create("width"),
        color: "#fff",
        width: "100%",
        [theme.breakpoints.up("sm")]: {
          width: "12ch",
          "&:focus": {
            width: "20ch"
          }
        }
      }
    }
  }));

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.05),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.125),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('md')]: { // was sm but md resized to 100% width sooner
      //marginLeft: theme.spacing(1),
      width: '50%',
      marginLeft: 'auto',
      marginRight: 'auto', // was 0px, set to auto to force larger search bar to center in appbar
    },    
  }));

export default function AzureAutocomplete(props:any) {
    const isInitialMount = useRef(true);
    const [value, setValue] = React.useState<PlaceType | null>(null);
    const [inputValue, setInputValue] = React.useState('');
    const [initialized, setInitialized] = React.useState(false);
    const [options, setOptions] = React.useState<readonly PlaceType[]>([]);
    // const loaded = React.useRef(false);
    // const { setHighlightInfo, setEdInfo, setSearchPlace } = useLayerVisibilityStore();
    const { userState, setSearchPlace, setSelectedPlace, setLastSelectedPlace, defaultSearchValue, clearSearch, setClearSearch, setLastNearestSearch, setLastInEdSearch, setSearchLocation } = useAppStateStore();
    const placesLibrary = useMapsLibrary('places');
//   const routesLibrary = useMapsLibrary('routes');
    const map = useMap();
//   var placesSvc: google.maps.places.PlacesService;
    const [placesService, setPlacesService] = useState<google.maps.places.PlacesService>();
    const [autocompleteService, setAutocompleteService] = useState<google.maps.places.AutocompleteService>();

    const fetch = React.useMemo(
        () => debounce( 
            (
                request: google.maps.places.AutocompletionRequest, //{ input: string, componentRestrictions: google.maps.GeocoderComponentRestrictions, locationRestriction?: google.maps.LatLngBoundsLiteral, locationBias?: google.maps.LatLngBoundsLiteral },
                callback: (results: google.maps.places.AutocompletePrediction[] | null,status:google.maps.places.PlacesServiceStatus) => void,
            ) => {
                autocompleteService?.getPlacePredictions(request,callback);
                // (autocompleteService.current as any).getPlacePredictions(request, callback,);
            }, 400,
        ),
        [autocompleteService],
    );  

    const fetchGooglePlace = React.useMemo(
        () =>
            // debounce(
                async (
                    request: google.maps.places.PlaceDetailsRequest,
                    callback: (results: google.maps.places.PlaceResult | null, status: google.maps.places.PlacesServiceStatus) => void,
                ) => {
                    placesService?.getDetails(request, callback);
                    // (placesService.current as any).getDetails(request, callback);
                },[placesService]
            // ),[]
    );

//   function handleLocationChange(p: PlaceType | null) {
//     if (p && p.result) {
//         // map?.setCenter({ lat: p?.result.y, lng: p?.result.x });
//         //console.log(p.result);

//         // @CT commented 2024Jan11
//         // const bounds = edBounds(p.result.ed_abbr);
//         // setBounds(bounds);
//         // setEd(p.result.ed_abbr);
//         // // map?.fitBounds(bounds);
//         setSearchPlace(p);
//         setHighlightInfo(undefined);
//         const info = edInfo(p.result.ed_abbr);
//         setEdInfo(info);
//     } else if (p === null) {
//       setEdInfo(null);
//       setSearchPlace(p);
//     }
//   }
    // Initialize directions service and renderer
    useEffect(() => {
        if (isInitialMount.current) {
            if (!map || !placesLibrary) { return }
            setPlacesService(new placesLibrary.PlacesService(map));
            setAutocompleteService(new placesLibrary.AutocompleteService()); 
            isInitialMount.current = false;
        }
    }, [map, placesLibrary]);

    useEffect(() => {
        if (clearSearch) {
            setInputValue('');
            setValue(null);
            setClearSearch(false);
        }
    }, [clearSearch])

    useEffect(() => {   
        let active = true; 
        if(!initialized && defaultSearchValue) {
            setInputValue(defaultSearchValue?.description);
            setValue(defaultSearchValue);
            setInitialized(true);
        }
        
        if (inputValue === '') {  }
        fetch(
            { 
                input: inputValue, 
                componentRestrictions: {country: 'ca'}, 
                locationBias: { west: -139.06, south: 48.30, east: -114.03, north: 60.00},
                types: ['geocode']
            }, (results: google.maps.places.AutocompletePrediction[] | null, status: google.maps.places.PlacesServiceStatus) => {
            if (active) {
                let newOptions: readonly PlaceType[] = [];
                // This is adding the full value to the options.
                // if (value) {
                //     newOptions = [value];
                // }

                if (results) {
                    //newOptions = [...newOptions, ...results.filter(r => r.structured_formatting.secondary_text.includes(' BC,'))];
                    // newOptions = [...newOptions, ...results.filter(r => r.description.includes(' BC,'))];
                    newOptions = [...newOptions, ...results];
                }
                setOptions(newOptions);
            }
        });

    return () => {
      active = false; 
    };
  }, [value, inputValue, fetch, defaultSearchValue, clearSearch]);

    return (
        // <APIProvider apiKey={API_KEY} libraries={['places',]}>
            <StyledAutocomplete
                id="ed-address-search"
                sx={ {position: 'relative'} }
                getOptionLabel={ (option) =>
                    typeof option === 'string' ? option : option.description
                }
                filterOptions={ (x) => x }
                options={ options }
                autoComplete
                includeInputInList
                filterSelectedOptions
                value={ value }
                noOptionsText="No locations"
                onChange={(event: any, newValue: PlaceType | null) => {
                    // We have the place here, it only has the description and a place id but now location.
                    console.log("*** AzureAutocomplete.onChange - ",newValue);
                    setOptions(newValue ? [newValue, ...options] : options);
                    setValue(newValue);
                    setSearchPlace(newValue);
                    if (userState === UserStates.NB) {
                        setLastNearestSearch({
                            type: SearchType.GEOCODE, 
                            location: null,
                            parameter: newValue?.description || '',
                            placeId: newValue?.place_id || null
                        });
                    } else {
                        setLastInEdSearch({
                            type: SearchType.GEOCODE, 
                            location: null,
                            parameter: newValue?.description || '',
                            placeId: newValue?.place_id || null
                        });
                    }

                    // @CT commented 2024Jan11
                    // setSearchPlace(newValue); 
                    // handleLocationChange(newValue);
                    if (newValue && newValue.place_id) 
                        fetchGooglePlace(
                            {placeId: newValue ? newValue.place_id: '', fields:['geometry','address_components','vicinity']},(placeResult, status) => {
                                // The result is a google place object
                                console.log("AzureAutoComplete",placeResult);
                                setSelectedPlace(placeResult);
                                setLastSelectedPlace(placeResult);
                                if (userState === UserStates.ED) {

                                } else {

                                }
                                setSearchLocation(placeResult?.geometry?.location ? {lat: placeResult?.geometry?.location.lat(), lng: placeResult?.geometry?.location.lng()}  : null);                                
                            }
                        );
                }}
                onInputChange={(event, newInputValue) => {setInputValue(newInputValue);} }
                renderInput={(params) => (
                    <StyledTextField
                        {...params}
                        placeholder={userState === UserStates.ED ? "Enter your home address..." : 'Enter your location or press "Get Location"...'}
                        size="small"
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon style={{ color: "white", opacity: 1.0, marginLeft: "8px" }}/>                           
                                </InputAdornment>
                            ),                           
                        }}
                    />
                )}    
                renderOption={(props, option) => {
                    const matches = option.structured_formatting.main_text_matched_substrings || [];
                    const parts = parse(
                        option.structured_formatting.main_text,
                        matches.map((match: any) => [match.offset, match.offset + match.length]),
                    );

                    return (
                        <li {...props}>
                            <Grid container alignItems="center">
                                <Grid item sx={{ display: 'flex', width: 44 }}>
                                    <LocationOnIcon sx={{ color: 'text.secondary' }} />
                                </Grid>
                                <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                    {
                                        parts.map((part, index) => (
                                            <Box key={index} component="span" sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}>
                                                {part.text}
                                            </Box>
                                        ))
                                    }
                                    <Typography variant="body2" color="text.secondary">
                                        {option.structured_formatting.secondary_text}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </li>
                    );
                }}
            />
        // </APIProvider>
    );
}
