import { MemoryRouter, Route, Routes } from "react-router-dom";
import Layout from './components/routes/Layout';
import Loading from './components/routes/Loading';
import Closed from './components/routes/Closed';
import NoPage from './components/routes/NoPage';

export default function App() {
    const dvh = window.CSS.supports('height', '100vh');
    return (
        <MemoryRouter>
        <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Loading minHeight={`calc(100${dvh ? 'dvh' : 'vh'} - 128px)`} />} />
          <Route path="closed" element={<Closed />} />
          {/* <Route path="contact" element={<Contact />} /> */}
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
      </MemoryRouter>
    );
}