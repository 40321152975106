import { IVotingPlaceHours } from "../model/VotingPlace"

export const DeoHours: IVotingPlaceHours = {
    other: [
        {day: 0, open: 0, closed: 0},
        {day: 1, open: 9, closed: 17},
        {day: 2, open: 9, closed: 17},
        {day: 3, open: 9, closed: 17},
        {day: 4, open: 9, closed: 17},
        {day: 5, open: 9, closed: 17},
        {day: 6, open: 10, closed: 16},
    ],
    advance: {day: 0, open: 8, closed: 20},
    final: {day: 0, open: 8, closed: 20}
}

export const VpHours: IVotingPlaceHours = {
    other: [],
    advance: {day: 0, open: 8, closed: 20},
    final: {day: 0, open: 8, closed: 20}
}

export const BCBounds = () => {
    return { east: -114.054 , north: 60.0 , south: 48.3 , west: -139.057 };
}

// export const BCBounds = new google.maps.LatLngBounds(
//     new google.maps.LatLng(48.3, -139.057),
//     new google.maps.LatLng(60.0, -114.054));

export const UserStates = {
    DF: 'Default',
    NB: 'Nearby',
    ED: 'In-ED'
}
  
// The application states
export const AppStates = {
    LOADING: 'loading',
    PRE_WRIT : 'pre_writ',
    PRE_ADVANCE: 'pre_advance',
    ADVANCE_1: 'advance_1',
    ADVANCE_2: 'advance_2',
    ADVANCE_3: 'advance_3',
    ADVANCE_4: 'advance_4',
    ADVANCE_5: 'advance_5',
    ADVANCE_6: 'advance_6',
    ADVANCE_7: 'advance_7',
    ADVANCE_8: 'advance_8',
    ADVANCE_9: 'advance_9',
    POST_ADVANCE: 'post_advance',
    GENERAL: 'general',
    CLOSED: 'closed',
    ERROR: 'error',
    MULTI: 'multi',
}

// The scope of the application
export const AppScopes = {
    GENERAL: 'general',
    FINAL: 'final',
    BYELECTION: 'byelection',
    MULTI: 'multi_byelection'                // multiple by-election events.  The app will forward to separate instance.
}

// The types of google directions we are allowing the application to request
export const DirectionTypes = {
    'DRIVING': 'DRIVING',
    'TRANSIT': 'TRANSIT',
    'WALKING': 'WALKING',
    'BICYCLING': 'BICYCLING'
  }
  
/**
 * Codes for voting place types in the voting place tables
 */
export const VPTypeCodes = {
    FINAL: 3,
    ADVANCE: 4,
    ADVANCE_FINAL: 10,
    DEO: 99
}

export const MenuOptionKeys = { 
    ADVANCE_VOTING_PLACE: 'ADVANCE_VOTING_PLACE',
    CANDIDATES: 'CANDIDATES',
    DISTRICT_ELECTORAL_OFFICE: 'DISTRICT_ELECTORAL_OFFICE',
    FINAL_VOTING_PLACE: 'FINAL_VOTING_PLACE',
    MORE_ABOUT_EVENT: 'MORE_ABOUT_EVENT',
}

/**
 * Different statuses for VoterELectoralDistrictInfo requests
 */
export const edInfoStatuses = {
    OK: "OK",
    NO_SI_RESULTS_FOR_RANGE: "NO_SI_RESULTS_FOR_RANGE",
    NO_SI_RESULTS_FOR_RANGE_ED_ONLY: "NO_SI_RESULTS_FOR_RANGE_ED_ONLY",
    NO_SI_RESULTS: "NO_SI_RESULTS",
    NO_EVENT: "NO_EVENT",
    INVALID_CONTENT: "INVALID_CONTENT",
    PARSE_CONTENT_FAIL: "PARSE_CONTENT_FAIL",
    MULTIPLE_RESULTS: "MULTIPLE_RESULTS",
    FAILURE: "FAILURE",
    NO_CONTENT: "NO_CONTENT",
    SVA_EDVA_RESULT: "SVA_EDVA_RESULT"
}

export const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const weekDaysShort = ["Sun","Mon","Tues","Wed","Thu","Fri","Sat"];

export const weekDaysLong = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];