import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { IEventInfo } from "../model/Event";
import { IBcpagResult, IVoterElectoralDistrictInfo, IVotingPlaceResult } from "../model/VotingPlace";
import { UserStates } from "../util";
import * as turf  from "@turf/turf";
import { useEventInfoStore, useVotingPlaceStore, useAppStateStore, SearchType } from "../store/layerStore";
import { useMap, useMapsLibrary } from "@vis.gl/react-google-maps";
import { PlaceType } from "../components/Search/AzureAutocomplete";
import { OpenVotingPlaces, ClosedVotingPlaces } from "../util/votingPlace";
import { useNavigate } from "react-router-dom";
import { API_HOST } from ".";

// export const API_HOST: string = "https://wtv-api.azurewebsites.net";
// export const API_HOST: string = "http://localhost:49740";

export const EVENT_SCOPE_URL: string = `${API_HOST}/api/EventInfo`;
//export const EVENT_SCOPE_URL: string = "https://wtv-api.azurewebsites.net/api/EventInfo";

export const NEAREST_VP_URL: string = `${API_HOST}/api/NearestVotingPlaces`;
//export const NEAREST_VP_URL: string = "https://wtv-api.azurewebsites.net/api/NearestVotingPlaces";

/* Will return the VPs closest to the voter's address (ed, score, x, y)*/
export const ED_VP_URL: string = `${API_HOST}/api/EDVotingPlaces`;
//export const ED_VP_URL: string = "https://wtv-api.azurewebsites.net/api/EDVotingPlaces";

/* Will return the ED for the voter location */
export const VOTER_ED_URL: string = `${API_HOST}/api/VoterElectoralDistrictInfo`;
//export const VOTER_ED_URL: string = "https://wtv-api.azurewebsites.net/api/VoterElectoralDistrictInfo";

export const VP_WAIT_TIME_URL: string = `${API_HOST}/api/VotingPlaceWaitTime?addressStandardId=`;
//export const VP_WAIT_TIME_URL: string = "https://wtv-api.azurewebsites.net/api/VotingPlaceWaitTime?addressStandardId=";

// const BCPAG_ADDRESS_URL = (formattedAddress: string) => `https://apps.gov.bc.ca/pub/geocoder/addresses.json?apiKey=z5dD1QrvMtVO3qWDNX4iYgQjvHEhiNYo&addressString=${formattedAddress}&locationDescriptor=any&maxResults=1&interpolation=adaptive&echo=true&setBack=0&outputSRS=4326&minScore=1&provinceCode=BC`;
const BCPAG_ADDRESS_URL = (formattedAddress: string) => `https://geocoder.api.gov.bc.ca/addresses.json?apiKey=z5dD1QrvMtVO3qWDNX4iYgQjvHEhiNYo&addressString=${formattedAddress}&locationDescriptor=any&maxResults=1&interpolation=adaptive&echo=true&setBack=0&outputSRS=4326&minScore=1&provinceCode=BC`;

/**
* Different statuses for VoterELectoralDistrictInfo requests
*/
export const edInfoStatuses = {
    OK: "OK",
    NO_SI_RESULTS_FOR_RANGE: "NO_SI_RESULTS_FOR_RANGE",
    NO_SI_RESULTS_FOR_RANGE_ED_ONLY: "NO_SI_RESULTS_FOR_RANGE_ED_ONLY",
    NO_SI_RESULTS: "NO_SI_RESULTS",
    NO_EVENT: "NO_EVENT",
    INVALID_CONTENT: "INVALID_CONTENT",
    PARSE_CONTENT_FAIL: "PARSE_CONTENT_FAIL",
    MULTIPLE_RESULTS: "MULTIPLE_RESULTS",
    FAILURE: "FAILURE",
    NO_CONTENT: "NO_CONTENT",
    SVA_EDVA_RESULT: "SVA_EDVA_RESULT"
  }

export const VoterElectoralDistrictInfoListener = () => {
    useEffect(() => {},[]);
    return null;
}

export const EDVotingPlacesListener = () => {
    useEffect(() => {},[]);
    return null;
}

export const UserStateListener = () => {
    const {lastInEdSearch, setLastInEdSearch, lastNearestSearch, setLastNearestSearch, userState, searchPlace, selectedPlace, setSelectedPlace, lastSelectedPlace, setLastSelectedPlace, setVoterElectoralDistrictInfo} = useAppStateStore();
    const { nearestVotingPlaces, setNearestVotingPlaces, inEdVotingPlaces, setInEdVotingPlaces } = useVotingPlaceStore();


    useEffect(() => {
        if (userState === UserStates.ED) {
            console.log('*** UserStateListener IN ED');
            if (lastInEdSearch !== null && searchPlace && searchPlace !== null) {
                // ! CHECK FOR NULL OR EMPTY SEARCH PLACE
                if (lastInEdSearch.placeId === searchPlace?.place_id) {
                    console.log(`EQUAL: ${lastInEdSearch.parameter} ${searchPlace?.description}`);
                } else if (lastInEdSearch.placeId !== null && lastInEdSearch.placeId !== searchPlace?.place_id) {
                    console.log(`RE_QUERY: Expecting results for ${lastInEdSearch.parameter} but latest are ${searchPlace?.description}`);
                    setInEdVotingPlaces([]);
                    setVoterElectoralDistrictInfo(null);
                    const p: google.maps.places.PlaceResult = { address_components: lastSelectedPlace?.address_components, geometry: lastSelectedPlace?.geometry, html_attributions: [], rating: 0}
                    setLastInEdSearch({
                        type: SearchType.GEOCODE, 
                        location: null,
                        parameter: searchPlace?.description || null,
                        placeId: searchPlace?.place_id || null
                    });
                    setLastSelectedPlace(p);                    
                    setSelectedPlace(p);
                }
            } else {
                setInEdVotingPlaces([]);
                setVoterElectoralDistrictInfo(null);
                setLastInEdSearch({
                    type: SearchType.GEOCODE,
                    location: null,
                    parameter: null,
                    placeId: null
                });
            }
        } else {
            console.log('*** UserStateListener NEAREST');
            if (lastNearestSearch !== null && searchPlace && searchPlace !== null) {
                if (lastNearestSearch.placeId === searchPlace?.place_id) {
                    console.log(`EQUAL: ${lastNearestSearch.parameter} ${searchPlace?.description}`);
                } else {
                    console.log(`RE_QUERY: Expecting results for ${lastNearestSearch.parameter} but latest are ${searchPlace?.description}`);
                    console.log('**** > setNearestVotingPlaces - B');
                    setNearestVotingPlaces([]);
                    const p: google.maps.places.PlaceResult = { address_components: lastSelectedPlace?.address_components, geometry: lastSelectedPlace?.geometry, html_attributions: [], rating: 0}
                    setLastNearestSearch({
                        type: SearchType.GEOCODE,
                        location: null,
                        parameter: searchPlace?.description || null,
                        placeId: searchPlace?.place_id || null
                    });
                    setLastSelectedPlace(p);             
                    setSelectedPlace(p);
                }
            } else if (lastNearestSearch !== null && lastNearestSearch.type === SearchType.LOCATION && searchPlace === null) {
                // Do nothing, leave the VP list as-is.
            } else {
                if (nearestVotingPlaces !== null) {
                    console.log('**** > setNearestVotingPlaces - A',nearestVotingPlaces);
                    setNearestVotingPlaces(null);
                }
                setLastNearestSearch({
                    type: SearchType.GEOCODE,
                    location: null,
                    parameter: null,
                    placeId: null
                });
            }
        }
    },[userState]);
    return null;
}

export const EventInfoListener = () => {
    const { eventInfo } = useEventInfoStore();
    const navigate = useNavigate();

    useEffect(() => {
        if (eventInfo) {
            navigate("/closed");
        }
    },[eventInfo, navigate]);
    return null;
}

export const SearchLocationListener = () => {
    const { searchLocation, lastNearestSearch } = useAppStateStore();
    const { eventInfo} = useEventInfoStore();
    const { setNearestVotingPlaces, setVotingPlaceSearchResults, } = useVotingPlaceStore();

    const map = useMap();
    useEffect(() => {
        if (searchLocation && map) {
            if (searchLocation && eventInfo) {
                console.log("*** SearchLocationListener",lastNearestSearch,searchLocation);
                fetchNearestVotingPlaces({lng: searchLocation.lng, lat: searchLocation.lat, score: eventInfo.score ? eventInfo.score : 1}, (vpResult?: IVotingPlaceResult) => {
                    if (vpResult) {
                        vpResult.votingPlaces.forEach(r => {
                            r.distance = turf.distance(turf.point([r.latLng.lng, r.latLng.lat]), turf.point([searchLocation.lng, searchLocation.lat]));
                            if (r.uploadDateTime) {
                                // Assume the last update time is stored in UTC, ensure the Date object is created to reflect this.
                                const dbDate = new Date(r.uploadDateTime);
                                const uploadDateTime: number = Date.UTC(dbDate.getFullYear(), dbDate.getMonth(), dbDate.getDate(), dbDate.getHours(), dbDate.getMinutes(), dbDate.getSeconds());                         
                                r.uploadDateTime = new Date(uploadDateTime);
                            } else r.uploadDateTime = new Date(0);
                        });
                        // setVotingPlaceSearchResults(vpResult.votingPlaces);
                        console.log('**** > SearchLocationListener.setNearestVotingPlaces - C',searchLocation);
                        setNearestVotingPlaces(vpResult.votingPlaces);
                    }
                })
            }

            // //map?.panTo(activeVotingPlace.latLng);
            // map.setCenter(searchLocation);
            // map.setZoom(15);
            // // setSearchLocation(searchLocation);
        }
    }, [searchLocation, eventInfo, map]);

    return null;
}

export const NearestVotingPlacesListener = () => {
    const { nearestVotingPlaces } = useVotingPlaceStore();
    const { userLocation } = useAppStateStore();
    const { eventInfo } = useEventInfoStore();

    const map = useMap();
    useEffect(() => {
        if (map && eventInfo && nearestVotingPlaces && nearestVotingPlaces.length > 0) {
            let vps = OpenVotingPlaces(eventInfo, nearestVotingPlaces);
            let bnds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
            if (vps.length > 0) {
                console.log('*** NearestVotingPlacesListener: Open',vps[0]);
                bnds.extend(vps[0].latLng);
            } else {
                console.log('*** NearestVotingPlacesListener: Closed',vps[0]);
                vps = ClosedVotingPlaces(eventInfo, nearestVotingPlaces);
                if (vps) bnds.extend(vps[0].latLng);
            }

            if (userLocation) bnds.extend(userLocation);

            // const fc: turf.FeatureCollection = turf.featureCollection(nearestVotingPlaces.map(vp => turf.point([vp.latLng.lng, vp.latLng.lat])));
            // if (userLocation) {
            //     fc.features.push(turf.point([userLocation.lng, userLocation.lat]));
            // }
            // const bbox: turf.helpers.BBox = turf.bbox(fc);

            
            // const bnds: google.maps.LatLngBoundsLiteral = {west: bbox[0], south: bbox[1], east: bbox[2], north:bbox[3], }
            map.fitBounds(bnds);
            map.setZoom((map.getZoom() || 15) - 1);
        }
    },[map, nearestVotingPlaces]);
    return null;
}

export const InEdVotingPlacesListener = () => {
    const { inEdVotingPlaces, setBcpagResult } = useVotingPlaceStore();
    const { userLocation, setSelectedPlace, voterElectoralDistrictInfo, searchPlace, selectedPlace } = useAppStateStore();
    const { eventInfo } = useEventInfoStore();

    const map = useMap();
    useEffect(() => {
        if (map && eventInfo && inEdVotingPlaces) { // && inEdVotingPlaces.length > 0) {
            if (inEdVotingPlaces.length > 0) {
                let vps = OpenVotingPlaces(eventInfo, inEdVotingPlaces);

                let bnds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
                if (vps.length > 0) {
                    console.log('*** InEdVotingPlacesListener: Open',vps[0]);
                    bnds.extend(vps[0].latLng);
                } else {
                    console.log('*** InEdVotingPlacesListener: Closed',vps[0]);
                    vps = ClosedVotingPlaces(eventInfo, inEdVotingPlaces);
                    if (vps) bnds.extend(vps[0].latLng);
                }

                if (selectedPlace?.geometry?.location) bnds.extend(selectedPlace?.geometry?.location);

                map.fitBounds(bnds);
                map.setZoom((map.getZoom() || 15) - 1);
            } else {
                if (selectedPlace?.geometry?.location) {
                    map.panTo(selectedPlace?.geometry?.location);
                    map.setZoom(15);  
                }
            }
        }
        //@CT HERE
        setSelectedPlace(null);
        setBcpagResult(null);         

            // let tfc: turf.FeatureCollection;
            // let pt: any;
            // if (vps.length > 0) {
            //     vps = vps.filter(v => v.addressStandardId === voterElectoralDistrictInfo?.assignedVotingPlace.id);
            //     if (selectedPlace?.geometry?.location) {
            //         pt = turf.point([selectedPlace?.geometry?.location.lng(), selectedPlace?.geometry?.location.lat()]);
            //     }
            // }
            // //(vps.length === 0) {
            // else { 
            //     vps = ClosedVotingPlaces(eventInfo, inEdVotingPlaces);
            //     if (userLocation) {
            //         pt = turf.point([userLocation.lng, userLocation.lat]);
            //     }
            // }
            // const fc: turf.FeatureCollection = turf.featureCollection(vps.map(vp => turf.point([vp.latLng.lng, vp.latLng.lat])));
            // // if (userLocation) {
            // //     fc.features.push(turf.point([userLocation.lng, userLocation.lat]));
            // // }
            // if (pt) {
            //     fc.features.push(pt);
            // }

            // if (fc.features.length > 1) {
            //     const bbox: turf.helpers.BBox = turf.bbox(fc);
            //     // const bboxPolygon = turf.bboxPolygon(bbox);
            //     const bnds: google.maps.LatLngBoundsLiteral = {west: bbox[0], south: bbox[1], east: bbox[2], north:bbox[3], }
            //     map.fitBounds(bnds);
            //     map.setZoom((map.getZoom() || 15) - 1);
            // } else if (userLocation) {
            //     map.panTo(userLocation);
            //     map.setZoom(15);
            // }

            // var bounds;
            // for (const vp of nearestVotingPlaces) {
            //     if (!bounds) {

            //     }
            // }
        //     setSelectedPlace(null);
        //     setBcpagResult(null);
        // }


    },[map, inEdVotingPlaces]);
    return null;
}

export const ParameterPlaceIdListener = () => {
    const isInitialMount = useRef(true);
    const { loadingPlaceId, setLoadingPlaceAddress, setSelectedPlace, setLastSelectedPlace, setDefaultSearchValue, setSearchLocation, setLastNearestSearch } = useAppStateStore();
    const map = useMap();
    const placesLibrary = useMapsLibrary('places');
    const [placesService, setPlacesService] = useState<google.maps.places.PlacesService>();

    const fetchGooglePlace = React.useMemo(
        () => async (
            request: google.maps.places.PlaceDetailsRequest,
            callback: (results: google.maps.places.PlaceResult | null, status: google.maps.places.PlacesServiceStatus) => void,
        ) => {
            placesService?.getDetails(request, callback);
        },[placesService]
    );

    // Initialize directions service and renderer
    // useEffect(() => {
    //     console.log(`** ParameterPlaceIdListener: init`);
    //     if (isInitialMount.current) {
    //         console.log(`** ParameterPlaceIdListener: init initial mount`);
    //         if (!map || !placesLibrary) { return }
    //         setPlacesService(new placesLibrary.PlacesService(map)); 
    //         isInitialMount.current = false;
    //     }
    // }, [map, placesLibrary]);

    useEffect(() => {
        // if (isInitialMount.current) { return; }
        if (isInitialMount.current) {
            if (!map || !placesLibrary) { return }
            setPlacesService(new placesLibrary.PlacesService(map)); 
            isInitialMount.current = false;
        } else {
            if (map && placesService && loadingPlaceId) {
                console.log(`** ParameterPlaceIdListener: fetching ${loadingPlaceId}`);
                fetchGooglePlace(
                    { placeId: loadingPlaceId }, (placeResult) => {
                        // The result is a google place object
                        console.log('** ParameterPlaceIdListener: fetched place',placeResult);
                        setSelectedPlace(placeResult);
                        setLastSelectedPlace(placeResult); 
                        setSearchLocation(placeResult?.geometry?.location ? {lat: placeResult?.geometry?.location.lat(), lng: placeResult?.geometry?.location.lng()}  : null);
                        const def: PlaceType = { 
                            place_id: loadingPlaceId, 
                            description: placeResult?.formatted_address || '', 
                            structured_formatting:  { 
                                main_text: placeResult?.formatted_address || '', 
                                secondary_text: ''
                            }
                        }
                        setDefaultSearchValue(def);
                        setLoadingPlaceAddress(def || null);
                        setLastNearestSearch({
                            type: SearchType.GEOCODE, 
                            location: placeResult?.geometry?.location || null,
                            parameter: placeResult?.formatted_address || '',
                            placeId: loadingPlaceId
                        });
                    }
                );
                // setLoadingPlaceId(null);
            } else {
                return;
            }
        }        
    },[map, placesLibrary, loadingPlaceId, placesService]);
    return null;
}

export const BcpagListener = () => {
    const { bcpagResult } = useVotingPlaceStore();

    useEffect(() => {
        if (bcpagResult === null) return;
    }, [bcpagResult]);
    return null;
}

export const ParseFormattedAddress = (addressComponents: google.maps.GeocoderAddressComponent[]) => {
    const n = addressComponents.filter(a => a.types[0] === "street_number").length > 0 ? addressComponents.filter(a => a.types[0] === "street_number")[0].short_name : undefined;
    const r = addressComponents.filter(a => a.types[0] === "route").length > 0 ? addressComponents.filter(a => a.types[0] === "route")[0].short_name : undefined;
    const l = addressComponents.filter(a => a.types[0] === "locality").length > 0 ? addressComponents.filter(a => a.types[0] === "locality")[0].short_name : undefined;
    const a2 = addressComponents.filter(a => a.types[0] === "administrative_area_level_2").length > 0 ? addressComponents.filter(a => a.types[0] === "administrative_area_level_2")[0].short_name : undefined;
    const a3 = addressComponents.filter(a => a.types[0] === "administrative_area_level_3").length > 0 ? addressComponents.filter(a => a.types[0] === "administrative_area_level_3")[0].short_name : '';
    const addr = `${n ? n : ''} ${r ? r : ''} ${l ? l : (a2 ? a2 : a3)}`;
    return addr;
}

export const PlaceSearchListener = () => {
    const { eventInfo } = useEventInfoStore();
    const { userState, searchPlace, selectedPlace, setSelectedPlace, setUserElectoralDistrict, setUserLocation, setVoterElectoralDistrictInfo, setResultsLoading } = useAppStateStore();
    const { setNearestVotingPlaces, setInEdVotingPlaces, setVotingPlaceSearchResults, bcpagResult, setBcpagResult } = useVotingPlaceStore();
    
    useEffect(() => {
        if (selectedPlace && selectedPlace !== null) {      
            console.log('> PlaceSearchListener.useEffect()'); 
            if (selectedPlace.geometry?.location)
                setUserLocation({lat: selectedPlace.geometry?.location?.lat(), lng: selectedPlace.geometry?.location?.lng()});

            setResultsLoading(true);
            // setMessage("Loading...");

            switch (userState) {
                case UserStates.ED:
                    console.log('** PlaceSearchListener - UserState check', UserStates.ED, selectedPlace.address_components, bcpagResult);
                    // setUserElectoralDistrict(null);
                    // setVoterElectoralDistrictInfo(null);

                    // The Google Place should have a street number if we are going to send it to the WTV API
                    // Unless we have a bcPagObject - then send both to the WTV API
                    if ((selectedPlace.address_components && selectedPlace.address_components[0].types[0] === 'street_number') || bcpagResult !== null) {
                        console.log('** PlaceSearchListener - Before fetchVoterEdInfo',selectedPlace,bcpagResult, eventInfo?.score);
                        fetchVoterEdInfo(selectedPlace, bcpagResult, eventInfo?.score, (voterEDResponse) => { 
                            setVoterElectoralDistrictInfo(voterEDResponse || null);
                            switch (voterEDResponse?.status) {
                                case edInfoStatuses.OK:
                                case edInfoStatuses.NO_SI_RESULTS_FOR_RANGE:
                                case edInfoStatuses.NO_SI_RESULTS_FOR_RANGE_ED_ONLY: {
                                    setUserElectoralDistrict(voterEDResponse.electoralDistrict.edAbbr);
                                    setVoterElectoralDistrictInfo(voterEDResponse);
                                    fetchInEDVotingPlaces(
                                        {
                                            ed: voterEDResponse?.electoralDistrict.edAbbr, 
                                            lat: selectedPlace?.geometry?.location?.lat(), 
                                            lng: selectedPlace?.geometry?.location?.lng(), 
                                            score: eventInfo?.score
                                        }, (vpResult?: IVotingPlaceResult) => {
                                            if (vpResult) {
                                                vpResult.votingPlaces.forEach(r => {
                                                    r.distance = turf.distance(turf.point([r.latLng.lng, r.latLng.lat]), turf.point([selectedPlace?.geometry?.location?.lng() || 0, selectedPlace?.geometry?.location?.lat() || 0]));
                                                    if (r.uploadDateTime) {
                                                        // Assume the last update time is stored in UTC, ensure the Date object is created to reflect this.
                                                        const dbDate = new Date(r.uploadDateTime);
                                                        const uploadDateTime: number = Date.UTC(dbDate.getFullYear(), dbDate.getMonth(), dbDate.getDate(), dbDate.getHours(), dbDate.getMinutes(), dbDate.getSeconds());                         
                                                        r.uploadDateTime = new Date(uploadDateTime);
                                                    } else r.uploadDateTime = new Date(0);
                                                });                 
                                                // setVotingPlaceSearchResults(vpResult.votingPlaces);
                                                setInEdVotingPlaces(vpResult.votingPlaces);
                                            }
                                        }
                                    );
                                    break;
                                }
                                // If there are no SI results, send a request to BCPAG
                                case edInfoStatuses.NO_SI_RESULTS: {
                                    console.log(edInfoStatuses.NO_SI_RESULTS);
                                    if ((!bcpagResult || bcpagResult === null) && selectedPlace.address_components) {
                                        // const addr = ParseFormattedAddress(selectedPlace.address_components);
                                        const addr = searchPlace?.description.split(", BC, Canada")[0].split(", British Columbia, Canada")[0];
                                        fetchBcpagAddress({address: addr || ''}, (bcpagResult: IBcpagResult | null) => {
                                            setBcpagResult(bcpagResult);
                                        });
                                    } else {
                                        console.log('NO_SI_RESULTS and already have BCPAG result');
                                        setSelectedPlace(null);
                                        setInEdVotingPlaces(null);
                                        setBcpagResult(null);
                                    }
                                    break;
                                }
                                case edInfoStatuses.NO_EVENT:
                                    break;
                                case edInfoStatuses.SVA_EDVA_RESULT:
                                    console.log("*** PlaceSearchListener - SVA_EDVA_RESULT");
                                    setUserElectoralDistrict(voterEDResponse.electoralDistrict.edAbbr);
                                    setInEdVotingPlaces([]);
                                    break;
                                default:
                                    console.log("*** PlaceSearchListener - Default");
                                    // setVotingPlaceSearchResults([]);
                                    setInEdVotingPlaces(null);
                                    console.log('**** > setNearestVotingPlaces - D');
                                    setNearestVotingPlaces(null);
                                    // setInEdVotingPlaces([]);
                                    // setNearestVotingPlaces([]);
                                    setBcpagResult(null);
                                    setSelectedPlace(null);
                                    break;
                            }
                        }); 
                    } else {
                        // No street number available in Google Place, so request match from BC Geocoder.
                        console.log('No street number available in Google Place, so request match from BC Geocoder.');
                        // const addr = selectedPlace.address_components ? ParseFormattedAddress(selectedPlace.address_components) : undefined;
                        const addr = searchPlace?.description.split(", BC, Canada")[0].split(", British Columbia, Canada")[0];
                        if (addr) {
                            fetchBcpagAddress({address: addr}, (bcpagResult: IBcpagResult | null) =>  {
                                setBcpagResult(bcpagResult);
                            });
                        }
                    }
                    break;
                case UserStates.NB:
                default:
                    if (selectedPlace && selectedPlace.geometry && selectedPlace.geometry.location && eventInfo) {
                        console.log("*** PlaceSearchListener - Before fetchNearestVotingPlaces");
                        fetchNearestVotingPlaces({lng: selectedPlace.geometry.location.lng(), lat: selectedPlace.geometry.location.lat(), score: eventInfo.score ? eventInfo.score : 1}, (vpResult?: IVotingPlaceResult) => {
                            if (vpResult) {
                                vpResult.votingPlaces.forEach(r => {
                                    r.distance = turf.distance(turf.point([r.latLng.lng, r.latLng.lat]), turf.point([selectedPlace?.geometry?.location?.lng() || 0, selectedPlace?.geometry?.location?.lat() || 0]));
                                    if (r.uploadDateTime) {
                                        // Assume the last update time is stored in UTC, ensure the Date object is created to reflect this.
                                        const dbDate = new Date(r.uploadDateTime);
                                        const uploadDateTime: number = Date.UTC(dbDate.getFullYear(), dbDate.getMonth(), dbDate.getDate(), dbDate.getHours(), dbDate.getMinutes(), dbDate.getSeconds());                         
                                        r.uploadDateTime = new Date(uploadDateTime);
                                    } else r.uploadDateTime = new Date(0);
                                });
                                // setVotingPlaceSearchResults(vpResult.votingPlaces);
                                console.log('**** > setNearestVotingPlaces - E',selectedPlace,bcpagResult);
                                setNearestVotingPlaces(vpResult.votingPlaces);
                            }
                        })
                    }
                    break;
            }
            console.log('< PlaceSearchListener.useEffect()');
        }
    }, [selectedPlace, bcpagResult]);

    return null;
}

export const getVotingPlaces_ = (place: google.maps.places.PlaceResult | null, userState: string, eventInfo: IEventInfo) => {
    switch (userState) {
        case UserStates.ED:
            fetchVoterEdInfo(place, null, eventInfo?.score,() => {
                // fetchInEDVotingPlaces(
                //     {
                //         ed: voterEDResponse?.electoralDistrict.edAbbr, 
                //         lat: place?.geometry?.location?.lat(), 
                //         lng: place?.geometry?.location?.lng(), 
                //         score: appState ? appState : 1
                //     }, (vpResult?: IVotingPlaceResult) => {
                //         console.log('> IN ED VP RESULTS',vpResult);
                //         if (vpResult) {
                //             vpResult.votingPlaces.forEach(r => {
                //                 r.distance = turf.distance(turf.point([r.latLng.lng, r.latLng.lat]), turf.point([place?.geometry?.location?.lng() || 0, place?.geometry?.location?.lat() || 0]));
                //                 if (r.uploadDateTime) {
                //                     // Assume the last update time is stored in UTC, ensure the Date object is created to reflect this.
                //                     const dbDate = new Date(r.uploadDateTime);
                //                     const uploadDateTime: number = Date.UTC(dbDate.getFullYear(), dbDate.getMonth(), dbDate.getDate(), dbDate.getHours(), dbDate.getMinutes(), dbDate.getSeconds());                         
                //                     r.uploadDateTime = new Date(uploadDateTime);
                //                 } else r.uploadDateTime = new Date(0);
                //             });                 
                //             return vpResult.votingPlaces;
                //         }
                //     }
                // )                
            });
            break;
        case UserStates.NB:
        default:
            if (place && place.geometry && place.geometry.location && eventInfo) {
                fetchNearestVotingPlaces({lng: place.geometry.location.lng(), lat: place.geometry.location.lat(), score: eventInfo.score ? eventInfo.score : 1}, (vpResult?: IVotingPlaceResult) => {
                    if (vpResult) {
                        vpResult.votingPlaces.forEach(r => {
                            r.distance = turf.distance(turf.point([r.latLng.lng, r.latLng.lat]), turf.point([place?.geometry?.location?.lng() || 0, place?.geometry?.location?.lat() || 0]));
                            if (r.uploadDateTime) {
                                // Assume the last update time is stored in UTC, ensure the Date object is created to reflect this.
                                const dbDate = new Date(r.uploadDateTime);
                                const uploadDateTime: number = Date.UTC(dbDate.getFullYear(), dbDate.getMonth(), dbDate.getDate(), dbDate.getHours(), dbDate.getMinutes(), dbDate.getSeconds());                         
                                r.uploadDateTime = new Date(uploadDateTime);
                            } else r.uploadDateTime = new Date(0);
                        });
                        return vpResult.votingPlaces;
                        // setVotingPlaceSearchResults(vpResult.votingPlaces);
                    }
                })
            }
            break;
    }    
}

export const fetchNearestVotingPlaces = 
    async (
        request: { lng: number, lat: number, score: number },
        callback: (results?: IVotingPlaceResult) => void,
    ) => {
        const requestUrl = `${NEAREST_VP_URL}?x=${request.lng}&y=${request.lat}&score=${request.score}&nocache=${Date.now()}`;
        const response = await axios.get(requestUrl,{}).catch((err:any) => {
            console.log("Error: ", err);
        });
        if (response) {
            callback({
                votingPlaces: response.data.votingPlaces,
                kind: response.data.kind,
                columns: response.data.columns,
                rows: response.data.rows,
            });
        }
    }

export const fetchInEDVotingPlaces = 
    async (
        request: { lng: number | undefined, lat: number | undefined, score: any, ed: string | undefined },
        callback: (results?: IVotingPlaceResult) => void,
    ) => {
        const requestUrl = `${ED_VP_URL}?ed=${request.ed}&x=${request.lng}&y=${request.lat}&score=${request.score}&nocache=${Date.now()}`;
        const response = await axios.get(requestUrl,{}).catch((err:any) => {
            console.log("Error: ", err);
        });
        if (response) {
            callback({
                votingPlaces: response.data.votingPlaces,
                kind: response.data.kind,
                columns: response.data.columns,
                rows: response.data.rows,
            });
        }
    }

export const fetchVoterEdInfo = 
    async (
        place: google.maps.places.PlaceResult | null,
        bcpag: IBcpagResult | null,
        appState: any,
        callback: (results?: IVoterElectoralDistrictInfo) => void,
    ) => {
        console.log('*** fetchVoterEdInfo - Before axios.post',place, bcpag);
        const requestUrl = `${VOTER_ED_URL}`; //?utcOffset=${new Date().getTimezoneOffset()}&nocache=${Date.now()}`;
        const response = await axios.post(requestUrl, {
            GooglePlaceContent: place, //bcpag === null ? place : null,
            BcpagContent: bcpag,
        }).catch((err:any) => {
            console.log("Error: ", err);
        });

        if (response) {
            console.log('*** fetchVoterEdInfo - Response',response);
            callback(response.data);
            // fetchInEDVotingPlaces(
            //     {
            //         ed: response.data.electoralDistrict.edAbbr, 
            //         lat: place?.geometry?.location?.lat(), 
            //         lng: place?.geometry?.location?.lng(), 
            //         score: appState ? appState : 1
            //     }, (vpResult?: IVotingPlaceResult) => {
            //         console.log('> IN ED VP RESULTS',vpResult);
            //         if (vpResult) {
            //             vpResult.votingPlaces.forEach(r => {
            //                 r.distance = turf.distance(turf.point([r.latLng.lng, r.latLng.lat]), turf.point([place?.geometry?.location?.lng() || 0, place?.geometry?.location?.lat() || 0]));
            //                 if (r.uploadDateTime) {
            //                     // Assume the last update time is stored in UTC, ensure the Date object is created to reflect this.
            //                     const dbDate = new Date(r.uploadDateTime);
            //                     const uploadDateTime: number = Date.UTC(dbDate.getFullYear(), dbDate.getMonth(), dbDate.getDate(), dbDate.getHours(), dbDate.getMinutes(), dbDate.getSeconds());                         
            //                     r.uploadDateTime = new Date(uploadDateTime);
            //                 } else r.uploadDateTime = new Date(0);
            //             });                 
            //             return vpResult.votingPlaces;
            //         }
            //     }
            // )
        }
    }

export const fetchBcpagAddress = 
    async (
        request: { address: string },
        callback: (results: IBcpagResult | null) => void,
    ) => {
        const requestUrl = BCPAG_ADDRESS_URL(request.address); //`${NEAREST_VP_URL}?x=${request.lng}&y=${request.lat}&score=${request.score}&nocache=${Date.now()}`;
        const response = await axios.get(requestUrl,{timeout: 15000}).catch((err:any) => {
            console.log("Error: ", err);
        });
        if (response) {
            callback(response.data);
        }
    }