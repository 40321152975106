import axios from "axios";
import { IEventInfo } from "../model/Event";

// export const API_HOST: string = "./wtv-api ";

export const API_HOST: string = process.env.NODE_ENV === 'production' ? '.' : 'http://localhost:49740';

const EVENT_SCOPE_URL: string = `${API_HOST}/api/EventInfo`;

// export const fetchInEDVotingPlaces_nu_ = 
//     async (
//         request: { input: string },
//         callback: (results?: IEventInfo) => void,
//     ) => {
//         const timeZoneOffset: number = (new Date().getTimezoneOffset());
//         // const requestUrl = `${VOTER_ED_URL}?utcOffset=${new Date().getTimezoneOffset()}&nocache=${Date.now()}`;
//         const requestUrl = `${VOTER_ED_URL}?utcOffset=${timeZoneOffset}&nocache=${Date.now()}`;
//         const response = await axios.get(requestUrl,{}).catch((err:any) => {
//             console.log("Error: ", err);
//         });
//         if (response) {
//             callback({
//                 advanceVotingDates: response.data.advanceVotingDates,
//                 configId: response.data.configId,
//                 deoOpenDate: response.data.deoOpenDate,
//                 electoralDistrictText: response.data.electoralDistrictText,
//                 electoralDistricts: response.data.electoralDistricts,
//                 finalVotingDate: response.data.finalVotingDate,
//                 finalVotingDateLocal: response.data.finalVotingDateLocal,
//                 holidays: response.data.holidays,
//                 mapBounds: response.data.mapBounds,
//                 menuOptions: response.data.menuOptions.map((m:any) => ({
//                     key: m.key,
//                     label: m.label,
//                     url: m.url,
//                 })),
//                 scope: response.data.scope,
//                 state: response.data.state,
//                 year: response.data.year,
//                 historicAdvanceVotingWaitTimes: response.data.historicAdvanceVotingWaitTimes,
//                 score: 0,
//             });
//             // callback(response.data.value.map((f:any) =>  ({
//             //     description: f.street_addr,
//             //     result: f, 
//             //     structured_formatting: {
//             //         main_text: f.street_addr,
//             //         secondary_text: `${f.ed_name} (${f.ed_abbr})`,
//             //     }
//             // }) ));
//         }
//     }
// export const fetchEventInfoPromise = 
//     async (
//         request: { input: string },
//         // callback: (results?: IEventInfo) => void,
//     ) => {
//         const timeZoneOffset: number = (new Date().getTimezoneOffset());
//         const requestUrl = `${EVENT_SCOPE_URL}?utcOffset=${timeZoneOffset}&nocache=${Date.now()}`;
//         const response = await axios.get(requestUrl,{}).catch((err:any) => {
//             console.log("Error: ", err);
//         });
//         if (response) {
//             // callback({
//             //     advanceVotingDates: response.data.advanceVotingDates,
//             //     configId: response.data.configId,
//             //     deoOpenDate: response.data.deoOpenDate,
//             //     electoralDistrictText: response.data.electoralDistrictText,
//             //     electoralDistricts: response.data.electoralDistricts,
//             //     finalVotingDate: response.data.finalVotingDate,
//             //     finalVotingDateLocal: response.data.finalVotingDateLocal,
//             //     holidays: response.data.holidays,
//             //     mapBounds: response.data.mapBounds,
//             //     menuOptions: response.data.menuOptions.map((m:any) => ({
//             //         key: m.key,
//             //         label: m.label,
//             //         url: m.url,
//             //     })),
//             //     scope: response.data.scope,
//             //     state: response.data.state,
//             //     year: response.data.year,
//             //     historicAdvanceVotingWaitTimes: response.data.historicAdvanceVotingWaitTimes,
//             //     score: 0,
//             // });
//         }
// }


export const fetchEventInfo = 
    async (
        request: { input: string },
        callback: (results?: IEventInfo) => void,
    ) => {
        const timeZoneOffset: number = (new Date().getTimezoneOffset());
        // const requestUrl = `${EVENT_SCOPE_URL}?utcOffset=${new Date().getTimezoneOffset()}&nocache=${Date.now()}`;
        const requestUrl = `${EVENT_SCOPE_URL}?utcOffset=${timeZoneOffset}&nocache=${Date.now()}`;
        const response = await axios.get(requestUrl,{}).catch((err:any) => {
            console.log("Error: ", err);
        });
        if (response) {
            callback({
                advanceVotingDates: response.data.advanceVotingDates,
                configId: response.data.configId,
                deoOpenDate: response.data.deoOpenDate,
                electoralDistrictText: response.data.electoralDistrictText,
                electoralDistricts: response.data.electoralDistricts,
                finalVotingDate: response.data.finalVotingDate,
                finalVotingDateLocal: response.data.finalVotingDateLocal,
                holidays: response.data.holidays,
                holidayInfos: response.data.holidayInfos,
                // holidayInfos: response.data.holidayInfos.map((h:any) => ({
                //     name: h.Name,
                //     date: h.Date
                // })),
                mapBounds: response.data.mapBounds,
                menuOptions: response.data.menuOptions.map((m:any) => ({
                    key: m.key,
                    label: m.label,
                    url: m.url,
                })),
                scope: response.data.scope,
                state: response.data.state,
                year: response.data.year,
                historicAdvanceVotingWaitTimes: response.data.historicAdvanceVotingWaitTimes,
                score: 0,
                votingPlaceClosures: response.data.votingPlaceClosures,
            });
            // callback(response.data.value.map((f:any) =>  ({
            //     description: f.street_addr,
            //     result: f, 
            //     structured_formatting: {
            //         main_text: f.street_addr,
            //         secondary_text: `${f.ed_name} (${f.ed_abbr})`,
            //     }
            // }) ));
        }
    }