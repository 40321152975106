
import edBs11Info from '../../../data/bs11-info.json';

enum BoundarySet {
    Bs10 = 10,
    Bs11 = 11,
}


export const bs11EdBounds: any = (abbr: string) => {
    return edBounds(abbr,BoundarySet.Bs11);
}

export const bs11EdInfo = (abbr: string) => {
    return edInfo(abbr, BoundarySet.Bs11);
}


const edBounds = (abbr: string, bs: BoundarySet) => {
  const info = (bs === BoundarySet.Bs11 ? edBs11Info : edBs11Info).filter(o => o.edAbbr === abbr)[0];
  const bnd = new google.maps.LatLngBounds({lat: info.minLat, lng: info.minLng}, {lat: info.maxLat, lng: info.maxLng});
  return bnd;
}

const edInfo = (abbr: string, bs: BoundarySet) => {
  let info = (bs === BoundarySet.Bs11 ? edBs11Info : edBs11Info).filter(o => o.edAbbr.toLowerCase() === abbr.toLowerCase()).map(o => ({
    edName: o.edName,
    edAbbr: o.edAbbr,
    area: o.area,
    groupName: o.groupName,
    minLng: o.minLng,
    minLat: o.minLat,
    maxLng: o.maxLng,
    maxLat: o.maxLat,
    // bounds_: new google.maps.LatLngBounds({lat: o.minLat, lng: o.minLng}, {lat: o.maxLat, lng: o.maxLng}),
    bounds: {west: o.minLng, east: o.maxLng, south: o.minLat, north: o.maxLat},
    centroidLng: o.centroidLng,
    centroidLat: o.centroidLat,
  }));
  return info[0];
}

/**
 * Convert latLng coords to xy DOM coords
 * @param mapInstance
 * @param latLng
 */
const fromLatLngToPoint = (
  latLng: google.maps.LatLng | google.maps.LatLngLiteral,
  map: google.maps.Map
): google.maps.Point => {
  const projection: google.maps.Projection = map.getProjection()!;
  const bounds = map.getBounds()!;

  const topRight = projection.fromLatLngToPoint(bounds.getNorthEast())!;
  const bottomLeft = projection.fromLatLngToPoint(bounds.getSouthWest())!;

  const scale = Math.pow(2, map.getZoom()!);
  const worldPoint = projection.fromLatLngToPoint(latLng)!;

  return new google.maps.Point(
    (worldPoint.x - bottomLeft.x) * scale,
    (worldPoint.y - topRight.y) * scale
  );
};

export const showPolygonTooltip = ({
  map,
  latLng,
  lineName,
}: {
  map: google.maps.Map;
  latLng: google.maps.LatLng;
  lineName: string;
}) => {
  const { x, y } = fromLatLngToPoint(latLng, map);
  const tooltip = document.getElementById('lineTooltip');
  if (tooltip) {
  tooltip.innerHTML = `
  Line ${lineName}
  `;
  tooltip.setAttribute(
    'style',
    `position: fixed;
     display: flex;
     font-family: system-ui;
     padding: 8px;
     border-radius: 8px;
     background: #EE352E;
     color: white;
     top: ${y}px;
     left: ${x}px;`
  );
  //background: ${getLineColor(lineName)};
  }
};

export const hidePolygonTooltip = () => {
  //document?.getElementById('lineTooltip')?.setAttribute('style', 'display: none;');
};