import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter } from "react-router-dom";
import './index.css';
// import App from './App_original';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ErrorPage from './components/routes/ErrorPage';
import Root from './components/routes/Layout';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// function getRouterChildren(): RouteObject[] {
//     const folders:IFileItem[] = window.FileConfig.filter(f => f.type === "folder");
//     const files:IFileItem[] = window.FileConfig.filter(f => f.type !== "folder");
//     files.filter(f => f.type.toLowerCase() === '').forEach(f => {f.type = 'color'});
//     folders.forEach(f => {f.route = encodeURI(`/${getRouterPath(f)}`); f.color = getColor(f.id)});
//     files.forEach(f => {f.route = encodeURI(`/${getRouterPath(f)}`); f.color = getColor(f.parentId); f.name = f.name.replace(/\.[^/.]+$/, "")});
  
//     console.log("getRouterChilder",folders, files);
    
//     const c = [
//       ...folders.map(f => { 
//         return {
//           path: `${getRouterPath(f)}`,
//           element: <App />,
//           id: f.id,
//         } 
//       }), 
//       { path:"Search", 
//         element:<App />, 
//         id:"search"
//       }
//     ];
//     console.log(c);
//     return c;
//   }

const router = createBrowserRouter([
    {
        path: '/',
        element: <Root />, //<App />,
        errorElement: <ErrorPage key='' />,
        // children: [{
        //     path: "/By-election",
        //     element: <MultiByelection key=''/>,
        //     id:'byelection',
        // },{
        //     path: "/VMP", 
        //     element: <ErrorPage key='' />,
        //     id: 'vmp'
        // }],
    }
])

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
