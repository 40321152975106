import { Box, Grid, Paper, Typography } from "@mui/material";
import { useRouteError } from "react-router-dom";

export default function ErrorPage(props: {key: string}) { 
    const error:any = useRouteError();
    console.error(error);
    return (
        <Grid container spacing={2}>
            <Box
              sx={{
                p: 2,
                borderRadius: 2,
                bgcolor: 'background.default',
                display: 'grid',
                gridTemplateColumns: { md: '1fr 1fr' },
                gap: 2,
              }}
            >
                <Paper>
                    <Typography variant="h4">An error occured with Where To Vote.</Typography>
                    <Typography paragraph variant="h5">
                        Go to <a href="http://elections.bc.ca/" target="_blank" rel="noreferrer">elections.bc.ca</a> or call Elections BC at 1-800-8683 for more information.
                        {error.statusText || error.message}
                    </Typography>
                </Paper>
            </Box>
        </Grid>
    );
}