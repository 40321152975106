import React, {useEffect, useState} from 'react';
import {createRoot} from 'react-dom/client';

import {
  APIProvider,
  Map,
  useMapsLibrary,
  useMap
} from '@vis.gl/react-google-maps';
import { useDirectionsStore, useUIStateStore, useVotingPlaceStore } from '../../store/layerStore';
import { Box, CardHeader, IconButton, Link, Tab, Tabs, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';

import { DirectionsRenderer } from '@react-google-maps/api';
import { IVotingPlace } from '../../model/VotingPlace';
import { DirectionsTransit } from '@mui/icons-material';
// const API_KEY =
//   globalThis.GOOGLE_MAPS_API_KEY ?? (process.env.GOOGLE_MAPS_API_KEY as string);

export default function VotingPlaceDirections({vp}: {vp: IVotingPlace}) {
    const [directionsService, setDirectionsService] = useState<google.maps.DirectionsService>();
    const [directionsRenderer, setDirectionsRenderer] = useState<google.maps.DirectionsRenderer>();
    const [travelMode, setTravelMode] = useState<google.maps.TravelMode>(google.maps.TravelMode.DRIVING);
    const {setOrigin, setDestination,} = useDirectionsStore(); 
    const [routes, setRoutes] = useState<google.maps.DirectionsRoute[]>([]);
    const [routeIndex, setRouteIndex] = useState(0);
    const selected = routes[routeIndex];
    const leg = selected?.legs[0];
    const { setShowDirections } = useUIStateStore();
    const {origin, destination, mapRef, routesLibraryRef} = useDirectionsStore();
    const panelRef:any = React.useRef();

    // Initialize directions service and renderer
    useEffect(() => {
        if (!routesLibraryRef || !mapRef) return;
        setDirectionsService(new routesLibraryRef.DirectionsService());
        setDirectionsRenderer(new routesLibraryRef.DirectionsRenderer({map:mapRef}));
    }, [routesLibraryRef, mapRef]);

    // Use directions service
    useEffect(() => {
        if (!directionsService || !directionsRenderer || (!origin && !destination)) return;

        if (origin && destination) {
            directionsService
                .route({
                    origin: origin, 
                    destination: destination,
                    // travelMode: google.maps.TravelMode.DRIVING,
                    travelMode: travelMode,
                    provideRouteAlternatives: false,
                })
                .then(response => {
                    directionsRenderer.setPanel(panelRef.current);
                    directionsRenderer.setDirections(response);
                    directionsRenderer.setMap(mapRef);
                    // directionsRenderer.setPanel(window.document.getElementById('directions') as HTMLElement);
                    setRoutes(response.routes);
                });
        }

        return () => directionsRenderer.setMap(null);
    }, [directionsService, directionsRenderer, origin, destination, travelMode]); 

    // Update direction route
    useEffect(() => {
        if (!directionsRenderer) return;
        directionsRenderer.setRouteIndex(routeIndex);
        if (panelRef.current && !directionsRenderer.getPanel()) {
            console.log("set the panel ref", panelRef.current);
        }
    }, [routeIndex, directionsRenderer, panelRef]);

    // useEffect(() => {
    //     console.log("panelRef",panelRef.current)
    // }, [panelRef])

    const handleCloseClick = () => {
        setShowDirections(false);
        setDestination(null);
        setOrigin(null);
    }

    return (
            <Box sx={{ overflowY: 'auto', flexGrow: 1, p: 0, fontFamily:'BC Sans' }}>
                <CardHeader
                    sx={{backgroundColor:'#db282e', paddingBottom:'0px', marginBottom:'-10px'}}
                    action={
                        <IconButton onClick={handleCloseClick}>
                            <CloseIcon fontSize='medium' sx={{color:'white'}} />
                        </IconButton>
                    }            
                /> 
                <Box sx={{backgroundColor:'#db282e'}}>
                    <Typography paragraph fontFamily='BC Sans' variant='body1' marginLeft='10px' marginBottom='0px' sx={{color:'white',fontSize:'1.5em', fontWeight:'bold'}}>{vp.buildingName}</Typography>
                    <Typography fontFamily='BC Sans' variant='h6' marginLeft='10px' paddingBottom='16px' sx={{color:'white'}}>{vp.edName}</Typography>
                </Box>             
                <Tabs
                    value={travelMode}
                    onChange={(e,v) => {setTravelMode(v)}}
                    // indicatorColor=
                    TabIndicatorProps={{style: {background: '#db282e'}}}
                    textColor="inherit"
                    variant="fullWidth"
                    // aria-label="full width tabs example"
                    sx={{ color: '#db282e', backgroundColor: '#e9e8dc' }}
                >
                    <Tab icon={<DirectionsCarIcon sx={{color: '#db282e'}} />} value={google.maps.TravelMode.DRIVING} aria-label='Driving' />
                    <Tab icon={<DirectionsTransit sx={{color: '#db282e'}}/>} value={google.maps.TravelMode.TRANSIT} aria-label='Transit' />
                    <Tab icon={<DirectionsWalkIcon sx={{color: '#db282e'}}/>} value={google.maps.TravelMode.WALKING} aria-label='Walking' />
                    <Tab icon={<DirectionsBikeIcon sx={{color: '#db282e'}}/>} value={google.maps.TravelMode.BICYCLING} aria-label='Bicycling' />
                </Tabs>
                {/* <div id='directions' /> */}
                
        
            { leg && false &&
                <>
                    <h2>{selected?.summary}</h2>
                    <p>{leg.start_address.split(',')[0]} to {leg.end_address.split(',')[0]}</p>
                    <p>{directionsRenderer?.getDirections()?.available_travel_modes}</p>
                    <p>Distance: {leg.distance?.text}</p>
                    <p>Duration: {leg.duration?.text}</p>

                    <h2>Other Routes</h2>
                    <ul>
                        {routes.map((route, index) => (
                        <li key={route.summary}>
                            <button onClick={() => {setRouteIndex(index); console.log(panelRef.current)}}>
                                {route.summary}
                                {selected.waypoint_order}
                            </button>
                        </li>
                        ))}
                    </ul>
                </>
            }
            {/* <Typography><p><a href={`https://maps.google.com?saddr=${origin?.lat},${origin?.lng}&daddr=${destination?.lat},${destination?.lng}`} target="_blank">Open in Google Maps</a></p></Typography> */}
            <Box sx={{padding: '10px', paddingBottom:'0px'}}>
                <Link variant='body1' fontFamily='BC Sans' target="_blank" href={`https://maps.google.com?saddr=${origin?.lat},${origin?.lng}&daddr=${destination?.lat},${destination?.lng}`}>Open in Google Maps</Link>
            </Box>
            <Box sx={{padding: '10px'}} ref={panelRef}></Box> 
        </Box> 
    );
}