import { Box, Stack, Typography } from "@mui/material";
import { useEventInfoStore } from "../../store/layerStore";
import { AppScopes } from "../../util";

export default function Closed(props: {minHeight?:string}) {
    const {eventInfo} = useEventInfoStore();
    const dvh = window.CSS.supports('height', '100vh');

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight={`calc(100${dvh ? 'dvh' : 'vh'} - 128px)`}
        >
            <Stack direction='column' alignItems='center' margin='10%'>
                {(eventInfo && eventInfo.scope === AppScopes.BYELECTION) && <Typography paragraph align="center" variant="h4">{`Voting for the ${eventInfo.year} ${eventInfo.electoralDistrictText} By-election is now closed.`}</Typography>}
                {(eventInfo && eventInfo.scope === AppScopes.GENERAL) && <Typography paragraph align="center" variant="h4">{`Voting for the ${eventInfo.year} Provincial General Election is now closed.`}</Typography>}
                {(eventInfo && eventInfo.scope === AppScopes.MULTI) && <Typography paragraph align="center" variant="h4">{`Voting for the Langford-Juan de Fuca and Vancouver-Mount Pleasant By-elections is now closed.`}</Typography>}
                <Typography paragraph align="center" variant="h5">For election results, visit <a href='http://elections.bc.ca/' target="_blank" rel="noreferrer">elections.bc.ca</a></Typography>
            </Stack>

        </Box>
    );
}