import * as React from 'react';
import { ThemeProvider } from 'styled-components';
import { Snackbar, createTheme, AppBar, Toolbar, Theme, Typography, IconButton } from '@mui/material';
import { responsiveFontSizes } from '@mui/material/styles';

import MenuIcon from '@mui/icons-material/Menu';
import MyLocationIcon from '@mui/icons-material/MyLocation';

import { APIProvider } from '@vis.gl/react-google-maps';

import { useAppStateStore, useEventInfoStore, useUIStateStore, useVotingPlaceStore, SearchType, useDirectionsStore } from '../../store/layerStore';
import { fetchEventInfo } from "../../api";
import LogoImage from '../LogoImage';
import MenuDrawer from '../MenuDrawer';
import { IEventInfo } from '../../model/Event';
import { AppStates, UserStates } from '../../util';
import Content from './Content';
import AzureAutocomplete from '../Search/AzureAutocomplete';
import '@bcgov/bc-sans/css/BC_Sans.css';
// import { fetchEventInfoPromise } from '../../api/startup';

const API_KEY = 'AIzaSyAjxt6nhZhlXOZvKqoFRVDbVzxa_GL_Jg4';

const drawerWidth = '485px'; //'485px';
const baseHeaderHeight = 64; // <Toolbar /> default height
const baseSearchHeight = 64; // <Toolbar /> default height
const baseFooterHeight = 0;//56;

enum DrawerContentType {
    Menu = 1,
    Help,
}

const themedStyles = (theme: Theme) => {
    return {
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            backgroundColor: "#db282e",
        },
        infoArea: {
            backgroundColor: "#e9e8dc",
            fontFamily: 'Bebas Neue',
        },    
    }
}

export default function Layout() {
    const isInitialMount = React.useRef(true);
    const [placesService] = React.useState<google.maps.places.PlacesService>();
    const { setUserLocation, setSearchLocation, message, setMessage, setLoadingPlaceId, setClearSearch } = useAppStateStore();
    const { searchPlace, setSelectedPlace, setDefaultSearchValue, userState, lastNearestSearch, setLastNearestSearch  } = useAppStateStore();
    const { setVotingPlaceSearchResults, setNearestVotingPlaces } = useVotingPlaceStore();
    const {eventInfo, setEventInfo} = useEventInfoStore();
    const {mapRef} = useDirectionsStore();
    const { menuDrawerOpen, setMenuDrawerOpen, setMenuDrawerType } = useUIStateStore();
    const [largeScreen, setLargeScreen] = React.useState(false);

    const [drawerContentType, setDrawerContentType] = React.useState(DrawerContentType.Menu);
    const theme = responsiveFontSizes(createTheme());

    const fetchGooglePlace = React.useMemo(
        () => async (
            request: google.maps.places.PlaceDetailsRequest,
            callback: (results: google.maps.places.PlaceResult | null, status: google.maps.places.PlacesServiceStatus) => void,
        ) => {
            placesService?.getDetails(request, callback);
        },[placesService]
    );

    React.useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
          setLargeScreen(window.innerWidth > theme.breakpoints.values.sm);
        }    
        // Add event listener
        window.addEventListener("resize", handleResize);
        handleResize();
    
        if (isInitialMount.current) {
            // fetchEventInfoPromise({ input: '' }).then(



            // );

            fetchEventInfo({ input: '' }, (info?: IEventInfo) => {
                if (info) { 
                    info.advanceVotingDates = info.advanceVotingDates.map(d => new Date(d));
                    info.deoOpenDate = new Date(info.deoOpenDate);
                    info.holidays = info.holidays.map(d => new Date(d));
                    info.holidayInfos = info.holidayInfos.map(h => ({date: new Date(h.date), name: h.name}))
                    info.finalVotingDate = new Date(info.finalVotingDate);
                    info.finalVotingDateLocal = new Date(info.finalVotingDateLocal);
                    switch (info.state) {
                        case AppStates.PRE_ADVANCE:
                        case AppStates.ADVANCE_1: info.score = 1; break;
                        case AppStates.ADVANCE_2: info.score = 2; break;
                        case AppStates.ADVANCE_3: info.score = 4; break;
                        case AppStates.ADVANCE_4: info.score = 8; break;
                        case AppStates.ADVANCE_5: info.score = 16; break;
                        case AppStates.ADVANCE_6: info.score = 32; break;
                        case AppStates.ADVANCE_7: info.score = 64; break;
                        case AppStates.ADVANCE_8: info.score = 128; break;
                        case AppStates.ADVANCE_9: info.score = 256; break;
                        default: info.score = 512; break;
                    }
                    console.log(info);
                    setEventInfo(info);
                };
            });
    
            // Initialize directions service and renderer
            const queryParameters = new URLSearchParams(window.location.search);
    
            if (queryParameters && queryParameters.has("placeid") ) {
                const pid: string | null = queryParameters.get("placeid");
                if (pid) {
                    setLoadingPlaceId(pid);
                    fetchGooglePlace(
                        {placeId: pid},(placeResult, status) => {
                            // The result is a google place object
                            setSelectedPlace(placeResult);
                            setDefaultSearchValue(
                                { 
                                    description: placeResult?.formatted_address || '', 
                                    place_id: placeResult?.place_id || '',
                                    structured_formatting: {
                                        main_text: placeResult?.formatted_address || '',
                                        secondary_text: placeResult?.vicinity || '',
                                    },
                                    result: placeResult
                                });
                        }
                    );
                }
            } else {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(function(position) {
                        // Create a position object
                        const pos: google.maps.LatLngLiteral = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        };
              
                        //panToPoint(pos.lat, pos.lng);
                        //getVPsNearUserGeolocation(pos, appState);
                        setUserLocation(pos);
                        setSearchLocation(pos);
                        setLastNearestSearch({
                            type: SearchType.LOCATION, location: pos,
                            parameter: null,
                            placeId: null
                        });
                        // return(pos);
                    }, () => { setMessage('Geolocation is not supported by this browser. Please enter your location in the search bar, or upgrade your browser'); });
                } else {
                    // Browser doesn't support Geolocation
                    //window.Materialize.toast('Geolocation is not supported by this browser. Please enter your location in the search bar, or upgrade your browser', 4000);
                    setMessage('Geolocation is not supported by this browser. Please enter your location in the search bar, or upgrade your browser');
                    console.log('Browser does not support geolocation');
                } 
            }
    
            // fetchEventInfo({input:'', callback: });
            // fetchEventInfo({ input: '' }, (info?: IEventInfo) => {
            //     if (info) { 
            //         info.advanceVotingDates = info.advanceVotingDates.map(d => new Date(d));
            //         info.deoOpenDate = new Date(info.deoOpenDate);
            //         info.holidays = info.holidays.map(d => new Date(d));
            //         info.finalVotingDate = new Date(info.finalVotingDate);
            //         info.finalVotingDateLocal = new Date(info.finalVotingDateLocal);
            //         switch (info.state) {
            //             case AppStates.PRE_ADVANCE:
            //             case AppStates.ADVANCE_1: info.score = 1; break;
            //             case AppStates.ADVANCE_2: info.score = 2; break;
            //             case AppStates.ADVANCE_3: info.score = 4; break;
            //             case AppStates.ADVANCE_4: info.score = 8; break;
            //             case AppStates.ADVANCE_5: info.score = 16; break;
            //             case AppStates.ADVANCE_6: info.score = 32; break;
            //             case AppStates.ADVANCE_7: info.score = 64; break;
            //             case AppStates.ADVANCE_8: info.score = 128; break;
            //             case AppStates.ADVANCE_9: info.score = 256; break;
            //             default: info.score = 512; break;
            //         }
    
            //         setEventInfo(info);
            //     };
            // });
    
            // @ ts-expect-error: Let's ignore a compile error like this unreachable code 
            //isInitialMount.current.scrollIntoView();
            // window.scrollTo(1,0);
            isInitialMount.current = false;
    
        } else {
    
        }
        // Remove event listener on cleanup
        // return () => window.removeEventListener("resize", handleResize);    
    });

    const handleMenuDrawerOpen = () => {
        setMenuDrawerType(DrawerContentType.Menu);
        setMenuDrawerOpen(!menuDrawerOpen);
    };

    const handleMyLocationClick = () => {
        // (e) => { setUserLocation(getUserLocation()); }    
        if (navigator.geolocation) {
            setClearSearch(true);
            setVotingPlaceSearchResults([]);
            console.log('**** > setNearestVotingPlaces - F');
            setNearestVotingPlaces(null);            
            navigator.geolocation.getCurrentPosition(function(position) {
                // Create a position object
                const pos: google.maps.LatLngLiteral = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };
      
                //panToPoint(pos.lat, pos.lng);
                //getVPsNearUserGeolocation(pos, appState);
                setUserLocation(pos);
                setSearchLocation(pos);
                setLastNearestSearch({
                    type: SearchType.LOCATION, location: pos,
                    parameter: null,
                    placeId: null
                });
                // setMessage(`Location: ${pos.lng}, ${pos.lat}`)
                // return(pos);
            }, () => { setMessage('Geolocation is not supported by this browser. Please enter your location in the search bar, or upgrade your browser'); } );
        } else {
            // Browser doesn't support Geolocation
            //window.Materialize.toast('Geolocation is not supported by this browser. Please enter your location in the search bar, or upgrade your browser', 4000);
            setMessage('Geolocation is not supported by this browser. Please enter your location in the search bar, or upgrade your browser');
            // console.log('Browser does not support geolocation');
        }
        return;
    }

    // return (
    //     <Box sx={{ display: 'flex' }}>
    //         <CircularProgress />
    //     </Box>
    // );
    return (
        // <ThemeProvider theme={ AppBarTheme }>
        <ThemeProvider theme={ theme }>
        <div >
        <Snackbar 
            anchorOrigin={{vertical: 'bottom',horizontal: 'center'}}
            onClose={(event: React.SyntheticEvent | Event, reason?: string) => {
                if (reason === 'clickaway') {
                    return;
                }
                setMessage();
            }}
            message={message}
            open={message !== undefined}
            autoHideDuration={5000}
        />
        <APIProvider apiKey={API_KEY} libraries={['maps','places','routes','marker']} version='3.51' onLoad={() => {console.log('**** LAYOUT',mapRef)}}>
            <AppBar position="fixed" sx={{ ...themedStyles(theme).appBar, zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                {/* <EventInfoListener /> */}
                <Toolbar sx={{ ...themedStyles(theme).infoArea }}>
                    <LogoImage />
                    <Typography variant="h4" style={{ marginLeft: 'auto', fontFamily: 'Bebas Neue', color: 'black' }}>Where to Vote</Typography>
                    {/* <IconButton size="medium" edge="end" aria-label="help" sx={{ color: 'black' }} onClick={() => {}}>
                        <HelpOutline />
                    </IconButton> */}
                </Toolbar>
        
                <Toolbar>
                    { eventInfo && (eventInfo.state !== AppStates.PRE_WRIT && eventInfo.state !== AppStates.CLOSED && eventInfo.state !== AppStates.ERROR) &&
                    <>
                        <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={handleMenuDrawerOpen}>
                            <MenuIcon />
                        </IconButton>
                        <AzureAutocomplete defaultPlace={searchPlace} />
                        { userState === UserStates.NB &&
                        <IconButton size="large" edge="end" color="inherit" aria-label="menu" sx={{ ml: 0 }} onClick={handleMyLocationClick}>
                            <MyLocationIcon />
                        </IconButton>   
                        }
                    </>
                    }
                </Toolbar>
            </AppBar>
            <MenuDrawer 
                width={drawerWidth} 
                anchor={ drawerContentType === DrawerContentType.Menu ? "left" : "right" }
            />    
            <Toolbar id='spacer1' />
            <Toolbar id='spacer2' />    
            <Content drawerWidth={drawerWidth} containerWidth={window.innerWidth} containerHeight={`${baseHeaderHeight + baseSearchHeight + baseFooterHeight - (largeScreen ? 0 : 16)}`} isLargeScreen={largeScreen} />
        </APIProvider>
        </div>
      </ThemeProvider>
      );

}