import * as React from 'react';
import { Box, createTheme, Typography, IconButton, Drawer, Stack, Divider, ListItemButton, ListItemIcon, List, ListItemText, ListItem, Collapse, Link } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PhoneIcon from '@mui/icons-material/Phone';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import LinkIcon from '@mui/icons-material/Link';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import MapIcon from '@mui/icons-material/Map';
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';
import InfoIcon from '@mui/icons-material/Info';
import GroupsIcon from '@mui/icons-material/Groups';
import ApartmentIcon from '@mui/icons-material/Apartment';
import { ExpandLess, ExpandMore } from "@mui/icons-material";

import { responsiveFontSizes } from '@mui/material/styles';

import AdvanceFinalVotingPlaceImage from "../images/AdvanceFinalVotingPlace.png";
import AdvanceVotingPlaceImage from "../images/AdvanceVotingPlace.png";
import AssignedVotingPlaceImage from "../images/AssignedVotingPlace.png";
import DistrictElectoralOfficeImage from "../images/DistrictElectoralOffice.png";
import FinalVotingPlaceImage from "../images/FinalVotingPlace.png";
import ElectoralDistrictBoundaryImage from "../images/ElectoralDistrictBoundary.png";
import ClosureImage from "../images/Closure.png";

import { useEventInfoStore, useUIStateStore } from '../store/layerStore';
import { IEventInfo } from '../model/Event';

enum DrawerContentType {
    Menu = 1,
    Help,
}

const renderMenuListItem = (key: string, info: IEventInfo, icon: any) => {
    if (info && info.menuOptions.filter(m => m.key === key).length === 1) {
        const m = info.menuOptions.filter(m => m.key === key)[0];
        return (
            //<ListItem key={m.key} component="a" href={m.url}>
            <ListItemButton key={m.key} component="a" href={m.url} target="_blank">
                <ListItemIcon>
                    {icon}
                </ListItemIcon>
                <ListItemText primary={m.label} />
            </ListItemButton>                
        //</ListItem>
        )
    } else {
        return <></>
    }
}

function TextMenuItem(props: {key: string, title: string, content: string}) {
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    }

    return  (
        <>
            <ListItemButton onClick={handleClick}>
                <ListItemIcon>
                    <NotListedLocationIcon />
                </ListItemIcon>
                <ListItemText primary={props.title} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse key={props.key} in={open} timeout='auto' unmountOnExit>
                <List component='li' >
                    <ListItem>
                        <ListItemText primary={props.content} />
                    </ListItem>
                </List>  
            </Collapse>
        </>
    )         
}

function LegendMenuItem(props: {key: string}) {
    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen(!open);
    }
    return  (
        <>
            <ListItemButton onClick={handleClick}>
                <ListItemIcon>
                    <MapIcon />
                </ListItemIcon>
                <ListItemText primary='Legend' />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse key={props.key} in={open} timeout='auto' unmountOnExit>
                <List component='li' >
                <ListItem sx={{pt: '0px', pb:'4px'}}>
                        {/* <ListItemButton> */}
                            <Box component="img" src={AssignedVotingPlaceImage} sx={{ pl:'10px', pr: '5px', maxHeight: {xs: '2.5rem', lg:'2.5rem'} }} />
                            <ListItemText primary='Assigned Voting Place' />
                        {/* </ListItemButton> */}
                    </ListItem>
                    <ListItem sx={{pt: '4px', pb:'4px'}}>
                        <Box component="img" src={AdvanceVotingPlaceImage} sx={{ pl:'10px', pr: '5px', pt: '1px' }} />
                        <ListItemText primary='Advance Voting Place' />

                    </ListItem> 
                    <ListItem sx={{pt: '4px', pb:'4px'}}>
                        <Box component="img" src={FinalVotingPlaceImage} sx={{ pl:'10px', pr: '5px' }} />
                        <ListItemText primary='Final Voting Place' />
                    </ListItem>
                    <ListItem sx={{pt: '4px', pb:'4px'}}>
                        <Box component="img" src={AdvanceFinalVotingPlaceImage} sx={{ pl:'10px', pr: '5px' }} />
                        <ListItemText primary='Advance & Final Voting Place' />
                    </ListItem>                    
                    <ListItem sx={{pt: '4px', pb:'4px'}}>
                        <Box component="img" src={DistrictElectoralOfficeImage} sx={{ pl:'10px', pr: '5px' }} />
                        <ListItemText primary='District Electoral Office' />
                    </ListItem>
                    <ListItem sx={{pt: '4px', pb:'4px'}}>
                        <Box component="img" src={ClosureImage} sx={{ pl:'10px', pr: '5px' }} />
                        <ListItemText primary='Closed Due to Emergency' />
                    </ListItem>                    
                    <ListItem sx={{pt: '4px', pb:'4px'}}>
                        <Box component="img" src={ElectoralDistrictBoundaryImage} sx={{ pl:'10px', pr: '5px', height: '28px' }} />
                        <ListItemText primary='Electoral District Boundary' />
                    </ListItem>                                                                                                           
                </List>  
            </Collapse>
        </>
    )      
}

const renderDrawerContent = (contentType: DrawerContentType, info: IEventInfo) => {
    switch (contentType) {
        case DrawerContentType.Menu:
            return (<>
                <Box sx={{ overflow: 'auto', flexGrow: 1, p: 0,  }}>
                    <List dense >
                        {renderMenuListItem('ADVANCE_VOTING_PLACE', info, <LinkIcon />)}
                        {renderMenuListItem('FINAL_VOTING_PLACE', info, <LinkIcon />)}
                        <TextMenuItem key="foo" title="Find voting places nearest to me" content="Find voting places close to an address or your mobile location."  />
                        <TextMenuItem key="foo" title="Find voting places in my electoral district" content="Find your assigned voting place and voting places in your electoral district based on your home address.
                                                       In a provincial election you can vote at any voting place. Find out more at elections.bc.ca" />
                        <LegendMenuItem key='legend'/>
                    </List>
                    <Box >
                        <Typography variant="h4" sx={{fontFamily:'Bebas Neue', pl:'16px'}}>Learn More</Typography>
                        <Divider />
                    </Box>
                    <List dense >
                        {renderMenuListItem('MORE_ABOUT_EVENT', info, <InfoIcon /> )}
                        {renderMenuListItem('CANDIDATES', info, <GroupsIcon /> )}
                        {renderMenuListItem('DISTRICT_ELECTORAL_OFFICE', info, <ApartmentIcon /> )}

                        <ListItemButton key='privacy' component="a" href="https://elections.bc.ca/privacy/" target="_blank">
                            <ListItemIcon>
                                <LockPersonIcon />
                            </ListItemIcon>
                            <ListItemText primary='Privacy Information' />
                        </ListItemButton>                

                        <ListItemButton key='call' component="a" href="tel:+1-800-661-8683">
                            <ListItemIcon>
                                <PhoneIcon />
                            </ListItemIcon>
                            <ListItemText primary='Call Elections BC 1-800-661-8683' />
                        </ListItemButton>                

                        <ListItemButton key='link' component="a" href="https://elections.bc.ca" target="_blank">
                            <ListItemIcon>
                                <LinkIcon />
                            </ListItemIcon>
                            <ListItemText primary='elections.bc.ca' />
                        </ListItemButton>                
               
                    </List>
                    <Stack direction="row" justifyContent='center' spacing={3}>
                        <Link href="https://www.facebook.com/ElectionsBC/" target="_blank" >
                            <FacebookIcon fontSize='medium' sx={{color: '#3b5998',}}/>
                        </Link>
                        <Link href="https://twitter.com/electionsbc/" target="_blank" >
                            <XIcon fontSize='medium' sx={{color: 'black'}}/>
                        </Link>
                        <Link href="https://www.instagram.com/electionsbc/" target="_blank" >
                            <InstagramIcon fontSize='medium' sx={{color: '#125668'}} />
                        </Link>
                        <Link href="https://www.youtube.com/user/ElectionsBConline" target="_blank" >
                            <YouTubeIcon fontSize='medium' sx={{color: '#b00'}}/>
                        </Link>                        
                </Stack>
                </Box>
                </>
        );
        case DrawerContentType.Help:
            return (<></>);
    }
}

export default function MenuDrawer(props: {width: string, anchor: 'left' | 'top' | 'right' | 'bottom'}) {
    const [drawerContentType] = React.useState<DrawerContentType>(DrawerContentType.Menu);
    const { eventInfo } = useEventInfoStore();
    const { menuDrawerOpen, setMenuDrawerOpen, menuDrawerType } = useUIStateStore()
    const theme = responsiveFontSizes(createTheme());
    
    const handleDrawerClose = () => {
        setMenuDrawerOpen(!menuDrawerOpen);
    };

    return(
        <Drawer
            sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
                width: props.width,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: '100%',
                    boxSizing: 'border-box',
                    height: '100%',
                    [theme.breakpoints.up('sm')]: {
                    width: props.width,
                    },
                },
            }}
            variant="temporary"
            anchor={ menuDrawerType === DrawerContentType.Menu ? 'left' : 'right' }
            open={ menuDrawerOpen }
        >
            <Box>
                <Stack direction="row" alignItems="center" justifyContent={'space-between'} sx={{pl: 2}}>
                    <Typography variant="h4" sx={{fontFamily:'Bebas Neue'}}>
                        {menuDrawerType === DrawerContentType.Menu ? 'Voting Places' : 'How to find your electoral district'}
                    </Typography>
                    <IconButton size="large" color="inherit" aria-label="menu" sx={{mr:'2px'}} onClick={handleDrawerClose}>
                        { drawerContentType === DrawerContentType.Menu ? <ChevronLeftIcon /> : <ChevronRightIcon /> }
                    </IconButton>
                </Stack>
                <Divider />
            </Box>
            {eventInfo && renderDrawerContent(drawerContentType, eventInfo)}
        </Drawer>
    )
}