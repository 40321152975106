import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import { VotingPlaceSummary } from './VotingPlaceSummary';
import { Collapse, Divider, IconButton, Stack, Tab, Tabs } from '@mui/material';
import ListIcon from '@mui/icons-material/List';
import { ExpandLess, ExpandMore } from "@mui/icons-material";
// import { EDInfo } from '../../data/ed';
import { OpenVotingPlaces, ClosedVotingPlaces, UserStates, edInfoStatuses, AppScopes, VPTypeCodes } from '../../util'
import { useAppStateStore, useEventInfoStore, useVotingPlaceStore } from '../../store/layerStore';
import React from 'react';
import { UserStateListener } from '../../api/votingPlaces';
import { IEventInfo } from '../../model/Event';
import { IVoterElectoralDistrictInfo, IVotingPlace } from '../../model/VotingPlace';


export const SortedOpenVotingPlaces = (vps: IVotingPlace[] | null, eventInfo: IEventInfo, userState: string, voterElectoralDistrictInfo: IVoterElectoralDistrictInfo | null) => {
    const ovp = OpenVotingPlaces(eventInfo, vps);
    if ( userState === UserStates.ED) {
        const ed = OpenVotingPlaces(eventInfo, vps);
        return ed;
    } else {
        const nb = ovp.filter(v => v.votingPlaceTypeCode !== VPTypeCodes.DEO).sort((a,b) => a.distance - b.distance)
            .concat(ovp.filter(v => v.votingPlaceTypeCode === VPTypeCodes.DEO).sort((a,b) => a.distance - b.distance));
        return nb;
    }
}

export const SortedClosedVotingPlaces = (vps: IVotingPlace[] | null, eventInfo: IEventInfo, userState: string, vedInfo: IVoterElectoralDistrictInfo | null) => {
    const cvp = ClosedVotingPlaces(eventInfo, vps);
    if ( userState === UserStates.ED) {
        const ed = cvp.filter(v => v.isClosure)
            .concat(cvp.filter(v => !v.isClosure && (vedInfo?.assignedVotingPlace !== null && vedInfo?.assignedVotingPlace.id === v.addressStandardId)))
            .concat(cvp.filter(v => !v.isClosure && (vedInfo?.assignedVotingPlace !== null && vedInfo?.assignedVotingPlace.id !== v.addressStandardId) && (v.votingPlaceTypeCode === VPTypeCodes.ADVANCE || v.votingPlaceTypeCode === VPTypeCodes.ADVANCE_FINAL)).sort((a,b) => a.distance - b.distance))
            .concat(cvp.filter(v => !v.isClosure && (vedInfo?.assignedVotingPlace !== null && vedInfo?.assignedVotingPlace.id !== v.addressStandardId) && (v.votingPlaceTypeCode === VPTypeCodes.DEO || v.votingPlaceTypeCode === VPTypeCodes.FINAL)).sort((a,b) => a.distance - b.distance));
        return ed;
    } else {
        const nb = cvp.filter(v => v.isClosure)
            .concat(cvp.filter(v => !v.isClosure && (v.votingPlaceTypeCode === VPTypeCodes.ADVANCE || v.votingPlaceTypeCode === VPTypeCodes.ADVANCE_FINAL)).sort((a,b) => a.distance - b.distance))
            .concat(cvp.filter(v => !v.isClosure && (v.votingPlaceTypeCode === VPTypeCodes.DEO || v.votingPlaceTypeCode === VPTypeCodes.FINAL)).sort((a,b) => a.distance - b.distance));
        return nb;
    }
}

export const VotingPlaceSummaryList = () => {
    const { nearestVotingPlaces, inEdVotingPlaces } = useVotingPlaceStore();
    const { voterElectoralDistrictInfo } = useAppStateStore();
    const { eventInfo } = useEventInfoStore();
    const { userState, setUserState, resultsLoading} = useAppStateStore();
    const [openVotingPlacesExpanded, setOpenVotingPlacesExpanded] = React.useState(true);
    const [closedVotingPlacesExpanded, setClosedVotingPlacesExpanded] = React.useState(true);

    const showClosedMessage = () => {
        if (eventInfo && !resultsLoading && OpenVotingPlaces(eventInfo, userState === UserStates.ED ? inEdVotingPlaces : nearestVotingPlaces).length === 0) {
            const vps = (userState === UserStates.ED ? inEdVotingPlaces : nearestVotingPlaces);

            return (vps && vps.length > 0 && OpenVotingPlaces(eventInfo,vps).length===0);
        } else {
            return false;
        }
    }

    const renderVpMessage = () => {
        let c: JSX.Element = <></>;
        if (userState === UserStates.NB) {
            c = <></>;
        } else {
            if (voterElectoralDistrictInfo && voterElectoralDistrictInfo.assignedVotingPlace) {
                const closure = eventInfo?.votingPlaceClosures.filter(c => c.addressStandardId === voterElectoralDistrictInfo.assignedVotingPlace.id);
                if (closure?.length === 1) {
                    const msg: string = `${closure[0].closureNote.toLocaleLowerCase()}`;
                    console.log('*** renderVpMessage',closure);
                }
            } 

            switch (voterElectoralDistrictInfo?.status) {
                case edInfoStatuses.INVALID_CONTENT:
                case edInfoStatuses.PARSE_CONTENT_FAIL:
                case edInfoStatuses.MULTIPLE_RESULTS:
                case edInfoStatuses.NO_SI_RESULTS:
                case edInfoStatuses.FAILURE:
                case edInfoStatuses.NO_CONTENT:
                    if (eventInfo?.scope === AppScopes.BYELECTION) {
                        c = <Typography>We could not find a voting place assigned to the address you entered. Call Elections BC at 1-800-661-8683 for more information.</Typography>;
                    } else {
                        c = <Typography>We could not find a voting place assigned to the address you entered. Try using the "Nearest To Me" search, or call Elections BC at 1-800-661-8683 for more information.</Typography>;
                    }
                    break;
                case edInfoStatuses.NO_SI_RESULTS_FOR_RANGE:
                case edInfoStatuses.NO_SI_RESULTS_FOR_RANGE_ED_ONLY:
                    c = <Typography>We could not find an exact match for your home address. Your location on the map is approximate.</Typography>;
                    break;
                case edInfoStatuses.SVA_EDVA_RESULT:
                    const m = eventInfo?.menuOptions.filter(m => m.key === 'DISTRICT_ELECTORAL_OFFICE')[0];
                    if (eventInfo?.scope === AppScopes.BYELECTION) {
                        c = <Typography>Special arrangements have been made for residents at this location to vote. Please contact your <a href={m?.url} target="_blank">District Electoral Office</a> for more information.</Typography>;
                    } else {
                        c = <Typography>Special arrangements have been made for residents at this location to vote. Please contact your <a href={m?.url} target="_blank">District Electoral Office</a> for more information.</Typography>;
                    }
                    break;
                case edInfoStatuses.NO_EVENT:
                    c = <Typography>We could not find your address within the ${eventInfo?.electoralDistrictText} electoral district. You must be an eligible voter resident in ${eventInfo?.electoralDistrictText} to vote in the by-election. Please call Elections BC at 1-800-661-8683 for more information.</Typography>;
                    break;
                default: 
                    if (inEdVotingPlaces && inEdVotingPlaces.length > 0) { 
                        c = <></>;
                    } else {
                        if (voterElectoralDistrictInfo?.electoralDistrict.edAbbr) {
                            c = <Typography>Voting places are being confirmed for your electoral district and will be published through this application when available. They will also be published through Elections BC\'s web page, in community newspapers and on Where to Vote cards sent to voters before advance voting starts.</Typography>;
                        } else {
                            c = <Typography>Enter your location in the search bar to see results.</Typography>;
                        }
                    }
                    break;
            }
        }
        return c;   
    } 

    const renderOpenTitleText = () => {
        const tn = 'Open Voting Places';
        const te = `${tn} in My Electoral District`;
    //     <Typography fontFamily='Bebas Neue' fontSize='1.4rem' lineHeight='2.5rem' marginLeft='10px' marginRight='auto' sx={{ color: 'white' }}>
    //     { 'Open Voting Places for your electoral district' }
    // </Typography>                        
    // <Typography fontFamily='Bebas Neue' fontSize='1.4rem' lineHeight='2.5rem' marginLeft='10px' marginRight='auto' sx={{ color: 'white' }}>
    //     { renderOpenTitleText() }
    // </Typography>        
        if (userState === UserStates.NB) {
            return (
                <Typography fontFamily='BC Sans' fontSize='1.0rem' lineHeight='2.5rem' marginLeft='10px' marginRight='auto' sx={{ color: 'white' }}>
                    { tn }
                </Typography>
            );
        } else {
            const vps = (userState === UserStates.ED ? inEdVotingPlaces : nearestVotingPlaces);
            
            // const t1 = eventInfo && vps && vps.length > 0 && vps.length > 0 ? `${vps[0].edName}` : te;
            if (vps && vps.length > 0) {
                return (
                    <Stack direction="column" justifyContent="flex-start" width='100%' marginRight='auto'>
                        <Typography fontFamily='BC Sans' fontSize='1.0rem' lineHeight='2.5rem' marginLeft='10px' marginRight='auto' sx={{ color: 'white' }}>
                            { te }
                        </Typography>
                        <Divider sx={{borderColor: 'white', marginLeft: '10px'}}/>      
                        <Typography fontFamily='BC Sans' fontSize='1.0rem' lineHeight='2.5rem' marginLeft='10px' marginRight='auto' sx={{ color: 'white' }}>
                            { `${vps[0].edName}` }
                        </Typography>          
                    </Stack>
                );
            } else {
                return (
                    <Stack direction="column" justifyContent="flex-start" alignContent="center" width='100%' marginRight='auto'>
                        <Typography fontFamily='BC Sans' fontSize='1.0rem' lineHeight='2.5rem' marginLeft='10px' marginRight='auto' sx={{ color: 'white' }}>
                            { tn }
                        </Typography>
                        {/* <Divider sx={{borderColor: 'white', marginLeft: '10px'}}/>      
                        <Typography fontFamily='Bebas Neue' fontSize='1.4rem' lineHeight='2.5rem' marginLeft='10px' marginRight='auto' sx={{ color: 'white' }}>
                            { t1 }
                        </Typography>           */}
                    </Stack>
                );                
            }

        }
    }

    return (
        <>  
            <Box sx={{ borderBottom: 1, borderColor: 'divider', backgroundColor:'#e9e8dc' }}>
                <Tabs
                    value={userState}
                    onChange={(e,v) => {setUserState(v)}}
                    // indicatorColor=
                    TabIndicatorProps={{style: {background: '#db282e'}}}
                    textColor="inherit"
                    variant="standard"
                    aria-label="full width tabs example"
                    sx={{ color: '#db282e',  }}
                >
                    <Tab label="Nearest to Me" value={UserStates.NB} sx={{fontFamily: 'BC Sans', fontSize: '1.05rem', fontWeight: 'bold', letterSpacing: '0.00em' }} />
                    <Tab label="In My Electoral District" value={UserStates.ED} wrapped sx={{fontFamily: 'BC Sans', fontSize: '1.05rem', fontWeight: 'bold', letterSpacing: '0.00em', padding: '8px'}} />
                    {/* Before changing to BC Sans font */}
                    {/* <Tab label="Nearest to Me" value={UserStates.NB} sx={{fontFamily: 'BC Sans', fontSize: '1.4rem', letterSpacing: '0.00938em' }} />
                    <Tab label="In My Electoral District" value={UserStates.ED} wrapped sx={{fontFamily: 'BC Sans', fontSize: '1.4rem', letterSpacing: '0.00938em', padding: '8px'}} /> */}
                </Tabs>
                {/* @CT HERE */}
                {/* <UserStateListener /> */}
            </Box>
                    
            <Box sx={{ backgroundColor: 'white'}}>
                <Stack direction='row' padding='10px'>
                    <ListIcon />
                    <Typography marginLeft='10px' marginRight='auto' sx={{fontFamily:'BC Sans'}}>Voting Place Results</Typography>
                </Stack>
                <Box sx={{paddingLeft:'10px', paddingRight:'10px'}}>
                    {renderVpMessage()}
                </Box>
            </Box>
            { ((userState === UserStates.ED && inEdVotingPlaces && inEdVotingPlaces.length > 0) || (userState === UserStates.NB && nearestVotingPlaces && nearestVotingPlaces.length > 0)) && 
            <Box sx={{ overflow: 'scroll', flexGrow: 1, p: 0, fontFamily:'BC Sans' }}>
                <Box sx={{ backgroundColor: '#db282e' }}>
                    <Stack direction="row" justifyContent="flex-start">
                        { renderOpenTitleText() }
                        {/* <Typography fontFamily='Bebas Neue' fontSize='1.4rem' lineHeight='2.5rem' marginLeft='10px' marginRight='auto' sx={{ color: 'white' }}>
                            { 'Open Voting Places for your electoral district' }
                        </Typography>                        
                        <Typography fontFamily='Bebas Neue' fontSize='1.4rem' lineHeight='2.5rem' marginLeft='10px' marginRight='auto' sx={{ color: 'white' }}>
                            { renderOpenTitleText() }
                        </Typography> */}
                        <IconButton onClick={() => { setOpenVotingPlacesExpanded(!openVotingPlacesExpanded)} }>
                            {openVotingPlacesExpanded ? <ExpandLess sx={{color:'white'}} /> : <ExpandMore sx={{color:'white'}} />}
                        </IconButton>
                    </Stack>
                </Box>
                
                <Collapse key={'open-voting-places'} in={openVotingPlacesExpanded} timeout='auto' unmountOnExit>
                    { showClosedMessage() && 
                        <Typography>All voting places are currently closed</Typography>
                    }
                    <List sx={{ paddingTop: '0' }}>
                        {eventInfo && (
                            SortedOpenVotingPlaces(userState === UserStates.ED ? inEdVotingPlaces : nearestVotingPlaces,eventInfo, userState, voterElectoralDistrictInfo).map((item) => (
                                <VotingPlaceSummary key={`${item.addressStandardId}-${item.buildingName}-${(new Date()).getTime()}`} isOpen={true} containerHeight={100} margin={5} vp={item} />
                            ))
                        )}
                    </List>
                    
                </Collapse>
                
                <Box sx={{ backgroundColor: '#db282e' }}>
                    <Stack direction="row" justifyContent="flex-start">
                        <Typography fontFamily='BC Sans' fontSize='1.0rem' lineHeight='2.5rem' marginLeft='10px' marginRight='auto' sx={{ color: 'white' }}>Closed Voting Places</Typography>
                        <IconButton onClick={() => { setClosedVotingPlacesExpanded(!closedVotingPlacesExpanded); }}>
                            {closedVotingPlacesExpanded ? <ExpandLess sx={{color:'white'}} /> : <ExpandMore sx={{color:'white'}} />}
                        </IconButton>
                    </Stack>
                </Box>
                <Collapse key={'closed-voting-places'} in={closedVotingPlacesExpanded} timeout='auto' unmountOnExit>
                    <List sx={{ paddingTop: '0' }}>
                        {eventInfo && (
                            SortedClosedVotingPlaces(userState === UserStates.ED ? inEdVotingPlaces : nearestVotingPlaces,eventInfo, userState, voterElectoralDistrictInfo).map((item) => (
                                <VotingPlaceSummary key={item.addressStandardId} isOpen={false} containerHeight={100} margin={5} vp={item} />
                            ))
                        )}
                    </List>
                </Collapse>
            </Box>
            }
        </>
    );
}