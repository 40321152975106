import { Box, Paper, Typography } from '@mui/material';
import React, { PureComponent } from 'react';
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ComposedChart, Scatter } from 'recharts';
import { IHistoricWaitTimes, IWaitTimeChartData, IWaitTimeInterval } from '../../model/Event';
import { useEventInfoStore } from '../../store/layerStore';

const data_ = [
    {time: '7am-8am', active: false, historical: 0, wait: 0, label:'', fill: '#488fed'},
    {time: '8am-9am', active: false, historical: 1, wait: 0, label:'8am', fill: '#488fed'},
    {time: '9am-10am', active: false,historical: 2, wait: 0, label:'', fill: '#488fed'},
    {time: '10am-11am', active: false,historical: 2, wait: 0, label:'', fill: '#488fed'},
    {time: '11am-12pm', active: false,historical: 1, wait: 0, label:'', fill: '#488fed'},
    {time: '12pm-1pm', active: false,historical: 3, wait: 0, label:'12pm', fill: '#488fed'},
    {time: '1pm-2pm', active: false,historical: 3, wait: 0, label:'', fill: '#488fed'}, 
    {time: '2pm-3pm', active: false,historical: 1, wait: 0, label:'', fill: '#488fed'}, 
    {time: '3pm-4pm', active: false,historical: 1, wait: 0, label:'', fill: '#488fed'}, 
    {time: '4pm-5pm', active: true,historical: 2, wait: 1, label:'4pm', fill: '#e9e8dc'}, 
    {time: '5pm-6pm', active: false,historical: 3, wait: 0, label:'', fill: '#488fed'}, 
    {time: '6pm-7pm', active: false,historical: 2, wait: 0, label:'', fill: '#488fed'}, 
    {time: '7pm-8pm', active: false,historical: 1, wait: 0, label:'', fill: '#488fed'},
    {time: '8pm-9pm', active: false,historical: 0, wait: 0, label:'8pm', fill: '#488fed'},
];

const makeChartData = (historicWaitTimeData: IHistoricWaitTimes): IWaitTimeChartData[] => {
    var intervals: IWaitTimeInterval[] = [];
    switch (new Date(Date.now()).getDay()) {
        case 0:
            intervals = historicWaitTimeData.sundayWaitTimes;
            break;
        case 6:
            intervals = historicWaitTimeData.saturdayWaitTimes;
            break;
        default:
            intervals = historicWaitTimeData.weekdayWaitTimes;
            break;
    }
    return (intervals.map<IWaitTimeChartData>(i => (
        { 
            interval: `${i.intervalStart} - ${i.intervalEnd}`, 
            label: i.intervalLabel ? i.intervalLabel : '', 
            value: i.voterPercent, 
            color: '#488fed', 
            isActive: false
        }
    )));

}

const CustomBarWithTarget = (props: any) => {
    const { fill, x, y, width, height, historical, wait, active } = props;
    let totalHeight = y + height;
    // let targetY = totalHeight - ((height/amt) * t)
    let targetY = totalHeight - ((height/historical) * wait)

    return (
        <svg>
            <rect x={x} y={y} width={width} height={height} stroke="none" fill={fill} />
            { props.active &&
                <line x1={x-2} x2={x + (width) + 2} y1={targetY} y2={targetY} stroke={"#db282e"} strokeWidth={2} />
            }
        </svg>
    );
};

const CustomBarActiveWaitTime = (props: any) => {
    const { fill, x, y, width, height, historical, wait, active } = props;
    let totalHeight = y + height;
    let targetY = totalHeight;// - ((height/historical) * wait);
    let dif = height / historical;
    const newHeight = wait * dif;
    // if (active || props.time == "8am-9am" || props.time == "5pm-6pm") console.log('> ',props);
    return (
        <svg>
            <rect x={x} y={y} width={width} height={height} stroke="fill" fill={fill} />
            { active &&
                <>
                <rect x={x} y={y+dif} width={width} height={newHeight} stroke="none" fill={"#db282e"} fillOpacity='0.15' />
                
                {/* <line x1={x-2} x2={x + (width) + 2} y1={y+newHeight-2} y2={y+newHeight-2} stroke={"#db282e"} strokeWidth={2} /> */}
                </>
            }
        </svg>
    );
};

const CustomLineActiveWaitTime = (props: any) => {
    const { fill, x, y, width, height, historical, wait, active } = props;
    let totalHeight = y + height - 2;
    let targetY = totalHeight;// - ((height/historical) * wait);

    return (
        <svg>
            {/* <rect x={x} y={y} width={width} height={height} stroke="none" fill={fill} /> */}
            { props.active &&
                <line x1={x-8} x2={x + (width) + 8} y1={targetY} y2={targetY} stroke={"#db282e"} strokeWidth={3} />
            }
        </svg>
    );
};

const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
        const value = payload[0].payload.historical;
        var status: string;
        switch (value) {
            case 1: status = 'Typically not busy'; break;
            case 2: status = 'Typically moderately busy'; break;
            case 3: status = 'Typically quite busy'; break;
            default: status = 'Not Available';
        }     
        return (
            <Paper>
                <Typography>{status}</Typography>
            </Paper>
        );
    }
  
    return null;
};

// export const WaitTimesChart = ({chartData}: {chartData?: IWaitTimeChartData[]}) => {
export const WaitTimesChart = () => {
    const { eventInfo } = useEventInfoStore();
    const chartData: IWaitTimeChartData[] = eventInfo ? makeChartData(eventInfo.historicAdvanceVotingWaitTimes) : [];

    if (eventInfo?.historicAdvanceVotingWaitTimes) {
        return (
            <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
                data={chartData}
                // data={chartData}
                margin={{
                    top: 12,
                    right: 16,
                    left: 16,
                    bottom: 5,
                }}
            >
                {/* <CartesianGrid strokeDasharray="3 3" /> */}
                <XAxis
                    dataKey="interval" 
                    tickFormatter={(value: any, index: number) => {
                        const label = chartData.filter(d => d.interval == value).map(d => d.label)[0];
                        // const label = data_.filter(d => d.time === value).map(d => d.label)[0]
                        // const label = chartData.filter((d: { label: any; }) => d.label === value).map((d: { label: any; }) => d.label)[0]
                        return label;
                    }}
                    scale='band'
                    tickMargin={5}
                    // tickSize={2}
                    style={{
                        fontFamily: 'Roboto',
                        fontSize: '.8rem',
                    }}
                />
                {/* <YAxis /> */}
                {/* <Tooltip content={<CustomTooltip />} /> */}
                {/* <Bar dataKey="historical" fill="#488fed" radius={[4, 4, 0, 0]} shape={<CustomBarWithTarget/>} /> */}
                {/* <Bar dataKey="historical" fill="#488fed" radius={[4, 4, 0, 0]} /> */}

                {/* <Bar dataKey="historical" fill="#488fed" radius={[4, 4, 0, 0]} shape={<CustomBarActiveWaitTime/>} /> */}
                <Bar dataKey="value" fill="#488fed" radius={[4, 4, 0, 0]} />
                {/* <Scatter dataKey="wait" fill="red" shape={<CustomLineActiveWaitTime/>}/> */}
                
                {/* <Bar dataKey="wait" fill="#82ca9d" activeBar={<Rectangle fill="gold" stroke="purple" />} /> */}
            </ComposedChart>
        </ResponsiveContainer>
        );
    } else {
        return <></>;
    }
}