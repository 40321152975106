import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import { ThemeProvider } from 'styled-components';
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { useUIStateStore, useVotingPlaceStore } from '../store/layerStore';
import { VotingPlaceSummaryList } from './VotingPlaces/VotingPlaceSummaryList';
import { IVotingPlace } from '../model/VotingPlace';
import { VotingPlaceDetailsContent } from './VotingPlaces/VotingPlaceDetailsContent';
import VotingPlaceDirections from './VotingPlaces/VotingPlaceDirections';
const drawerWidth = 475;

const renderContent = (showDirections: boolean, vp: IVotingPlace | null) => {
    if (vp) {
        // return <VotingPlaceDetails vp={vp} />
        if (showDirections) {
            return <VotingPlaceDirections vp={vp}/>
        } else {
            return <VotingPlaceDetailsContent vp={vp} />
        }
    } else {
        return(<VotingPlaceSummaryList />)
    }
}

export const ClippedDrawer = ({containerHeight, margin, styledHeight, styledWidth}: {containerHeight: number, margin: number, styledHeight: string, styledWidth: string}) => {
// export default function ClippedDrawer() {
    const theme = responsiveFontSizes(createTheme());
    const { activeVotingPlace, } = useVotingPlaceStore();
    const { showDirections } = useUIStateStore();
    
  return (
    <ThemeProvider theme={ theme }>
      <Drawer
        variant="permanent"
        elevation={0}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
        //   zIndex: 1,
          [`& .MuiDrawer-paper`]: { overflowY: 'clip', width: styledWidth, height: styledHeight, boxSizing: 'border-box', },
        }}
      >
        <Toolbar />
        <Toolbar />
        {/* <Box sx={{ overflow: 'none' }}> */}
            {/* <Box sx={{ borderBottom: 1, borderColor: 'divider', backgroundColor:'#e9e8dc' }}>
                <Tabs
                    value={userState}
                    onChange={(e,v) => {setUserState(v)}}
                    // indicatorColor=
                    TabIndicatorProps={{style: {background: '#db282e'}}}
                    textColor="inherit"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                    sx={{ color: '#db282e',  }}
                >
                    <Tab label="Nearest to Me" value={UserStates.NB} sx={{fontFamily: 'Bebas Neue', fontSize: '1.4rem', letterSpacing: '0.00938em' }} />
                    <Tab label="In My Electoral District" value={UserStates.ED} wrapped sx={{fontFamily: 'Bebas Neue', fontSize: '1.4rem', letterSpacing: '0.00938em', padding: '8px'}} />
                </Tabs>
            </Box> */}
            {/* <Box sx={{ backgroundColor: 'white'}}>
                <Stack direction='row' padding='10px'>
                <ListIcon />
                <Typography marginLeft='10px' marginRight='auto' sx={{ }}>Voting Place Results</Typography>
                </Stack>

            </Box>                */}
        {/* <Box sx={{ overflow: 'scroll', flexGrow: 1, p: 0, fontFamily:'Bebas Neue' }}> */}
        {/* <Box> */}
            {/* { activeVotingPlace && 
                <VotingPlaceDetails vp={activeVotingPlace}/>
            } */}
            
            {/* <VotingPlaceDetails vp={EDVotingPlaces()[2]}/> */}
            { renderContent(showDirections, activeVotingPlace) }
        {/* </Box> */}
      </Drawer>
    </ThemeProvider>
  );
}