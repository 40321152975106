import { Box, Typography } from "@mui/material";
import React from "react";

export default function NoPage(props: {minHeight?:string}) {
    React.useEffect = () => {
        
    }
    const dvh = window.CSS.supports('height', '100vh');
    return (
        <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight={`calc(100${dvh ? 'dvh' : 'vh'} - 128px)`}
        >

            <Typography variant="h4">No Page</Typography>
            <Typography variant="h4">See the <a href="https://elections.bc.ca/" target="_blank" rel="noreferrer">Elections BC</a> page for  more information.</Typography>

        </Box>
    );
}