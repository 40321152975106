import { useEffect, useRef } from 'react';
import { Map, Marker, useMap } from '@vis.gl/react-google-maps';
import { Bs11DeckGlOverlay, GEOSERVER_ROOT_URL, VotingPlacesLayer, VotingPlacesMarkerLayer, VpLayerCqlFilter } from './DeckGlOverlay';
import { ActiveVotingPlaceListener, SearchListener } from './MapInfluence';
import { useAppStateStore, useEventInfoStore, useLayerVisibilityStore, useVotingPlaceStore } from '../../store/layerStore';
import HighlightMapMarker from './HighlightMapMarker';
import Directions from './Directions';
import { BcpagListener, InEdVotingPlacesListener, NearestVotingPlacesListener, ParameterPlaceIdListener, PlaceSearchListener, SearchLocationListener, VP_WAIT_TIME_URL } from '../../api/votingPlaces';
import { VPTypeCodes } from '../../util';
import { VotingPlace } from '../../model';
import axios from 'axios';
import VotingPlaceAdvancedMarkerLayer from './VotingPlaceAdvanceMarkerLayer';

const DEFAULT_CENTER = { lat: 53.7267, lng: -127.6476 };
const DEFAULT_ZOOM = 6; //4;

export default function GoogleMapsContainer(props:any) {
    const { userLocation, searchPlace } = useAppStateStore();
    
    const { setActiveVotingPlace } = useVotingPlaceStore();
    const { voterElectoralDistrictInfo, userState } = useAppStateStore();
    const { eventInfo } = useEventInfoStore();

    // useEffect(() => {
    //     if (isInitialMount.current) {
    //         isInitialMount.current = false;
    //     }
    // });
    const mapStyleOptions = [
        {
            featureType: "poi",
            stylers: [{ visibility: "off" }],
        },
        {
            featureType: "transit",
            elementType: "labels.icon",
            stylers: [{ visibility: "on" }],
        },
    ]

    return (
    // <APIProvider apiKey={API_KEY}>
        <Map
            // mapId='ef2801b7b7cb9a36'
            zoom={ DEFAULT_ZOOM }
            center={ DEFAULT_CENTER }
            gestureHandling={'greedy'}
            disableDefaultUI={true}
            draggableCursor={'default'}
            zoomControl={true}
            style={{height: props.containerHeight, transition: 'height 0.1s' }}
            styles={mapStyleOptions}
            
        >
            {/* <ZoomPositionControl controlPosition={ControlPosition.RIGHT_BOTTOM} /> */}

            <Bs11DeckGlOverlay />

            {/* <VotingPlacesLayer /> */}
            <VotingPlacesMarkerLayer />
            {/* <VotingPlaceAdvancedMarkerLayer /> */}
            <SearchListener />
            <ActiveVotingPlaceListener />
            <NearestVotingPlacesListener />
            <InEdVotingPlacesListener />
            <SearchLocationListener />
            <PlaceSearchListener />
            <BcpagListener />
            <ParameterPlaceIdListener />
            <HighlightMapMarker />
            {/* { marker && <MapMarker /> } */}
            {searchPlace && searchPlace.result && 
                <Marker position={{lat: searchPlace?.result.y, lng: searchPlace?.result.x }} />
            }
            {userLocation &&
                <Marker position={{lat: userLocation.lat, lng: userLocation.lng }} />
            }
            <Directions />
        </Map>
    // </APIProvider>
)
};