import { Box, createTheme, Grid, responsiveFontSizes, Stack, Typography } from "@mui/material";
import { useEventInfoStore } from "../../store/layerStore";
import Loading from "./Loading";
import { AppStates } from "../../util";
import ErrorPage from "./ErrorPage";
import Closed from "./Closed";
import PreWrit from "./PreWrit";
import GoogleMapsContainer from "../GoogleMaps/GoogleMapsContainer";
import { ClippedDrawer } from "../ClippedDrawer";
import React from "react";

export default function Content(props: {drawerWidth: any, containerHeight: any, containerWidth:number, isLargeScreen: boolean}) {
    const { eventInfo } = useEventInfoStore();
    const [largeScreen, setLargeScreen] = React.useState(false);
    const theme = responsiveFontSizes(createTheme());
    const dvh = window.CSS.supports('height', '100vh');

    const renderContent = (state?: string) => {
        if(eventInfo) {
            switch (eventInfo.state) {
                case AppStates.LOADING:
                    return <Loading />;
                case AppStates.ERROR:
                    return <ErrorPage key={""} />;
                case AppStates.CLOSED:
                    return <Closed />;
                case AppStates.PRE_WRIT:
                    return <PreWrit />;
                case AppStates.MULTI:
                default:
                    return (
                        <>
                            {/* <GoogleMapsContainer containerHeight={baseHeaderHeight + baseSearchHeight + (edInfo ? infoContainerHeight() : 0) + baseFooterHeight - (largeScreen ? 0 : 16)} /> */}
                            {/* <Grid container spacing={2}>
                                <Grid item md={2} sm={12}>
                                    <ClippedDrawer containerHeight={props.containerHeight} margin={0} />
                                </Grid>
                                <Grid item sm="auto" md="auto">
                                    <GoogleMapsContainer containerHeight={props.containerHeight} />
                                </Grid>
                            </Grid> */}

                            <Stack direction={props.isLargeScreen ? 'row' : 'column'} alignItems={props.isLargeScreen ? 'flex-start' : 'flex-end'} justifyContent={props.isLargeScreen ? 'flex-start' : 'flex-end'}>
                                <ClippedDrawer 
                                    containerHeight={props.containerHeight} 
                                    // styledHeight={props.isLargeScreen ? `calc(100vh - 0px)` : `calc((60vh - 0px))`}
                                    styledHeight={props.isLargeScreen ? `calc(100${dvh ? 'dvh' : 'vh'} - 0px)` : `calc((60${dvh ? 'dvh' : 'vh'} - 0px))`} 
                                    styledWidth={props.isLargeScreen ? props.drawerWidth : '100%'}
                                    margin={0} />
                                { !props.isLargeScreen &&
                                    <Box height={dvh ? `calc(60dvh - 112px)` : `calc(60vh - 112px)`}></Box>
                                }
                                <GoogleMapsContainer containerHeight={props.isLargeScreen ? `calc(100${dvh ? 'dvh' : 'vh'} - ${props.containerHeight}px)` : `calc(40${dvh ? 'dvh' : 'vh'} - 0px)`} />
                            </Stack>

                        </>
                    );
            }
        } else {
            return(
                <Loading />
            );
        }
    }

    React.useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            setLargeScreen(window.innerWidth > theme.breakpoints.values.sm);
          // if (window.innerWidth>700) {
          //    setLargeScreen(true);
          // }
        }    
        // Add event listener
        // window.addEventListener("resize", handleResize);
        // handleResize();
    });

    return(renderContent(eventInfo?.state));
}