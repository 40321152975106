import { create } from 'zustand';
import { GoogleMapsOverlay } from '@deck.gl/google-maps/typed'
import { PickingInfo } from 'deck.gl/typed';
import { PlaceType } from '../components/Search/AzureAutocomplete';
import { IEventInfo } from '../model/Event';
import { IBcpagResult, IVoterElectoralDistrictInfo, IVotingPlace } from '../model/VotingPlace';
import { UserStates } from '../util';

export type ElectoralDistrictInfo = {
    edName: string,
    edAbbr: string,
    area: string,
    groupName: string,
    minLng: number,
    maxLng: number,
    minLat: number,
    maxLat: number,
    bounds: google.maps.LatLngBoundsLiteral,
}

export const SearchType = {
    LOCATION: 'location',
    GEOCODE: 'geocode',
}

export type SearchInfo = {
    type: string,
    location: google.maps.LatLng | google.maps.LatLngLiteral | null,
    parameter: string | null,
    placeId: string | null,
}

type LayerVisibilityStore = {
    // bs11: boolean,
    // bs10: boolean,
    map?: google.maps.Map,
    // overlay?: GoogleMapsOverlay,
    // bs11Overlay?: GoogleMapsOverlay,
    // bs10Overlay?: GoogleMapsOverlay,
    // positionOverlay?: GoogleMapsOverlay,
    selected?: PickingInfo,
    // searchPlace?: PlaceType | null,
    // ed?: string,
    // bounds?: google.maps.LatLngBoundsLiteral,
    edInfo?: ElectoralDistrictInfo | null,
    // region?: string,
    marker?: google.maps.LatLngLiteral,
    // markerLat: number,
    // markerLng: number,
    // highlightInfo?: any,
    // bs11HighlightInfo?: any,
    // bs10HighlightInfo?: any,
    // showInfoWindow: boolean,
    defaultPlace: PlaceType | null,
    // toggle: () => void,
    // toggleBs11: () => void,
    // toggleBs10: () => void,
    setMap: (m: google.maps.Map) => void,
    // setBs11Overlay: (o: GoogleMapsOverlay) => void,
    // setBs10Overlay: (o: GoogleMapsOverlay) => void,
    // setPositionOverlay: (o: GoogleMapsOverlay) => void,    
    setSelected: (sel: PickingInfo) => void,
    // setSearchPlace: (s: PlaceType | null) => void,
    // setEd: (sel: string) => void,
    // setBounds: (b: google.maps.LatLngBoundsLiteral) => void,
    setEdInfo: (e: ElectoralDistrictInfo | null) => void,
    // setRegion: (region: string) => void,
    setMarker: (m: google.maps.LatLngLiteral) => void,
    // setMarkerLat: (l: number) => void,
    // setMarkerLng: (l: number) => void,
    // setHighlightInfo: (h: any) => void,
    // setBs11HighlightInfo: (h: any) => void,
    // setBs10HighlightInfo: (h: any) => void,        
    // setShowInfoWindow: (b: any) => void,
    setDefaultPlace: (s: PlaceType | null) => void,
}

type VotingPlaceStore = {
    // searchPlace: PlaceType | null,
    // setSearchPlace: (sp: PlaceType | null) => void,
    activeVotingPlace: IVotingPlace | null,
    setActiveVotingPlace: (e?: IVotingPlace) => void,
    highlightVotingPlace: IVotingPlace | null,
    setHighlightVotingPlace: (e?: IVotingPlace) => void,    
    votingPlaceSearchResults: IVotingPlace[],
    setVotingPlaceSearchResults: (vp: IVotingPlace[]) => void,
    nearestVotingPlaces: IVotingPlace[] | null,
    setNearestVotingPlaces: (vp: IVotingPlace[] | null) => void,
    inEdVotingPlaces: IVotingPlace[] | null,
    setInEdVotingPlaces: (vp: IVotingPlace[] | null) => void,
    bcpagResult: IBcpagResult | null,
    setBcpagResult: (bcpag: IBcpagResult | null) => void,
    vpResultsMessage: string | null,
    setVpResultsMessage: (message: string | null) => void,
}

export const useVotingPlaceStore = create<VotingPlaceStore>((set) => ({
    // searchPlace: null,
    // setSearchPlace: (sp) => set((state) => ({searchPlace: sp})),
    activeVotingPlace: null,
    setActiveVotingPlace: (vp) => set((state) => ({activeVotingPlace: vp})),
    highlightVotingPlace: null,
    setHighlightVotingPlace: (vp) => set((state) => ({highlightVotingPlace: vp})),    
    votingPlaceSearchResults: [],
    setVotingPlaceSearchResults: (vp) => set((state) => ({votingPlaceSearchResults: vp})),
    nearestVotingPlaces: null,
    setNearestVotingPlaces: (vp) => set((state) => ({nearestVotingPlaces: vp})),
    inEdVotingPlaces: null,
    setInEdVotingPlaces: (vp) => set((state) => ({inEdVotingPlaces: vp})),
    bcpagResult: null,
    setBcpagResult: (bcpag) => set((state) => ({bcpagResult: bcpag})),
    vpResultsMessage: null,
    setVpResultsMessage: (message) => set((state) => ({vpResultsMessage: message})),    
}))

type EventInfoStore = {
    eventInfo?: IEventInfo,
    setEventInfo: (e: IEventInfo) => void,
}

export const useEventInfoStore = create<EventInfoStore>((set) => ({
    eventInfo: undefined,
    setEventInfo: (e) => set((state) => ({ eventInfo: e})), 
}))

enum DrawerContentType {
    Menu = 1,
    Help,
}

type UIStateStore = {
    menuDrawerOpen: boolean,
    setMenuDrawerOpen: (e: boolean) => void,
    menuDrawerType: DrawerContentType,
    setMenuDrawerType: (e: DrawerContentType) => void,
    showDirections: boolean,
    setShowDirections: (b: boolean) => void,
    largeScreen: boolean,
    setLargeScreen: (e: boolean) => void,
}

export const useUIStateStore = create<UIStateStore>((set) => ({
    menuDrawerOpen: false,
    setMenuDrawerOpen: (e: boolean) => set((state) => ({ menuDrawerOpen: e})),
    menuDrawerType: DrawerContentType.Menu,
    setMenuDrawerType: (e) => set((state) => ({ menuDrawerType: e})),
    showDirections: false,
    setShowDirections: (b) => set((state) => ({showDirections:b})),
    largeScreen: true,
    setLargeScreen: (e: boolean) => set((state) => ({ largeScreen: e})),    
}))

type AppStateStore = {
    userState: string,
    setUserState: (s: string) => void,
    searchPlace?: PlaceType | null,
    setSearchPlace: (s: PlaceType | null) => void,    
    selectedPlace: google.maps.places.PlaceResult | null,
    setSelectedPlace: (p:google.maps.places.PlaceResult | null) => void,
    defaultSearchValue: PlaceType | null,
    setDefaultSearchValue: (p:PlaceType | null) => void,    
    userLocation: google.maps.LatLngLiteral | null,
    setUserLocation: (l: google.maps.LatLngLiteral | null ) => void,
    searchLocation: google.maps.LatLngLiteral | null,
    setSearchLocation: (l: google.maps.LatLngLiteral | null) => void,
    userElectoralDistrict: string | null,
    setUserElectoralDistrict: (ed: string | null) => void,
    voterElectoralDistrictInfo: IVoterElectoralDistrictInfo | null,
    setVoterElectoralDistrictInfo: (v: IVoterElectoralDistrictInfo | null) => void,
    message?: string,
    setMessage: (m?: string) => void,
    loadingPlaceId: string | null,
    setLoadingPlaceId: (p: string | null) => void,
    loadingPlaceAddress: PlaceType | null,
    setLoadingPlaceAddress: (p: PlaceType | null) => void,
    resultsLoading: boolean,
    setResultsLoading: (b: boolean) => void,
    clearSearch: boolean,
    setClearSearch: (b: boolean) => void,
    lastNearestSearch: SearchInfo | null,
    setLastNearestSearch: (s: SearchInfo) => void,
    lastInEdSearch: SearchInfo | null,
    setLastInEdSearch: (s: SearchInfo) => void,
    lastSelectedPlace: google.maps.places.PlaceResult | null,
    setLastSelectedPlace: (p:google.maps.places.PlaceResult | null) => void,    
}

export const useAppStateStore = create<AppStateStore>((set) => ({
    userState: UserStates.NB,
    setUserState: (s) => set((state) => ({ userState: s})), 
    searchPlace: null,
    setSearchPlace: (sp) => set((state) => ({searchPlace: sp})),    
    selectedPlace: null,
    setSelectedPlace: (p: google.maps.places.PlaceResult | null) => set((state) => ({selectedPlace: p})),
    defaultSearchValue: null,
    setDefaultSearchValue: (p: PlaceType | null) => set((state) => ({defaultSearchValue: p})),    
    userLocation: null,
    setUserLocation: (l) => set((state) => ({userLocation: l})),
    searchLocation: null,
    setSearchLocation: (l) => set((state) => ({searchLocation: l})),    
    userElectoralDistrict: null,
    setUserElectoralDistrict: (ed) => set((state) => ({userElectoralDistrict: ed})),
    voterElectoralDistrictInfo: null,
    setVoterElectoralDistrictInfo: (v) => set((state) => ({voterElectoralDistrictInfo: v})),    
    message: undefined,
    setMessage: (m) => set((state) => ({message: m})),
    loadingPlaceId: null,
    setLoadingPlaceId: (p) => set((state) => ({loadingPlaceId: p})),
    loadingPlaceAddress: null,
    setLoadingPlaceAddress: (p) => set((state) => ({loadingPlaceAddress: p})),
    resultsLoading: true,
    setResultsLoading: (b) => set((state) => ({resultsLoading: b})),
    clearSearch: false,
    setClearSearch: (b) => set((state) => ({clearSearch: b})),
    lastNearestSearch: null,
    setLastNearestSearch: (s) => set((state) => ({lastNearestSearch: s})),
    lastInEdSearch: null,
    setLastInEdSearch: (s) => set((state) => ({lastInEdSearch: s})),
    lastSelectedPlace: null,
    setLastSelectedPlace: (p: google.maps.places.PlaceResult | null) => set((state) => ({lastSelectedPlace: p})),     
}))

type DirectionsStore = {
    origin: google.maps.LatLngLiteral | google.maps.LatLng | null,
    setOrigin: (s: google.maps.LatLngLiteral | google.maps.LatLng | null) => void,
    destination?: google.maps.LatLngLiteral | google.maps.LatLng | null,
    setDestination: (s: google.maps.LatLngLiteral | google.maps.LatLng | null) => void,
    routes: google.maps.DirectionsRoute[],
    setRoutes: (r: google.maps.DirectionsRoute[]) => void,
    routeIndex: number,
    setRouteIndex: (i: number) => void,
    selectedRoute: google.maps.DirectionsRoute | null,
    setSelectedRoute: (r: google.maps.DirectionsRoute) => void,
    leg: google.maps.DirectionsLeg | null,
    setLeg: (l: google.maps.DirectionsLeg) => void,
    directionsPanelRef: any,
    setDirectionsPanelRef: (r: any) => void,
    service: any,
    setService: (s: any) => void,
    renderer: any,
    setRenderer: (s: any) => void,
    mapRef: google.maps.Map | null,
    setMapRef: (m: google.maps.Map | null) => void,
    routesLibraryRef: google.maps.RoutesLibrary | null,
    setRoutesLibraryRef: (l: google.maps.RoutesLibrary | null) => void,
}

export const useDirectionsStore = create<DirectionsStore>((set) => ({
    origin: null,
    setOrigin: (s) => set((state) => ({ origin: s})),
    destination: null,
    setDestination: (s) => set((state) => ({ destination: s})),
    routes: [],
    setRoutes: (r) => set((state) => ({routes: r})),
    routeIndex: 0,
    setRouteIndex: (i) => set((state) => ({routeIndex:i})),
    selectedRoute: null,
    setSelectedRoute: (i) => set((state) => ({selectedRoute:i})),
    leg: null,
    setLeg: (l) => set((state) => ({leg:l})),
    directionsPanelRef: null,
    setDirectionsPanelRef: (r) => set((state) => ({directionsPanelRef:r})),
    service: null,
    setService: (s) => set((state) => ({service:s})),
    renderer: null,
    setRenderer: (r) => set((state) => ({renderer:r})),
    mapRef: null,
    setMapRef: (m) => set((state) => ({mapRef:m})),
    routesLibraryRef: null,
    setRoutesLibraryRef: (l) => set((state) => ({routesLibraryRef:l})),    
}))

export const useLayerVisibilityStore = create<LayerVisibilityStore>((set) => ({
    // bs10: false,
    // bs11: true,
    map: undefined,
    // overlay: undefined,
    // bs11Overlay: undefined,
    // bs10Overlay: undefined,
    // positionOverlay: undefined,
    selected: undefined,
    search: undefined,
    // ed: undefined,
    // bounds: undefined,
    edInfo: undefined,
    // region: undefined,
    marker: undefined,
    // markerLat: 49.3994,
    // markerLng: -123.5573,
    // highlightInfo: undefined,
    // bs11HighlightInfo: undefined,
    // bs10HighlightInfo: undefined,
    // showInfoWindow: false,
    defaultPlace: null,
    // toggle: () => set((state) => ({ bs11: !state.bs11 })),
    // toggleBs11: () => set((state) => ({ bs11: !state.bs11 })),
    // toggleBs10: () => set((state) => ({ bs10: !state.bs10 })),
    setMap: (m) => set((state) => ({ map: m })),
    // setBs11Overlay: (o) => set((state) => ({bs11Overlay: o})),
    // setBs10Overlay: (o) => set((state) => ({bs10Overlay: o})),
    // setPositionOverlay: (o) => set((state) => ({positionOverlay: o})),
    setSelected: (sel) => set((state) => ({ selected: sel })),
    // setSearchPlace: (sp) => set((state) => ({ searchPlace: sp })),
    // setEd: (ed) => set((state) => ({ ed: ed })),
    // setBounds: (bnd) => set((state) => ({ bounds: bnd })),
    setEdInfo: (e) => set((state) => ({ edInfo: e})),
    // setRegion: (r) => set((state) => ({ region: r })),
    setMarker: (m) => set((state) => ({ marker: m })),
    // setMarkerLat: (l) => set((state) => ({ markerLat: l })),
    // setMarkerLng: (l) => set((state) => ({ markerLng: l })),
    // setHighlightInfo: (h) => set((state) => ({ highlightInfo: h })),
    // setBs11HighlightInfo: (h) => set((state) => ({ bs11HighlightInfo: h })),
    // setBs10HighlightInfo: (h) => set((state) => ({ bs10HighlightInfo: h })),
    // setShowInfoWindow: (b) => set((state) => ({showInfoWindow: b})),
    setDefaultPlace: (sp) => set((state) => ({ defaultPlace: sp })),
}))